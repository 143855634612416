// Library imports
import styled from 'styled-components';
import { Button, ListGroup, Navbar } from 'react-bootstrap';
// Constants
import { POWER_BREAK_POINT } from '../GameCards/game_card_windows/PowerRank/constants';

export const AppContent = styled.div`
    flex-grow: 1;
    background: #fdfdfd;
    padding-bottom: 25px;
`;

export const ToolsNavbar = styled(Navbar)`
    border: 1px solid #ccc;
    border-bottom-width: 0;
    z-index: ${props => (props.drawerOpen ? 0 : 9999999)};
`;

export const SportLabel = styled.span`
    font-weight: bold;
    font-size: 18px;
    @media (min-width: 576px) {
        display: none;
    }
`;

export const TourButton = styled(Button)`
    display: inline-block !important;
    position: absolute;
    right: 50px !important;
    top: 8px;
    @media (min-width: 576px) {
        right: 25px !important;
    }
`;

export const LabelValueListGroup = styled(ListGroup)`
    .list-group-item {
        background: transparent;
        display: flex !important;
        flex-direction: ${props => (props.direction ? props.direction : 'column')} !important;
        align-items: ${props => (props.direction === 'row' ? 'center' : 'flex-start')} !important;

        label, span {
            font-size: 0.95rem;
        }

        label {
            width: auto;
            display: block;
            margin-bottom: 0px;
            font-weight: bold;
            margin-right: auto;
            @media (max-width: ${POWER_BREAK_POINT}) {
                margin-right: ${props => (props.responsive && props.rightJustify ? '0px' : 'auto')} !important;
            }
        }
            
        @media (max-width: ${POWER_BREAK_POINT}) {
            flex-direction: ${props => (props.responsive ? 'column' : props.direction ? props.direction : 'column')} !important;
            align-items: ${props =>
                props.responsive && props.rightJustify
                    ? 'flex-end'
                    : props.responsive
                    ? 'flex-start'
                    : props.direction === 'row'
                    ? 'center'
                    : 'flex-start'} !important;}
        }
    }
`;
