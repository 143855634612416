// Library imports
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

export default function DoubleBarChart({ data, xAxisLabel, yAxisLabel, symbol = '', fixedAmount = 0, keys = [] }) {
    return (
        <div style={{ height: '350px' }}>
            <ResponsiveBar
                data={data.map(barData => {
                    let newObj = {
                        [xAxisLabel]: barData.x,
                    };
                    keys.forEach(key => {
                        newObj[key.label] = Number(barData[key.dataValue] * 100).toFixed(fixedAmount);
                    });
                    return newObj;
                })}
                groupMode="grouped"
                padding={0.25}
                colors={{ scheme: 'paired' }}
                keys={keys.map(key => key.label)}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        translateX: -25,
                        translateY: -50,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 25,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                indexBy={xAxisLabel}
                margin={{ top: 50, right: 25, bottom: 50, left: 45 }}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: ``,
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yAxisLabel,
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                label={d => <tspan y={-10}>{`${d.value}${symbol}`}</tspan>}
                role="application"
            />
        </div>
    );
}
