// Library imports
import styled from 'styled-components';
// Util imports
import { HEADING_FONT, HEADING_FONT_WEIGHT, PRIMARY_DARK, PRIMARY_LIGHT } from '../../../utils/styleConstants';
// Constant imports
import { POWER_BREAK_POINT } from './PowerRank/constants';

export const GameCardContentWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    height: 275px;
    flex-direction: row;
    justify-content: flex-start;
`;
export const InnerContent = styled.div`
    width: 100%;
    display: flex;
    overflow-y: scroll;
`;

export const CardTitle = styled.span`
    font-family: ${HEADING_FONT};
    font-weight: ${HEADING_FONT_WEIGHT};
    font-size: 1.1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.75rem 1.25rem;
    @media (max-width: ${POWER_BREAK_POINT}) {
        ${p => p.responsive && 'padding: 0.75rem 0px;'}
    }
    ${props => props.color && `color: ${props.color};`}
`;
export const TeamRank = styled.span`
    position: absolute;
    font-size: 18px;
    top: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`;
export const HighLightedField = styled.span`
    background-color: ${props =>
        props.variant === 'success' ? '#d4edda' : props.variant === 'danger' ? '#f8d7da' : props.variant === 'primary' ? PRIMARY_LIGHT : '#f8f9fa'};
    border-color: ${props =>
        props.variant === 'success' ? '#c3e6cb' : props.variant === 'danger' ? '#f5c6cb' : props.variant === 'primary' ? PRIMARY_LIGHT : '#f8f9fa'};
    color: ${props =>
        props.variant === 'success' ? '#155724' : props.variant === 'danger' ? '#721c24' : props.variant === 'primary' ? PRIMARY_DARK : '#212529'};
    text-align: center;
    padding: 5px 10px;
    font-weight: 500;
    border-radius: 5px;
    white-space: nowrap;
    display: block;
    @media (max-width: ${POWER_BREAK_POINT}) {
        span {
            font-size: 14px;
        }
    }
`;
