import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const MonthlyProfitText = styled.span`
    font-weight: bold;
    font-size: 28px;
`;
export const ValueBlock = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    color: #212529;
    border-radius: 10px;
    .juice {
        font-size: 12px;
        color: #666;
    }
    .value {
        font-size: 14px;
        font-weight: bold;
    }
`;

export const UnitBlock = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #212529;
    .value {
        font-size: 14px;
        font-weight: bold;
    }
`;
