// Library imports
import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from '../../../components/page_components/LoadingSpinner';
import { Container, Card, ListGroup } from 'react-bootstrap';
import moment from 'moment';
// Component imports
import Table from '../../../components/page_components/TableV2';
import TwoColumnView from '../../../components/card_components/TwoColumnView';
// Controller imports
import { getGameTeamData } from '../../../controllers/GetDataController';
// Util imports
import { shortName } from '../../../utils';
// Styled components
import { CardTitle, InnerContent } from './styles';
import { LabelValueListGroup } from '../../App/styles';

const Schedule = ({ sport, game, history }) => {
    const [awayData, setAwayData] = useState({});
    const [homeData, setHomeData] = useState({});
    const [loading, setLoading] = useState({});

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { awayTeamData, homeTeamData } = await getGameTeamData(sport, game);
                setAwayData(awayTeamData ? awayTeamData : {});
                setHomeData(homeTeamData ? homeTeamData : {});
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const createScheduleJSX = team => {
        const schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        if (!schedule) return <></>;
        return (
            <Card>
                <CardTitle color={team.color} style={{ padding: '15px 10px 0px 10px' }}>
                    {shortName(team)}
                </CardTitle>
                <Card.Body style={{ paddingTop: 0, padding: '5px 10px' }}>
                    {!history ? (
                        <LabelValueListGroup direction="row" variant="flush">
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>Overall</label>
                                <span>{team.recordData.record}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>{team.teamId === game.awayTeam.teamId ? `Away: ` : `Home: `}</label>
                                <span>{team.teamId === game.awayTeam.teamId ? `${team.recordData.awayRecord}` : `${team.recordData.homeRecord}`}</span>
                            </ListGroup.Item>
                        </LabelValueListGroup>
                    ) : (
                        <></>
                    )}
                </Card.Body>
                <Table
                    showHeader={false}
                    dataList={
                        schedule.filter(game => game.points && game.opp_pts).length === 0
                            ? schedule.map((game, i) => {
                                  return {
                                      'schedule-game': `${moment(game.date).format('MM/DD/YY')}\n${game.location === 'away' ? '@' : 'vs.'} ${game.oppAbbr}`,
                                      'schedule-result': moment(game.date, 'hh:mm').format('hh:mm a'),
                                  };
                              })
                            : schedule
                                  .filter(game => game.points && game.opp_pts)
                                  .slice(0)
                                  .reverse()
                                  .map((game, i) => {
                                      return {
                                          'schedule-game': `${moment(game.date).format('MM/DD/YY')}\n${game.location === 'away' ? '@' : 'vs.'} ${game.oppAbbr}`,
                                          'schedule-result': `${game.points}-${game.opp_pts} ${sport === 'mlb' ? game.result : game.streak}`,
                                      };
                                  })
                    }
                    autoHeight={true}
                    bordered
                    columns={[
                        {
                            header: 'Game',
                            dataKey: 'schedule-game',
                            flexGrow: 1,
                        },
                        {
                            header: schedule.filter(game => game.points && game.opp_pts).length === 0 ? 'Time' : 'Final',
                            dataKey: 'schedule-result',
                        },
                    ]}
                />
            </Card>
        );
    };

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                {loading ? (
                    <LoadingSpinner loading={loading} />
                ) : (
                    <TwoColumnView columnOneJSX={createScheduleJSX(game.awayTeam)} columnTwoJSX={createScheduleJSX(game.homeTeam)} />
                )}
            </Container>
        </InnerContent>
    );
};

export default Schedule;
