// Fonts
export const HEADING_FONT = 'Roboto, sans-serif';
export const HEADING_FONT_WEIGHT = 'bold';
export const BODY_FONT = 'Roboto, sans-serif';
export const BODY_FONT_WEIGHT = 'normal';

// Colors
export const PRIMARY = '#006eb5';
export const PRIMARY_DARK = '#1c2237';
export const PRIMARY_LIGHT = '#cdf1ff';
export const PRIMARY_MEDIUM = '#9be4ff';
export const GREEN = '#00b894';
export const RED = '#d63031';
export const BLACK = '#111';
export const WHITE = '#fff';
export const GRAY = '#D3D3D3';
export const SILVER = '#f5f5f5';
export const GOLD = '#bf9b30';
