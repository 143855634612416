import { createSlice } from '@reduxjs/toolkit';

const mockData = {
    firstName: 'Keith',
    lastName: 'Waggoner',
    email: `kwaggoner${parseInt(Math.random() * 1000)}@gmail.com`,
    displayName: `Test-${parseInt(Math.random() * 1000)}`,
    password: 'rumor512',
    vpassword: 'rumor512',
    plan: {
        name: 'Monthly Platform Access',
        price: 39,
        id: 'monthly-access',
        planId: 'price_1KXq2iEo1NEo1jQFm94tzBQc',
        desc: 'This is the best package we have.  You will receive your own account to access the entire Line Prophet system.  You will also be requested to follow @LineProphetVIP on Twitter and join our private Discord server!',
    },
};

const initialState = {
    stage: 'info',
    data: {
        firstName: '',
        lastName: '',
        email: '',
        displayName: '',
        password: '',
        vPassword: '',
        plan: null,
        promo: '',
    },
};

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        setData(state, { payload }) {
            state.data = { ...state.data, ...payload };
        },
        setStage(state, { payload }) {
            state.stage = payload;
        },
    },
});

export const { setData, setStage } = registerSlice.actions;

export default registerSlice.reducer;
