// Component imports
import React from 'react';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Col, Button, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
// Controller imports
import { deleteProfile, register } from '../../controllers/UserController';
import { saveReciept } from '../../controllers/PaymentController';
// Firebase imports
import firebase from 'firebase';
// Action imports
import { setData, setStage } from '../../store/slices/registerSlice';
import { addLoader, removeLoader } from '../../store/slices/appSlice';

const Information = ({ setError }) => {
    // Redux
    const { data } = useSelector(state => state.register);
    const { loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();

    return (
        <Form
            onSubmit={async e => {
                e.preventDefault();
                if (!data.email) return setError('Please fill out all required fields. [Email].');
                if (!validator.isEmail(data.email)) return setError('Please enter a valid email.');
                const emailExist = await firebase.auth().fetchSignInMethodsForEmail(data.email);
                if (data.plan.id.indexOf('access') > -1) {
                    if (!data.firstName) return setError('Please fill out all required fields. [First Name].');
                    if (!data.lastName) return setError('Please fill out all required fields. [Last Name].');
                    if (!data.password) return setError('Please fill out all required fields. [Password].');
                    if (!data.vpassword) return setError('Please fill out all required fields. [Verify Password].');
                    if (!data.displayName) return setError('Please fill out all required fields. [Display Name].');
                    if (data.displayName.length > 14) return setError('Display name must be shorter than 15 characters and include no spaces.');
                    if (data.password !== data.vpassword) return setError('Passwords do not match.  Please try again');
                    if (!validator.isLength(data.password, { min: 5, max: 100 })) return setError('Password must be between 5 and 100 characters.');
                    if (emailExist.length !== 0) return setError('That email already exists.  Please use a different email.');
                } else {
                    if (emailExist.length !== 0) return setError('Your account already has access.  No need to buy a pick package you will get every play.');
                }
                if (!data.plan) return setError('Please fill out all required fields. [Plan].');
                setError('');
                dispatch(setData(data));
                if (data.plan.id === 'free-access') {
                    try {
                        dispatch(addLoader('registerLoading'));
                        var newUser = await register(data.email, data.password, data.displayName, data.plan.type); // lowercase verification on the BE
                        await saveReciept({
                            items: [
                                {
                                    ...data.plan,
                                    startDate: moment().toISOString(),
                                },
                            ], // end date is created on the BE
                            email: data.email, // lowercase verification on the BE
                            type: data.plan.type,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            twitter: data.twitter,
                        });
                        dispatch(setStage('access'));
                    } catch (error) {
                        setError(error.message);
                        deleteProfile(newUser.uid, String(newUser.email).toLowerCase());
                    } finally {
                        dispatch(removeLoader('registerLoading'));
                    }
                } else {
                    dispatch(setStage('pay'));
                }
            }}
        >
            {data.plan?.id?.indexOf('access') > -1 ? (
                <Row>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="firstName" sm={12} md={6}>
                        <Form.Label>First Name:</Form.Label>
                        <Form.Control
                            required
                            placeholder="John"
                            defaultValue={data.firstName ? data.firstName : ''}
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                        />
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="lastName" sm={12} md={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            required
                            placeholder="Doe"
                            defaultValue={data.lastName ? data.lastName : ''}
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                        />
                    </Form.Group>
                </Row>
            ) : (
                <></>
            )}
            <Row>
                <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="email" sm={12}>
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                        required
                        type="email"
                        defaultValue={data.email ? data.email : ''}
                        style={{ textTransform: 'lowercase' }}
                        autoCapitalize="none"
                        onKeyDown={e => {
                            if (e.key === ' ') {
                                e.preventDefault();
                            }
                        }}
                        onChange={e => {
                            const value = e.currentTarget.value.toLowerCase();
                            dispatch(setData({ [e.currentTarget.id]: value }));
                        }}
                    />
                </Form.Group>
                {data.plan?.id?.indexOf('access') > -1 ? (
                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="displayName" sm={12}>
                        <Form.Label>Display Name*</Form.Label>
                        <Form.Control
                            required
                            defaultValue={data.displayName ? data.displayName : ''}
                            onKeyDown={e => {
                                if (e.key === ' ') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                        />
                        <Form.Text className="text-muted">Must be between 5 and 15 characters & no spaces.</Form.Text>
                    </Form.Group>
                ) : (
                    <></>
                )}
            </Row>
            {data.plan?.id?.indexOf('access') > -1 ? (
                <Row>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="password" sm={12} md={6}>
                        <Form.Label>Password*</Form.Label>
                        <Form.Control
                            required
                            defaultValue={data.password ? data.password : ''}
                            type="password"
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                        />
                        <Form.Text className="text-muted">Password must be between 5 and 100 characters.</Form.Text>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="vpassword" sm={12} md={6}>
                        <Form.Label>Verify Password*</Form.Label>
                        <Form.Control
                            required
                            defaultValue={data.vpassword ? data.vpassword : ''}
                            type="password"
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                        />
                        <Form.Text className="text-muted">Passwords must match.</Form.Text>
                    </Form.Group>
                </Row>
            ) : (
                <></>
            )}
            {data.plan?.id?.indexOf('access') > -1 ? (
                <Row>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="twitter" sm={12}>
                        <Form.Label>Twitter (optional)</Form.Label>
                        <Form.Control
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                            placeholder="@LineProphetApp"
                        />
                        <Form.Text className="text-muted">Enter if you wish to follow the VIP Twitter account.</Form.Text>
                    </Form.Group>
                </Row>
            ) : (
                <></>
            )}
            <Row>
                <Form.Group as={Col} controlId="promo" sm={12}>
                    <Form.Label>Promo Code (optional)</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control
                            onChange={e =>
                                dispatch(
                                    setData({
                                        [e.currentTarget.id]: e.currentTarget.value,
                                    }),
                                )
                            }
                        />
                    </InputGroup>
                </Form.Group>
            </Row>
            <Button
                variant="primary"
                type="submit"
                style={{
                    marginTop: '25px',
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                }}
                disabled={loaders.registerLoading ? true : false}
            >
                {data.plan?.id === 'free-access' ? `Create Account` : `Continue`}
                <FontAwesomeIcon style={{ marginLeft: 7 }} icon={data.plan?.id === 'free-access' ? faUser : faArrowCircleRight} />
            </Button>
        </Form>
    );
};

export default Information;
