// Libraries imports
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Row, Col, Nav, Alert, Card, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
// Component imports
import SportsNav from '../../../../../components/page_components/SportsNav';
import Insights from './Insights';
import { LoadingSpinner } from '../../../../../components/page_components/LoadingSpinner';
// Controller imports
import { pickRecord } from '../../../../../controllers/UserController';
// Util imports
import { capitalize, formatPickJuice, formatPickValue, sortPicks } from '../../../../../utils';
// CSS imports
import 'react-calendar/dist/Calendar.css';
import '../../../../../css/Calendar.css';
// Styled Components
import { LabelValueListGroup } from '../../../../App/styles';
import { UnitBlock, ValueBlock } from './styles';

const VIPDrawer = () => {
    // Local state
    const [data, setData] = useState({});
    const [loadingWeekly, setLoadingWeekly] = useState(false);
    const [loadingTimeFrame, setLoadingTimeFrame] = useState(true);
    const [vipTimeframe, setVIPTimeFrame] = useState('week');
    const [sport, setSport] = useState('all');
    const [tab, setTab] = useState('weekly');

    useEffect(() => {
        async function fetchVIPData() {
            try {
                const timeFrameRes = await pickRecord(vipTimeframe, true);
                setData(timeFrameRes);
            } catch (error) {
                console.log(`Error getting timeframe picks VIP stats.\n${error.message}`);
            } finally {
                setLoadingTimeFrame(false);
                setLoadingWeekly(false);
            }
        }
        fetchVIPData();
    }, [vipTimeframe]);

    if (loadingTimeFrame) {
        return (
            <div>
                <LoadingSpinner loading={loadingTimeFrame} />
            </div>
        );
    }

    return (
        <div>
            <Row>
                <Col>
                    <Nav variant="tabs" className="mb-2" activeKey={tab}>
                        <Nav.Item>
                            <Nav.Link onClick={() => setTab('weekly')} eventKey="weekly">
                                Weekly Plays
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => setTab('insights')} eventKey="insights">
                                Advanced Insights
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            {tab === 'weekly' && data.picks ? (
                <Row>
                    <Col sm={12} md={6} style={{ marginBottom: '15px' }}>
                        <Card>
                            <Card.Header>Pending Plays</Card.Header>
                            <Card.Body>
                                <Alert className="text-center" variant="info">
                                    All units represent the risk amount
                                </Alert>
                                <ListGroup style={{ margin: '0px -15px' }} variant="flush">
                                    {sortPicks(data.picks, 'asc')
                                        .filter(pick => pick.result === 'pending')
                                        .map(pick => {
                                            return (
                                                <ListGroup.Item key={pick.pickId}>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                                <span style={{ fontSize: '12px', fontWeight: 400, color: '#666' }}>
                                                                    {moment(pick.gameTime).format('MM/DD h:mm A')}
                                                                </span>
                                                                <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#444' }}>{pick.teamName}</span>
                                                                <span style={{ fontSize: '12px', fontWeight: 400, color: '#666' }}>({pick.sport})</span>
                                                            </div>
                                                        </Col>
                                                        <ValueBlock xs={3} sm={3} md={3}>
                                                            <span className="value">{formatPickValue(pick)}</span>
                                                            <span className="juice">{formatPickJuice(pick)}</span>
                                                        </ValueBlock>
                                                        <UnitBlock xs={3} sm={3} md={3}>
                                                            <span className="value">{`${Number(pick.units).toFixed(1)}u`}</span>
                                                        </UnitBlock>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} style={{ marginBottom: '15px' }}>
                        <Card>
                            <Card.Header>Quick Insights</Card.Header>
                            <Card.Body>
                                <Nav variant="tabs" className="mb-2" activeKey={vipTimeframe}>
                                    {['week', 'month', 'season', 'all'].map((el, i) => {
                                        return (
                                            <Nav.Item key={i}>
                                                <Nav.Link
                                                    eventKey={el}
                                                    id={el}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setLoadingWeekly(true);
                                                        setVIPTimeFrame(e.target.id);
                                                    }}
                                                >
                                                    {capitalize(el === 'all' ? 'All' : el)}
                                                </Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </Nav>
                                <SportsNav click={setSport} activeItem={sport} />
                                {loadingWeekly ? (
                                    <LoadingSpinner loading={loadingWeekly} />
                                ) : (
                                    <LabelValueListGroup variant="flush">
                                        <ListGroup.Item>
                                            <label>Record: </label>
                                            <span
                                                style={{
                                                    fontSize: '3.25em',
                                                    marginTop: '5px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {`${data.record[sport].wins}-${data.record[sport].losses}`}
                                            </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <label>Units: </label>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '3.25em',
                                                        marginTop: '5px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {`${Number(data.record[sport].units).toFixed(2)}u`}
                                                </span>
                                                {data.record[sport].units >= 0 ? (
                                                    <FontAwesomeIcon
                                                        style={{
                                                            marginTop: '10px',
                                                            marginLeft: '10px',
                                                            fontSize: '2em',
                                                            color: 'green',
                                                        }}
                                                        icon={faArrowUp}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        style={{
                                                            marginTop: '10px',
                                                            marginLeft: '10px',
                                                            fontSize: '2em',
                                                            color: 'red',
                                                        }}
                                                        icon={faArrowDown}
                                                    />
                                                )}
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <label>ROI: </label>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '3.25em',
                                                        marginTop: '5px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {`${Number(data.record[sport].roi).toFixed(2)}%`}
                                                </span>
                                            </div>
                                        </ListGroup.Item>
                                    </LabelValueListGroup>
                                )}
                            </Card.Body>
                        </Card>
                        <Card className="mt-2">
                            <Card.Header>Weekly Results</Card.Header>
                            <Card.Body>
                                <Alert className="text-center" variant="info">
                                    All units represent the risk amount
                                </Alert>
                                <ListGroup style={{ margin: '0px -15px' }} variant="flush">
                                    {sortPicks(data.picks)
                                        .filter(pick => {
                                            if (pick.result !== 'pending') {
                                                var curr = new Date();
                                                curr.setHours(0, 0, 0, 0);
                                                const startOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay()));
                                                return moment(pick.gameTime).isSameOrAfter(startOfWeek);
                                            } else {
                                                return false;
                                            }
                                        })
                                        .map((pick, i) => {
                                            return (
                                                <ListGroup.Item variant={pick.result === 'win' ? 'success' : pick.result === 'loss' ? 'danger' : ''} key={i}>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 400,
                                                                        color: '#666',
                                                                    }}
                                                                >
                                                                    {moment(pick.gameTime).format('MM/DD h:mm A')}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        fontWeight: 'bold',
                                                                        color: '#444',
                                                                    }}
                                                                >
                                                                    {pick.teamName}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 400,
                                                                        color: '#666',
                                                                    }}
                                                                >
                                                                    ({pick.sport})
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <ValueBlock xs={3} sm={3} md={3}>
                                                            <span className="value">{formatPickValue(pick)}</span>
                                                            <span className="juice">{formatPickJuice(pick)}</span>
                                                        </ValueBlock>
                                                        <UnitBlock xs={3} sm={3} md={3}>
                                                            <span className="value">{`${Number(pick.units).toFixed(1)}u`}</span>
                                                        </UnitBlock>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Insights />
            )}
        </div>
    );
};

export default VIPDrawer;
