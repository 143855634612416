// Library imports
import React from 'react';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// Controller imports
import { addPick } from '../../../../controllers/UserController';
// Util imports
import { createPickId } from '../../../../utils';
import { PICK_OBJ } from '../../../../utils/constants';
// Styled components
import { CardTitle } from '../styles';
// Action imports
import { setUser } from '../../../../store/slices/userSlice';
import { addLoader, removeLoader } from '../../../../store/slices/appSlice';

const Confirm = ({ game, setView, pickData, setPickData }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const { sport, loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();

    const resetPickData = () => {
        setPickData({ ...PICK_OBJ, units: user.units ? user.units : 1 });
        setView('odds');
    };

    const handleAddPick = async (gameId, dateTime) => {
        try {
            dispatch(addLoader('add-pick'));
            const newPicks = await addPick({
                ...pickData,
                userId: user.uid,
                sport: sport,
                gameId: gameId,
                pickId: createPickId(),
                gameTime: dateTime,
            });
            dispatch(setUser({ ...user, picks: newPicks }));
            resetPickData();
        } catch (error) {
            console.log(`Error saving pick\n${error}`);
        } finally {
            dispatch(removeLoader('add-pick'));
        }
    };

    return (
        <Card>
            <CardTitle>{pickData.teamName}</CardTitle>
            <Card.Body style={{ paddingTop: 0 }}>
                <div>
                    <Button variant="danger" size="sm" className="mr-1" onClick={resetPickData}>
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleAddPick(game.gameId, game.dateTime)}
                        disabled={loaders.find(loader => loader === 'add-pick')}
                    >
                        Track Bet
                    </Button>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                            <label>
                                Value
                                <span style={{ fontSize: '12px', color: '#aaa', marginLeft: '5px' }}>({pickData.pickType})</span>
                            </label>
                            <span>
                                <Form.Control
                                    value={pickData.pickValue}
                                    type="number"
                                    style={{ maxWidth: 350 }}
                                    onChange={e => setPickData({ ...pickData, pickValue: e.target.value })}
                                    step={0.5}
                                />
                            </span>
                        </Col>
                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                            {pickData.pickType === 'moneyline' ? null : (
                                <>
                                    <label>Juice</label>
                                    <span>
                                        <Form.Control
                                            value={pickData.pickJuice}
                                            type="number"
                                            style={{ maxWidth: 350 }}
                                            onChange={e => setPickData({ ...pickData, pickJuice: e.target.value })}
                                            step={0.5}
                                        />
                                    </span>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                            <label>Units</label>
                            <span>
                                <Form.Control
                                    value={pickData.units}
                                    type="number"
                                    style={{ maxWidth: 350 }}
                                    onChange={e =>
                                        setPickData({
                                            ...pickData,
                                            units: e.target.value,
                                        })
                                    }
                                    step={0.5}
                                />
                            </span>
                        </Col>
                    </Row>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Confirm;
