// Library imports
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown as BootStrapDropdown } from 'react-bootstrap';
// Styled components
import { ClearButton, MenuContainer, MenuItem, SearchBar, Toggle } from './styles';
import { SPORTS } from '../../../utils/constants';

const formatValue = value => {
    if (!value) {
        return 'Select...';
    }
    if (SPORTS.includes(value)) {
        return String(value).toUpperCase();
    }
    if (value === 'all') {
        return 'All';
    }
    return value;
};

const Dropdown = ({
    dropdownStyle = {},
    toggleStyle = {},
    menuStyle = {},
    value,
    setValue,
    menuItems = [],
    variant = 'light',
    caption = '',
    searchable = false,
    clearable = false,
}) => {
    const [searchValue, setSearchValue] = useState('');
    return (
        <BootStrapDropdown
            style={{ width: '100%', ...dropdownStyle }}
            onSelect={key => {
                setSearchValue('');
                setValue(key);
            }}
        >
            <Toggle variant={variant} clearable={clearable} style={{ ...toggleStyle }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    {formatValue(value)}
                    {caption ? <span style={{ fontSize: 10 }}>{caption}</span> : <></>}
                </div>
            </Toggle>
            {clearable ? (
                <ClearButton variant="outline-danger" onClick={() => setValue('')}>
                    <FontAwesomeIcon icon={faTimes} />
                </ClearButton>
            ) : (
                <></>
            )}
            <MenuContainer clearable={clearable} style={{ ...menuStyle }}>
                {searchable ? <SearchBar autoFocus placeholder="Type to filter..." onChange={e => setSearchValue(e.target.value)} value={searchValue} /> : null}
                {menuItems
                    .filter(item => !searchValue || item.toLowerCase().includes(searchValue))
                    .map((item, i) => {
                        return (
                            <MenuItem key={i} lastItem={menuItems.length - 1 === i} eventKey={item ? item : item}>
                                {formatValue(item)}
                            </MenuItem>
                        );
                    })}
            </MenuContainer>
        </BootStrapDropdown>
    );
};

export default Dropdown;
