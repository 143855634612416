// Library imports
import React from 'react';
import { Cell } from 'rsuite-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// Styled components
import { TeamLogo } from './styles';

export const DeleteCell = ({ rowData, dataKey, handleDeleteRow, ...props }) => {
    var deleteParam = '';
    if (rowData['pickId']) deleteParam = rowData['pickId'];
    if (rowData['gameId']) deleteParam = rowData['gameId'];
    if (rowData['userId']) deleteParam = rowData['userId'];
    return (
        <Cell {...props}>
            <FontAwesomeIcon onClick={() => handleDeleteRow(deleteParam)} style={{ cursor: 'pointer', fontSize: '1.15em', color: 'red' }} icon={faTrashAlt} />
        </Cell>
    );
};

export const EditCell = ({ rowData, dataKey, handleEditRow, ...props }) => {
    var editParam = '';
    if (rowData['pickId']) editParam = rowData['pickId'];
    if (rowData['gameId']) editParam = rowData['gameId'];
    return (
        <Cell {...props}>
            <FontAwesomeIcon onClick={() => handleEditRow(editParam)} style={{ cursor: 'pointer', fontSize: '1.15em', color: 'blue' }} icon={faEdit} />
        </Cell>
    );
};

export const ColoredCell = ({ rowData, dataKey, ...props }) => {
    const colText = rowData[dataKey];
    const { backgroundColor, textColor } =
        colText === 'Win' ? { backgroundColor: '#dff0d8', textColor: '#3c763d' } : { backgroundColor: '#f2dede', textColor: '#a94442' };
    return (
        <Cell {...props} style={{ backgroundColor, textColor }}>
            {colText}
        </Cell>
    );
};

export const TeamCell = ({ rowData, dataKey, ...props }) => {
    const colText = rowData[dataKey];
    const teamImage = rowData['imgSrc'];
    return (
        <Cell {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {teamImage ? <TeamLogo src={teamImage} alt={colText} /> : <></>}
                <span style={{ fontWeight: 'bold', color: '#444' }}>{colText}</span>
            </div>
        </Cell>
    );
};
