// Library imports
import React from 'react';
import { ListGroup } from 'react-bootstrap';
// Component imports
import NoData from '../../../components/page_components/NoData';
// Styled components
import { InnerContent } from './styles';

const Headlines = ({ game }) => {
    const nthIndex = (str, pat, n) => {
        var L = str.length,
            i = -1;
        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    };

    if (!game.headlines) {
        return <NoData title="No data" message="There are currently no headlines for this game.  Check back soon." />;
    }
    return (
        <InnerContent>
            <ListGroup style={{ overflowX: 'auto' }} className="headlinesList p-2">
                {game.headlines.map((headline, i) => {
                    var title = headline.title.substr(0, 100) + '...';
                    var description = headline.description.substr(0, 100) + '...';
                    var href = headline.href.substr(headline.href.indexOf('q=') + 2, nthIndex(headline.href, '.com', 2));
                    return (
                        <ListGroup.Item className="mb-2" key={i}>
                            <a style={{ display: 'block' }} target="_blank" rel="noopener noreferrer" href={headline.href}>
                                {title}
                            </a>
                            <span style={{ fontSize: '12px', color: '#666' }} className="headlineHref">
                                {href}
                            </span>
                            <span style={{ fontSize: '12px', color: '#666' }} className="headlineDescription">
                                {description}
                            </span>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </InnerContent>
    );
};

export default Headlines;
