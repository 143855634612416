// Library imports
import React from 'react';
import { Cell } from 'rsuite-table';

export const ScheduleGameCell = ({ rowData, dataKey, ...props }) => {
    const colText = rowData[dataKey];
    const colArr = colText.split('\n');
    const resultText = rowData['schedule-result'];
    const { backgroundColor, textColor } = String(resultText).toLowerCase().includes('w')
        ? { backgroundColor: '#dff0d8', textColor: '#3c763d' }
        : String(resultText).toLowerCase().includes('l')
        ? { backgroundColor: '#f2dede', textColor: '#a94442' }
        : { backgroundColor: '#fff', textColor: '#000' };
    return (
        <Cell {...props} style={{ backgroundColor, textColor }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        color: '#666',
                    }}
                >
                    {colArr[0]}
                </span>
                <span
                    style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#444',
                    }}
                >
                    {colArr[1]}
                </span>
            </div>
        </Cell>
    );
};

export const ScheduleResultCell = ({ rowData, dataKey, ...props }) => {
    const colText = rowData[dataKey];
    const resultText = rowData['schedule-result'];
    const { backgroundColor, textColor } = String(resultText).toLowerCase().includes('w')
        ? { backgroundColor: '#dff0d8', textColor: '#3c763d' }
        : String(resultText).toLowerCase().includes('l')
        ? { backgroundColor: '#f2dede', textColor: '#a94442' }
        : { backgroundColor: '#fff', textColor: '#000' };
    return (
        <Cell
            {...props}
            style={{
                backgroundColor,
                textColor,
                fontSize: '14px',
                fontWeight: '500',
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
                paddingRight: '15px',
            }}
        >
            {colText}
        </Cell>
    );
};
