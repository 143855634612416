// Library and Component imports
import React from 'react';
import { SocialIcon } from 'react-social-icons';
// CSS imports
import '../css/Footer.css';

const Footer = () => {
    return (
        <footer className="main_footer">
            <div className="lower">
                <div className="inner">
                    <ul className="footer_sitemap">
                        <li className="sitemap_block">
                            <ul>
                                <li className="sitemap_heading">
                                    <a title="General" href="/">
                                        General
                                    </a>
                                </li>
                                <li>
                                    <a title="Home" href="/">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a title="Blog" rel="noopener noreferrer" target="_blank" href="https://blog.lineprophet.com/">
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a title="Sportsbook" rel="noopener noreferrer" target="_blank" href="https://vipclub.lv/#/signup/lineprophet">
                                        Sportsbook Partner
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="sitemap_block">
                            <ul>
                                <li className="sitemap_heading">
                                    <a href="/login">Line Prophet</a>
                                </li>
                                <li>
                                    <a title="login" href="/login">
                                        Log in
                                    </a>
                                </li>
                                <li>
                                    <a title="app" href="/sports">
                                        Game Analysis
                                    </a>
                                </li>
                                <li>
                                    <a title="app-store" target="_blank" href="https://apps.apple.com/us/app/line-prophet/id1641926747">
                                        App Store
                                    </a>
                                </li>
                                <li>
                                    <a
                                        title="google-play"
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.kwaggoner00.lineProphet&pli=1"
                                    >
                                        Google Play
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="sitemap_block">
                            <ul>
                                <li className="sitemap_heading">
                                    <a href="/">Legal</a>
                                </li>
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        title="privacy"
                                        href="https://www.privacypolicies.com/live/2fe2e855-51bd-49ea-9547-15140b9c4134"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        title="tandc"
                                        href="https://www.privacypolicies.com/live/24b09fba-d008-47b2-a1ea-f3400ec50625"
                                    >
                                        Terms and Conditions
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div style={{ marginTop: '2rem' }} id="social-icons">
                        <SocialIcon url="https://twitter.com/LineProphetLLC" />
                        <SocialIcon url="https://www.instagram.com/lineprophetllc/" />
                    </div>
                </div>
            </div>
            <div className="extra">
                <div className="inner">
                    <small>Copyright © 2021 Line Prophet LLC.</small>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
