export default {
    title: 'Key Factors',
    description: 'Win percentage for teams that have a better statistic in the following categories.',
    id: 'key-factors',
    data: [
        {
            id: 'astTo',
            label: 'AST:TO%',
            type: 'off',
            winner: 'gt',
            threshold: 0.1,
            moneyline: {
                wins: 144,
                losses: 115,
                entries: 259,
            },
            spread: {
                wins: 131,
                losses: 128,
                entries: 259,
            },
        },
        {
            id: 'oppg',
            label: 'PPG',
            type: 'off',
            winner: 'gt',
            threshold: 5,
            moneyline: {
                wins: 141,
                losses: 117,
                entries: 258,
            },
            spread: {
                wins: 128,
                losses: 130,
                entries: 258,
            },
        },
        {
            id: 'floorPercent',
            label: 'Floor %',
            type: 'off',
            winner: 'gt',
            threshold: 2,
            moneyline: {
                wins: 138,
                losses: 122,
                entries: 260,
            },
            spread: {
                wins: 123,
                losses: 137,
                entries: 260,
            },
        },
        {
            id: 'offEff',
            label: 'oEff%',
            type: 'off',
            winner: 'gt',
            threshold: 0.5,
            moneyline: {
                wins: 150,
                losses: 108,
                entries: 258,
            },
            spread: {
                wins: 131,
                losses: 127,
                entries: 258,
            },
        },
        {
            id: 'threePoint',
            label: '3 Point %',
            type: 'off',
            winner: 'gt',
            threshold: 1,
            moneyline: {
                wins: 144,
                losses: 112,
                entries: 256,
            },
            spread: {
                wins: 138,
                losses: 118,
                entries: 256,
            },
        },
        {
            id: 'reboundPercent',
            label: 'REB %',
            type: 'def',
            winner: 'gt',
            threshold: 2,
            moneyline: {
                wins: 134,
                losses: 121,
                entries: 255,
            },
            spread: {
                wins: 134,
                losses: 121,
                entries: 255,
            },
        },
        {
            id: 'ppg',
            label: 'DPPG',
            type: 'def',
            winner: 'lt',
            threshold: 5,
            moneyline: {
                wins: 138,
                losses: 120,
                entries: 258,
            },
            spread: {
                wins: 121,
                losses: 137,
                entries: 258,
            },
        },
        {
            id: 'defEff',
            label: 'Def. Eff%',
            type: 'def',
            winner: 'gt',
            threshold: 0.5,
            moneyline: {
                wins: 122,
                losses: 134,
                entries: 256,
            },
            spread: {
                wins: 135,
                losses: 121,
                entries: 256,
            },
        },
        {
            id: 'fouls',
            label: 'Fouls PG',
            type: 'def',
            winner: 'lt',
            threshold: 1,
            moneyline: {
                wins: 131,
                losses: 127,
                entries: 258,
            },
            spread: {
                wins: 122,
                losses: 136,
                entries: 258,
            },
        },
    ],
    type: 'bar',
    sport: 'all',
    options: ['moneyline', 'spread'],
    tags: ['Stats'],
};
