// Library imports
import React from 'react';
import { Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import Dropdown from '../DropDown';
// Util imports
import { capitalize } from '../../../utils';
import { All_FILTERS } from '../../../utils/constants';
// Action imports
import { setFilters } from '../../../store/slices/appSlice';
// Styled components
import { SearchFilterContainer, SearchFilterRow } from './styles';

const filterLabels = {
    0: 'All',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
};

const GameSearchFilter = ({
    gameData,
    startDate,
    setActiveDate,
    activeDate,
    value,
    setValue,
    ratingFilter,
    setRatingFilter,
}) => {
    // Redux
    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.app);
    return (
        <SearchFilterContainer>
            <SearchFilterRow>
                <Col md={12} lg={2} className="order-2-col pl-0 pr-0 d-flex align-items-stretch">
                    <Dropdown
                        icon={<FontAwesomeIcon icon={faCalendar} />}
                        toggleStyle={{
                            borderRadius: 0,
                            flex: 0,
                            minWidth: 50,
                            background: '#fff',
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                            borderRight: '1px solid #ccc',
                        }}
                        value={moment(activeDate).isValid() ? moment(activeDate).format('MM/DD/YYYY') : '---'}
                        setValue={selectedKey => setActiveDate(selectedKey)}
                        menuItems={[...new Set(gameData.map(item => moment(item.dateTime).format('MM/DD/YYYY')))].sort(
                            (a, b) => {
                                if (moment(a).isAfter(b)) return 1;
                                if (!moment(a).isBefore(b)) return -1;
                                if (moment(a).isSame(b)) return 1;
                            },
                        )}
                    />
                </Col>
                <Col md={12} lg={6} className="search-col pl-0 pr-0 d-flex align-items-stretch">
                    <Form
                        style={{
                            width: '100%',
                            display: 'flex',
                            background: '#fff',
                        }}
                        onSubmit={e => {
                            e.preventDefault();
                            document.querySelector('#card-search').blur();
                            return false;
                        }}
                    >
                        <InputGroup>
                            <Form.Control
                                type="search"
                                placeholder="Start typing team name..."
                                aria-label="Search"
                                id="card-search"
                                onChange={e => setValue(e.target.value)}
                                value={value}
                                style={{
                                    borderRadius: 0,
                                    borderWidth: 0,
                                    height: 'auto',
                                }}
                            />
                            <Button
                                variant="link"
                                size="sm"
                                style={{
                                    alignSelf: 'center',
                                    marginRight: '15px',
                                    color: 'red',
                                }}
                                onClick={() => {
                                    setValue('');
                                    setRatingFilter(0);
                                    dispatch(setFilters('Upcoming'));
                                    setActiveDate(startDate);
                                }}
                            >
                                Clear Filters
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md={12} lg={4} className="order-3-col pl-0 pr-0 d-flex align-items-stretch">
                    <Dropdown
                        icon={<FontAwesomeIcon icon={faFilter} />}
                        dropdownStyle={{ width: '60%' }}
                        toggleStyle={{
                            borderRadius: 0,
                            borderLeft: '1px solid #ccc',
                            borderRight: '1px solid #ccc',
                            flex: 0,
                            minWidth: 50,
                            background: '#fff',
                        }}
                        value={
                            All_FILTERS.find(filter => filter.id === filters[0])
                                ? All_FILTERS.find(filter => filter.id === filters[0]).label
                                : capitalize(filters[0])
                        }
                        setValue={selectedKey =>
                            dispatch(
                                setFilters(
                                    All_FILTERS.find(filter => filter.label === selectedKey)
                                        ? All_FILTERS.find(filter => filter.label === selectedKey).id
                                        : selectedKey,
                                ),
                            )
                        }
                        menuItems={['Upcoming', 'Live', ...All_FILTERS].map(filter => {
                            return filter.id ? filter.label : filter;
                        })}
                    />
                    <Dropdown
                        dropdownStyle={{ width: '40%', borderRadius: 0 }}
                        toggleStyle={{
                            borderRadius: 0,
                            flex: 0,
                            minWidth: 50,
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                            background: '#fff',
                        }}
                        value={filterLabels[ratingFilter]}
                        setValue={selectedKey => setRatingFilter(selectedKey)}
                        menuItems={[0, 1, 2, 3, 4]}
                        caption="star(s)"
                    />
                </Col>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

export default GameSearchFilter;
