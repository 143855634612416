// Library imports
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
// Constant imports
import { SPORTS } from './constants';

export const calcClientEndDate = (packageId, reciept) => {
    // Free users have no end date
    var baseMoment = moment();
    if (reciept?.endDate && reciept?.endDate !== 'never') {
        const endDate = convertToISOString(reciept.endDate);
        // Use current date if end date is in the past
        baseMoment = moment(endDate).isBefore(moment()) ? moment() : moment(endDate);
    }
    if (packageId === 'daily-access') {
        return moment(baseMoment.add(1, 'days')).toISOString();
    }
    if (packageId === 'weekly-access') {
        return moment(baseMoment.add(1, 'weeks')).toISOString();
    }
    if (packageId === 'yearly-access') {
        return moment(baseMoment.add(1, 'years')).toISOString();
    }
    if (packageId === 'monthly-access') {
        return moment(baseMoment.add(1, 'months')).toISOString();
    }
    alert(`${packageId} is not valid, no end date created.`);
};

export const scrollToTop = () => {
    if (document.querySelector('#header-navbar')) {
        document.querySelector('#header-navbar').scrollIntoView({ behavior: 'smooth' });
    }
};

export const shortName = team => {
    const maxLength = 13;
    let result = team.shortName && team.shortName.length < team.teamName.length ? team.shortName : team.teamName;

    if (result.length > maxLength) {
        result = result.substring(0, maxLength - 3) + '...';
    }

    return result;
};

export const shortString = string => {
    var shortString = string;
    const maxLength = 13;

    if (string.length > maxLength) {
        shortString = string.substring(0, maxLength - 3) + '...';
    }

    return shortString;
};

export const createPickId = () => uuidv4();

export const addPlusSign = value => (value > 0 ? `+${value}` : value);

export const isSportsPage = sport => SPORTS.includes(sport);

export const formatOdd = (odd, type) => {
    if (odd === undefined || (odd === 0 && (type === 'moneyline' || type === 'moneyLine'))) {
        return 'NA';
    }
    return `${odd === 0 ? 'PK' : addPlusSign(odd)}`;
};

export const formatPickJuice = pick => {
    if (pick.pickType.includes('prop') || pick.pickType === 'parlay' || pick.pickType === 'teaser') {
        return capitalize(pick.pickType);
    }
    return pick.pickType === 'moneyline' ? 'ML' : addPlusSign(pick.pickJuice);
};

export const formatOddButtonJuice = (juice, playType) => {
    if (playType === 'prop-value') {
        return 'Prop';
    }
    return playType === 'moneyline' ? 'ML' : addPlusSign(juice);
};

export const formatPickValue = pick => {
    // Unlike with formatOddButtonJuice all props picks are saved as prop type (not prop-under, prop-over, prop-value)
    // The prop value is included in the play name and this is just the juice
    if (pick.pickType.includes('prop') || pick.pickType === 'parlay' || pick.pickType === 'teaser') {
        return addPlusSign(pick.pickJuice);
    }
    if (pick.pickType.includes('under')) {
        return `u${pick.pick}`;
    }
    if (pick.pickType.includes('over')) {
        return `o${pick.pick}`;
    }
    return formatOdd(pick.pick, pick.pickType);
};

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    const wordArray = s.split(' ');
    var finalString = '';
    wordArray.forEach(word => {
        finalString = finalString + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
    });
    finalString = finalString.trim();
    return finalString;
};

export const insert = (arr, index, newItem) => {
    return !arr
        ? arr
        : [
              // part of the array before the specified index
              ...arr.slice(0, index),
              // inserted item
              newItem,
              // part of the array after the specified index
              ...arr.slice(index),
          ];
};

export const convertToISOString = date => {
    try {
        // Having new Date format the date before iOSString helps because moment can't seem to format initial date correctly
        // moment can take in a date object and convert to string
        var parsedDate = moment(new Date(date));
        if (!parsedDate.isValid()) {
            parsedDate = moment(date, 'MMM D, YYYY'); // Schedule dates need extra work to format
        }
        if (!parsedDate.isValid()) {
            throw new Error('Invalid date format');
        }
        return parsedDate.toISOString();
    } catch (e) {
        console.log(`Format date error: ${e}`);
        return 'NA';
    }
};

export const sortPicks = (picks, order = 'desc') => {
    if (picks === undefined || picks === null || picks.length === 0) return [];
    let sortable = Object.keys(picks).map(i => picks[i]);
    sortable.sort((a, b) => {
        const aGameTime = a.gameTime;
        const bGameTime = b.gameTime;
        if (aGameTime === bGameTime) {
            const aTeamName = a.teamName;
            const bTeamName = b.teamName;
            if (aTeamName > bTeamName) return 1;
            if (bTeamName > aTeamName) return -1;
        }
        if (aGameTime > bGameTime) return order === 'desc' ? -1 : 1;
        if (bGameTime > aGameTime) return order === 'desc' ? 1 : -1;
        return 1;
    });
    return sortable;
};

export const sortGames = (games, order = 'desc') => {
    if (games === undefined || games === null || games.length === 0) return [];
    let sortable = Object.keys(games).map(i => games[i]);
    sortable.sort((a, b) => {
        const aGameTime = a.dateTime;
        const bGameTime = b.dateTime;
        if (aGameTime === bGameTime) {
            const aTeamName = a.awayTeam.teamName;
            const bTeamName = b.awayTeam.teamName;
            if (aTeamName > bTeamName) return 1;
            if (bTeamName > aTeamName) return -1;
        }
        if (aGameTime > bGameTime) return order === 'desc' ? -1 : 1;
        if (bGameTime > aGameTime) return order === 'desc' ? 1 : -1;
        return 1;
    });
    return sortable;
};
