import { getUserToken } from './UserController';

export const updateMatchupData = async sport => {
    sport = String(sport).toLowerCase();
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/games/upcoming?sport=${sport}`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const updateTeamData = async sport => {
    sport = String(sport).toLowerCase();
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/teams?sport=${sport}`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const updatePropData = async sport => {
    sport = String(sport).toLowerCase();
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/props?sport=${sport}`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const updateLeagueData = async sport => {
    sport = String(sport).toLowerCase();
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/league?sport=${sport}`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const saveGame = async (game, sport) => {
    sport = String(sport).toLowerCase();
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/games/saveGame`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ game, sport }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};
