// Library imports
import React from 'react';
// Styled components
import { StyledButton } from './styles';

const PrimaryButton = ({ children, ...restOfProps }) => {
    return (
        <StyledButton id="primary-button" variant="primary" {...restOfProps}>
            {children}
        </StyledButton>
    );
};

export default PrimaryButton;
