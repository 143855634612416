// Library imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Form, InputGroup } from 'react-bootstrap';
// Component imports
import Table from '../../../../components/page_components/TableV2';
import Dropdown from '../../../../components/page_components/DropDown';
// Controller imports
import { editPick, deletePick } from '../../../../controllers/UserController';
// Action imports
import { setUser } from '../../../../store/slices/userSlice';
// Styled components
import { ProfileContentContainer } from '../../styles';
import { SearchFilterBar } from './styles';
// Util imports
import { SPORTS } from '../../../../utils/constants';
import { capitalize, formatPickJuice, formatPickValue, sortPicks } from '../../../../utils';

const Picks = () => {
    //Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [loading, setLoading] = useState(false);
    const [pickArr, setPickArr] = useState([]);
    const [pickFilter, setPickFilter] = useState('all');
    const [showDate, setShowDate] = useState('Pending');
    const [value, setValue] = useState('');

    useEffect(() => {
        var picks = user.picks;
        if (pickFilter !== 'all') {
            setPickArr(sortPicks(picks, 'asc').filter(pick => pick.sport === pickFilter));
        } else {
            setPickArr(sortPicks(picks, 'asc'));
        }
    }, [pickFilter, user.picks]);

    const handleSavePick = async (pickId, result) => {
        try {
            setLoading(true);
            var pickDetails = pickArr.find(pick => pick.pickId === pickId);
            pickDetails = { ...pickDetails, result: result };
            const newPicks = await editPick(pickDetails);
            dispatch(setUser({ ...user, picks: newPicks }));
        } catch (error) {
            console.log(`Error saving pick edit\n${error}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePick = async pickId => {
        try {
            setLoading(true);
            const newPicks = await deletePick(pickId);
            dispatch(setUser({ ...user, picks: newPicks }));
        } catch (error) {
            console.log(`Error deleting pick.\n${error}`);
        } finally {
            setLoading(false);
        }
    };

    const createDateList = () => {
        const dateSet = new Set();
        for (const game of pickArr) {
            const gameDate = moment(game.gameTime).format('MM-DD-YYYY');
            dateSet.add(gameDate);
        }
        const dateList = Array.from(dateSet).slice(0, 5);
        return ['Pending', ...dateList];
    };

    const configureColumns = () => {
        const filteredPicks = pickArr.filter(pick => {
            if (pick.sport === pickFilter || pickFilter === 'all') {
                if (showDate === 'Pending' && pick.result === 'pending') {
                    return true;
                }
                if (moment(pick.gameTime).isSame(showDate, 'D')) {
                    return true;
                }
            }
            return false;
        });
        const data = filteredPicks.map(pick => {
            return {
                pickId: pick.pickId,
                result: capitalize(pick.result),
                play: `${moment(pick.gameTime).format('MM/DD hh:mm A')}\n${pick.teamName}\n${pick.sport.toUpperCase()}`,
                'odd-value': `${formatPickValue(pick)}\n${formatPickJuice(pick)}`,
                units: `${pick.units}u`,
            };
        });
        return data;
    };

    return (
        <ProfileContentContainer>
            <SearchFilterBar>
                <Dropdown
                    value={pickFilter}
                    setValue={setPickFilter}
                    menuItems={['all', ...SPORTS]}
                    caption="Filter by sport"
                    dropdownStyle={{
                        flex: 1,
                        maxWidth: '250px',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                    }}
                    toggleStyle={{ backgroundColor: '#fff' }}
                />
                <Dropdown
                    value={showDate}
                    setValue={setShowDate}
                    menuItems={createDateList()}
                    caption="Filter by date"
                    dropdownStyle={{
                        flex: 1,
                        maxWidth: '250px',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        marginLeft: '5px',
                    }}
                    toggleStyle={{ backgroundColor: '#fff' }}
                />
                <Form
                    style={{ flex: 3 }}
                    onSubmit={e => {
                        e.preventDefault();
                        return false;
                    }}
                >
                    <InputGroup>
                        <Form.Control
                            type="search"
                            placeholder="Start typing..."
                            aria-label="Search"
                            id="pick-search"
                            onChange={e => setValue(e.target.value)}
                            value={value}
                        />
                    </InputGroup>
                </Form>
            </SearchFilterBar>
            <div>
                <Table
                    loading={loading}
                    dataList={configureColumns()}
                    handleSavePick={handleSavePick}
                    handleDeleteRow={handleDeletePick}
                    autoHeight={true}
                    bordered
                    columns={[
                        { header: 'Result', dataKey: 'result', flexGrow: 1, minWidth: 135 },
                        { header: 'Play', dataKey: 'play', flexGrow: 2, minWidth: 200 },
                        { header: 'Value', dataKey: 'odd-value', flexGrow: 1, minWidth: 100 },
                        { header: 'Units', dataKey: 'units', flexGrow: 1 },
                        { header: 'Delete', dataKey: 'delete', flexGrow: 1 },
                    ]}
                />
            </div>
        </ProfileContentContainer>
    );
};

export default Picks;
