// Library imports
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const PickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: 925px) {
        flex-direction: column;
    }
`;

export const Picker = styled(Form.Control)`
    min-width: 400px;
    margin-right: 15px !important;
    @media (max-width: 925px) {
        margin-right: 0px !important;
        margin-bottom: 15px !important;
    }
`;
