// Library imports
import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faHistory } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// Component imports
import StatGraphs from './StatGraphs';
import HistoryTable from './HistoryTable';
// Controller imports
import { getAlgorithmStats } from '../../controllers/AlgorithmController';

const Algorithm = () => {
    // Redux
    const { sport } = useSelector(state => state.app);
    // Local state
    const [data, setData] = useState({});
    const [timeframe, setTimeframe] = useState('current_season');
    const [subpage, setSubpage] = useState('stats');

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getAlgorithmStats(sport, timeframe);
                setData(res);
            } catch (error) {
                console.log(`Error getting algo stats.\n${error}`);
            }
        }
        fetchData();
    }, [sport, timeframe]);

    return (
        <div>
            <Navbar bg="light" variant="light" style={{ border: '1px solid #ccc' }}>
                <Container>
                    <Nav className="justify-content-center w-100">
                        <Nav.Link
                            active={subpage === 'stats'}
                            onClick={() => setSubpage('stats')}
                            style={{ marginRight: 15 }}
                        >
                            <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartLine} /> Stats
                        </Nav.Link>
                        <Nav.Link
                            active={subpage === 'history'}
                            onClick={() => setSubpage('history')}
                            style={{ marginRight: 15 }}
                        >
                            <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faHistory} /> History
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            {subpage === 'stats' ? <StatGraphs data={data} /> : <HistoryTable games={data.games} />}
        </div>
    );
};

export default Algorithm;
