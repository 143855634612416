import styled from 'styled-components';

export const TrendsWrapper = styled.div`
    border-top: 1px solid #ddd;
    height: 65vh;
    min-height: 600px;
    margin-bottom: -25px;
    display: flex;
    justify-content: row;
`;

export const FilterWrapper = styled.div`
    padding: 0px 25px 25px 25px !important;
    border-right: 1px solid #ddd !important;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 500px;
`;

export const TrendDetailsWrapper = styled.div`
    margin-bottom: 15px;
    margin-top: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
`;

export const TrendTitle = styled.span`
    font-size: 16px;
    font-weight: bold;
    display: block;
`;

export const TrendDescription = styled.span`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #333;
`;

export const TrendFilter = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const TrendFilterTitle = styled.span`
    font-size: 16px;
    margin-bottom: 5px;
`;

export const TrendFilterDescription = styled.span`
    font-size: 14px;
    margin-top: 5px;
`;

export const TrendGraphWrapper = styled.div`
    background: rgb(246, 246, 246);
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 1 !important;
    display: flex;
    justify-content: center; /* Horizontally center the GraphWrapper */
    align-items: center; /* Vertically center the GraphWrapper */
`;
export const TrendGraphTitleWrapper = styled.span`
    display: flex;
    flex-direction: column;
`;

export const TrendGraphTitle = styled.span`
    font-size: 18px;
    font-weight: 600;
`;
export const TrendGraphSubtitle = styled.span`
    font-size: 14px;
    font-weight: 400;
`;

export const GraphWrapper = styled.div`
    background: #fff;
    padding: 25px;
    width: 50%;
    min-width: 800px;
    border-radius: 5px;
    border: 1px solid #ddd;
    overflow: auto;
`;

export const GraphHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;

// All Trends Components
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
