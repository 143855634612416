// Libray imports
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, ListGroup, Row } from 'react-bootstrap';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faLock } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// Component imports
import UpgradeTooltip from '../../../../components/page_components/UpgradeTooltip';
import TabNav from '../../../../components/page_components/TabNav';
import ToolTip from './ToolTip';
// Utils imports
import { formatOdd, shortName } from '../../../../utils';
// Styled components
import {
    TeamSectionContainer,
    TeamLogo,
    TeamNameWrapper,
    PowerDataContainer,
    PowerItemContainer,
    PowerRankArrow,
    PowerItemLabel,
    PowerCol,
    PowerTeamId,
    Score,
} from './styles';
import { CardTitle, HighLightedField, InnerContent, TeamRank } from '../styles';
import { LockButton } from '../../GameCard/styles';
import { LabelValueListGroup } from '../../../App/styles';
// CSS imports
import 'bootstrap/dist/css/bootstrap.min.css';
// Image imports
import defaultImage from '../../../../img/logo-icon-gray.png';

const PowerRank = ({ demo, game, sport, history }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    // Local state
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');

    const awayTeamVegasOdd = type === 'spread' ? game.awayTeam.odds?.fullGame?.spread : game.awayTeam.odds?.fullGame?.moneyline;
    const homeTeamVegasOdd = type === 'spread' ? game.homeTeam.odds?.fullGame?.spread : game.homeTeam.odds?.fullGame?.moneyline;

    const getPowerPrediction = team => {
        if (!game.prediction) return 'NA';
        const { awayScore, homeScore, powerMoneyline } = game.prediction;
        if (!awayScore && !homeScore) {
            return 'NA';
        }
        if (type === 'spread') {
            if (team === 'home') {
                return formatOdd((awayScore - homeScore).toFixed(2), 'spread');
            } else {
                return formatOdd((homeScore - awayScore).toFixed(2), 'spread');
            }
        } else {
            var oddsToFormat;
            if (team === 'home') {
                oddsToFormat = homeScore - awayScore > 0 ? powerMoneyline.favML : powerMoneyline.dogML;
            } else {
                oddsToFormat = awayScore - homeScore > 0 ? powerMoneyline.favML : powerMoneyline.dogML;
            }
            return formatOdd(oddsToFormat, 'moneyline');
        }
    };

    const calcWinPercentage = moneyline => {
        if (!moneyline || isNaN(moneyline)) return 0;
        if (!moneyline || isNaN(moneyline)) return 0;
        var probability = 0;
        if (moneyline < 0) {
            probability = (-1 * moneyline) / (-1 * moneyline + 100);
        }
        if (moneyline > 0) {
            probability = 100 / (moneyline + 100);
        }
        return Number((probability * 100).toFixed(0));
    };

    const calcSpreadDifference = (vegasSpread, powerSpread) => {
        if (!vegasSpread || isNaN(vegasSpread)) return 0;
        if (!vegasSpread || isNaN(powerSpread)) return 0;
        const difference = Number(vegasSpread - powerSpread).toFixed(2);
        return formatOdd(difference, 'spread');
    };

    const getHighlightVariant = team => {
        if (user?.reciept?.type === 'free-account') {
            return 'light';
        }
        if (type === 'moneyline') {
            if (isNaN(Number(getPowerPrediction(team)))) {
                return 'light';
            }
            if (calcWinPercentage(Number(getPowerPrediction(team))) > calcWinPercentage(game[`${team}Team`].odds?.fullGame?.moneyline)) {
                return 'success';
            } else {
                return 'danger';
            }
        }
        if (type === 'spread') {
            if (!calcSpreadDifference(game[`${team}Team`].odds?.fullGame?.spread, Number(getPowerPrediction(team)))) {
                return 'light';
            }
            if (calcSpreadDifference(game[`${team}Team`].odds?.fullGame?.spread, Number(getPowerPrediction(team))) > 0) {
                return 'success';
            } else {
                return 'danger';
            }
        }
        return 'light';
    };

    const imageLoadError = e => {
        e.target.src = defaultImage;
    };

    const generateTeamWrapper = team => {
        return (
            <TeamSectionContainer>
                <TeamNameWrapper side={game.awayTeam.teamId === team.teamId ? 'left' : 'right'}>
                    <TeamLogo src={team.logo} id={team.teamId} alt="team logo" onError={imageLoadError} />
                    <CardTitle color={team.color} responsive={true}>
                        {team.rank ? <TeamRank>#{team.rank}</TeamRank> : <></>}
                        {sport === 'cbb' ? shortName(team) : team.teamName}
                    </CardTitle>
                    {history ? <Score>{team.score}</Score> : <PowerTeamId>({team.teamId})</PowerTeamId>}
                </TeamNameWrapper>
                <LabelValueListGroup
                    direction={history ? 'column' : 'row'}
                    variant="flush"
                    responsive={true}
                    rightJustify={team.teamId === game.homeTeam.teamId}
                >
                    {!history && team.conference ? (
                        <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                            <label>League</label>
                            <span>
                                {team.conference.match(/\b(\w)/g).join('')} {team.division}
                            </span>
                        </ListGroup.Item>
                    ) : (
                        <></>
                    )}
                    {!history ? (
                        <>
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>Spread</label>
                                <span>{formatOdd(team.odds?.fullGame?.spread)}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>Moneyline</label>
                                <span>{formatOdd(team.odds?.fullGame?.moneyline, 'moneyline')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>Record</label>
                                <span>{team.recordData ? team.recordData.record : '0-0'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>ATS</label>
                                <span>{team.recordData?.ats ? `${team.recordData.ats}` : '0-0'}</span>
                            </ListGroup.Item>
                        </>
                    ) : (
                        <></>
                    )}
                </LabelValueListGroup>
            </TeamSectionContainer>
        );
    };

    return (
        <InnerContent>
            <Container className="mt-2" fluid style={{ overflow: 'hidden' }}>
                <Row style={{ flexWrap: 'nowrap' }}>
                    <PowerCol xs={4} sm={4} md={4} lg={4} xl={4} leftCol={true}>
                        {generateTeamWrapper(game.awayTeam)}
                    </PowerCol>
                    <PowerCol xs={4} sm={4} md={4} lg={4} xl={4} centerCol={true}>
                        <PowerDataContainer>
                            <TabNav tabs={['moneyline', 'spread']} activeTab={type} setActiveTab={setType} />
                            <ToolTip />
                            <PowerItemContainer>
                                <PowerItemLabel>Vegas</PowerItemLabel>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <HighLightedField
                                        variant={game.awayTeam.odds?.fullGame?.moneyline < game.homeTeam.odds?.fullGame?.moneyline ? 'primary' : 'light'}
                                    >
                                        <span>{formatOdd(awayTeamVegasOdd, type)}</span>
                                        {type === 'spread' ? (
                                            <span style={{ display: 'block', fontSize: '12px' }}>Spread</span>
                                        ) : (
                                            <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(awayTeamVegasOdd)}%</span>
                                        )}
                                    </HighLightedField>
                                    {game.awayTeam.odds?.fullGame?.moneyline < game.homeTeam.odds?.fullGame?.moneyline ? (
                                        <PowerRankArrow icon={faArrowAltCircleLeft} />
                                    ) : (
                                        <PowerRankArrow icon={faArrowAltCircleRight} />
                                    )}
                                    <HighLightedField
                                        variant={game.awayTeam.odds?.fullGame?.moneyline > game.homeTeam.odds?.fullGame?.moneyline ? 'primary' : 'light'}
                                    >
                                        <span>{formatOdd(homeTeamVegasOdd, type)}</span>
                                        {type === 'spread' ? (
                                            <span style={{ display: 'block', fontSize: '12px' }}>Spread</span>
                                        ) : (
                                            <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(homeTeamVegasOdd)}%</span>
                                        )}
                                    </HighLightedField>
                                </div>
                            </PowerItemContainer>
                            <PowerItemContainer>
                                <PowerItemLabel>Powerline</PowerItemLabel>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <HighLightedField variant={getHighlightVariant('away')}>
                                        <span>
                                            {!demo && user.reciept.type === 'free-account' ? (
                                                <UpgradeTooltip
                                                    children={
                                                        <LockButton variant="light">
                                                            <FontAwesomeIcon icon={faLock} />
                                                        </LockButton>
                                                    }
                                                />
                                            ) : (
                                                getPowerPrediction('away')
                                            )}
                                        </span>
                                        {user?.reciept?.type === 'free-account' ? (
                                            <></>
                                        ) : type === 'spread' ? (
                                            <span style={{ display: 'block', fontSize: '12px' }}>
                                                {calcSpreadDifference(game.awayTeam.odds?.fullGame?.spread, Number(getPowerPrediction('away')))}
                                            </span>
                                        ) : (
                                            <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(Number(getPowerPrediction('away')))}%</span>
                                        )}
                                    </HighLightedField>
                                    {user?.reciept?.type === 'free-account' ? (
                                        <></>
                                    ) : type === 'spread' ? (
                                        calcSpreadDifference(game.awayTeam.odds?.fullGame?.spread, Number(getPowerPrediction('away'))) > 0 ? (
                                            <PowerRankArrow icon={faArrowAltCircleLeft} />
                                        ) : (
                                            <PowerRankArrow icon={faArrowAltCircleRight} />
                                        )
                                    ) : game.advantages && game.advantages.find(a => a.id === 'power-rank').teamId === game.awayTeam.teamId ? (
                                        <PowerRankArrow icon={faArrowAltCircleLeft} />
                                    ) : (
                                        <PowerRankArrow icon={faArrowAltCircleRight} />
                                    )}
                                    <HighLightedField variant={getHighlightVariant('home')}>
                                        <span>
                                            {!demo && user.reciept.type === 'free-account' ? (
                                                <UpgradeTooltip
                                                    children={
                                                        <LockButton variant="light">
                                                            <FontAwesomeIcon icon={faLock} />
                                                        </LockButton>
                                                    }
                                                />
                                            ) : (
                                                getPowerPrediction('home')
                                            )}
                                        </span>
                                        {user?.reciept?.type === 'free-account' ? (
                                            <></>
                                        ) : type === 'spread' ? (
                                            <span style={{ display: 'block', fontSize: '12px' }}>
                                                {calcSpreadDifference(game.homeTeam.odds?.fullGame?.spread, Number(getPowerPrediction('home')))}
                                            </span>
                                        ) : (
                                            <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(Number(getPowerPrediction('home')))}%</span>
                                        )}
                                    </HighLightedField>
                                </div>
                            </PowerItemContainer>
                        </PowerDataContainer>
                    </PowerCol>
                    <PowerCol xs={4} sm={4} md={4} lg={4} xl={4} rightCol={true}>
                        {generateTeamWrapper(game.homeTeam)}
                    </PowerCol>
                </Row>
            </Container>
        </InnerContent>
    );
};

export default PowerRank;
