// Library imports
import React, { useState } from 'react';
import { Button, OverlayTrigger } from 'react-bootstrap';
// Styled components
import { UpgradeTooltipContainer } from './styles';

const UpgradeTooltip = ({ children, innerChildStyle = {}, label = 'You must be a VIP member to view this data.' }) => {
    const [show, setShow] = useState(false);

    return (
        <OverlayTrigger
            trigger={['hover', 'focus', 'click']}
            overlay={props => {
                return (
                    <UpgradeTooltipContainer
                        id="button-tooltip-2"
                        {...props}
                        onMouseEnter={() => setShow(true)}
                        onMouseLeave={() => setShow(false)}
                    >
                        <span style={{ display: 'block' }}>{label}</span>
                        <Button
                            variant="success"
                            size="sm"
                            style={{ marginTop: '5px' }}
                            onClick={() => (window.location = '/profile?subpage=settings')}
                        >
                            Upgrade Access
                        </Button>
                    </UpgradeTooltipContainer>
                );
            }}
            placement="auto"
            show={show}
        >
            <div
                style={{ position: 'relative', ...innerChildStyle }}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {children}
            </div>
        </OverlayTrigger>
    );
};

export default UpgradeTooltip;
