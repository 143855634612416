// Library imports
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: opacity 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    ${({ hover }) =>
        hover &&
        `
        &&&:hover {
            opacity: 0.9 !important; // Slightly darker background
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Subtle shadow
        }`}
`;
