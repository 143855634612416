export const dailyUnitData = {
    'Thu Aug 01 2024': -0.5000000000000001,
    'Fri Aug 02 2024': 4.627777777777778,
    'Sat Aug 03 2024': 4.659090909090909,
    'Sun Aug 04 2024': 4.268518518518519,
    'Mon Aug 05 2024': 1.9450697450697447,
    'Tue Aug 06 2024': -1.890909090909091,
    'Wed Aug 07 2024': -2.3810344827586207,
    'Thu Aug 08 2024': -1.2206983629397419,
    'Fri Aug 09 2024': -0.7777777777777777,
    'Sat Aug 10 2024': 1.72061191626409,
    'Sun Aug 11 2024': 2.130434782608696,
    'Mon Aug 12 2024': 2.3138195138195137,
    'Tue Aug 13 2024': 4.4,
    'Wed Aug 14 2024': -1.6530303030303033,
    'Thu Aug 15 2024': -3.00945945945946,
    'Fri Aug 16 2024': 1.7407407407407405,
    'Sun Aug 18 2024': -2.1385281385281383,
    'Mon Aug 19 2024': 1.3282828282828283,
    'Tue Aug 20 2024': -0.6499999999999999,
    'Wed Aug 21 2024': 2.169230769230769,
};

export const calendarData = [
    {
        x: 'January',
        y: 10.616433467265116,
    },
    {
        x: 'February',
        y: 41.20666019091579,
    },
    {
        x: 'March',
        y: 60.94634110897816,
    },
    {
        x: 'April',
        y: 38.467007932972095,
    },
    {
        x: 'May',
        y: -3.0153929562859405,
    },
    {
        x: 'June',
        y: 30.18188182991099,
    },
    {
        x: 'July',
        y: 6.682361923711245,
    },
    {
        x: 'August',
        y: -4.760797236040438,
    },
    {
        x: 'September',
        y: 13.26204771905541,
    },
    {
        x: 'October',
        y: 4.774947682478,
    },
    {
        x: 'November',
        y: 33.50897995564375,
    },
    {
        x: 'December',
        y: 0,
    },
];
