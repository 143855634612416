// Library imports
import React from 'react';
import { ResponsiveLine } from '@nivo/line';

export default function LineChart({ data, xAxisLabel, yAxisLabel, symbol = '', fixedAmount = 0 }) {
    return (
        <div style={{ height: '350px' }}>
            <ResponsiveLine
                data={[
                    {
                        id: xAxisLabel,
                        data: data.map(lineData => {
                            return {
                                x: lineData.x,
                                y: Number(lineData.y).toFixed(fixedAmount),
                            };
                        }),
                    },
                ]}
                margin={{ top: 50, right: 25, bottom: 50, left: 45 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: xAxisLabel,
                    legendOffset: 36,
                    legendPosition: 'middle',
                    format: () => '',
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yAxisLabel,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    format: d => `${d}${symbol}`,
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                label={d => <tspan y={-10}>{`${d.value}${symbol}`}</tspan>}
            />
        </div>
    );
}
