// Library imports
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { PaymentListItem } from '../../../../static_pages/register_pages/styles';

export const PackageDetailsCard = styled(Card)`
    @media (max-width: 767px) {
        margin-bottom: 15px;
    }
`;

export const PaymentListItemWithPadding = styled(PaymentListItem)`
    @media (max-width: 768px) {
        padding: 0.75rem 1.25rem !important;
    }
`;
