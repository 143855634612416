// Library imports
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const TwoColumnView = ({ columnOneJSX, columnTwoJSX }) => {
    return (
        <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: '3px' }}>
                {columnOneJSX}
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: '3px' }}>
                {columnTwoJSX}
            </Col>
        </Row>
    );
};

export default TwoColumnView;
