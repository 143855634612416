import styled from 'styled-components';
import { Button, Card, ListGroup } from 'react-bootstrap';

export const StageTitle = styled(Card.Title)`
    font-weight: bold !important;
`;

export const StageBackButton = styled(Button)`
    position: absolute;
    right: 15px;
    padding: 0px !important;
`;

export const PaymentListItem = styled(ListGroup.Item)`
    display: flex !important;
    flex-direction: column;
    .item-desc {
        color: #666;
        font-size: 0.8rem;
    }
    .item-details {
        font-size: 1.2rem;
        font-weight: bold;
    }
    @media (max-width: 768px) {
        padding: 0.75rem 0rem !important;
    }
`;
