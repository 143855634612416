// Library and Component impots
import React, { useState } from 'react';
import { Card, Button, Badge } from 'react-bootstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// Controller imports
import { updateSubscription } from '../../../../controllers/PaymentController';
// Util imports
import { PACKAGES } from '../../../../utils/constants';
// Action imports
import { fetchCustomer } from '../../../../store/slices/userSlice';

const Subscription = ({ subscription }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [confirmCancel, setConfirmCancel] = useState(false);
    // Local variables
    const packageData = PACKAGES[Object.keys(PACKAGES).find(key => PACKAGES[key].planId == subscription.plan.id)];

    const handleRenewSubscription = async () => {
        try {
            await updateSubscription(
                {},
                subscription.id,
                subscription.items.data[0].id,
                user.reciept.endDate,
                false,
                true,
            );
            dispatch(fetchCustomer());
            setConfirmCancel(false);
        } catch (error) {
            console.log(`Error renewing subscription.\n${error}`);
        }
    };

    const handleCancelAutoPay = async () => {
        try {
            await updateSubscription(
                {},
                subscription.id,
                subscription.items.data[0].id,
                user.reciept.endDate,
                true,
                false,
            );
            dispatch(fetchCustomer());
            setConfirmCancel(false);
        } catch (error) {
            console.log(`Error canceling autopay.\n${error}`);
        }
    };

    return (
        <Card
            style={{
                margin: '15px',
                border: 0,
                maxWidth: '450px',
                minWidth: '300px',
            }}
        >
            <Card.Body>
                <Card.Title
                    style={{
                        display: 'flex',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                    }}
                >
                    {packageData?.name ? packageData?.name : 'Package'}
                    <Badge
                        style={{
                            marginLeft: '10px',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            color: '#fff',
                        }}
                        pill
                        bg={!subscription.cancel_at_period_end ? 'success' : 'danger'}
                    >
                        {!subscription.cancel_at_period_end ? 'Active' : 'Canceled'}
                    </Badge>
                </Card.Title>
                <Card.Subtitle
                    className="text-muted"
                    style={{
                        borderBottom: '1px solid #ddd',
                        paddingBottom: '15px',
                        marginBottom: '15px',
                    }}
                >
                    {`${
                        subscription.cancel_at_period_end
                            ? `Cancellation date: ${moment.unix(subscription.current_period_end).calendar()}`
                            : `Next payment: ${moment.unix(subscription.current_period_end).calendar()}`
                    }
                    `}
                </Card.Subtitle>
                {subscription.cancel_at_period_end ? (
                    <Button className="mr-2" variant="success" onClick={() => handleRenewSubscription()}>
                        Renew
                    </Button>
                ) : confirmCancel ? (
                    <>
                        <Button className="mr-2" variant="secondary" onClick={() => setConfirmCancel(false)}>
                            Back
                        </Button>
                        <Button className="mr-2" variant="danger" onClick={() => handleCancelAutoPay()}>
                            Confirm Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        className="mr-2"
                        variant="danger"
                        disabled={subscription.cancel_at_period_end}
                        onClick={() => setConfirmCancel(true)}
                    >
                        Cancel
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};

export default Subscription;
