import styled from 'styled-components';

export const SidebarContainer = styled.div`
    position: fixed;
    top: ${props => (props.page === 'profile' ? '135px' : '140px')};
    right: 0px;
    display: flex;
    border-left: 0px;
    border-right: 0px;
    flex-direction: column;
    background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #fefefe 0%, #f5f5f5 74%);
    z-index: 99999;
    @media (max-width: 991px) {
        top: ${props => (props.page === 'profile' ? '170px' : '175px')} !important;
    }
`;

export const TabContainer = styled.div`
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    flex-grow: 1;
    background-color: #fff;
    box-shadow: -2px 5px 5px #ccc;
    opacity: 0.8;
    @media (max-width: 480px) {
        opacity: 0.65;
    }
    &:hover {
        opacity: 1;
    }
`;

export const Tab = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 10px;
    padding: 15px 5px;
    color: #2b7dc5;
    border-left: 2px solid transparent;
    width: 80px;
    &:hover {
        cursor: pointer;
        border-left: 2px solid #2b7dc5;
        background: rgba(0, 0, 0, 0.05);
    }
    svg {
        font-size: 24px;
        margin-bottom: 10px;
    }
`;

export const BackDrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: 0;
    right: 0;
`;
