// Library imports
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { Dropdown as BootStrapDropdown } from 'react-bootstrap';

export const Toggle = styled(BootStrapDropdown.Toggle)`
    width: ${props => (props.clearable ? 'calc(100% - 25px)' : '100%')} !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 100% !important;
`;

export const MenuContainer = styled(BootStrapDropdown.Menu)`
    width: ${props => (props.clearable ? 'calc(100% - 25px)' : '100%')} !important;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px !important;
    padding: 0px !important;
    overflow: auto !important;
    max-height: 250px;
`;

export const MenuItem = styled(BootStrapDropdown.Item)`
    padding: 10px 13px !important;
    border-bottom: ${props => (props.lastItem ? '' : '1px solid #ddd')} !important;
    background-color: #fff !important;
    color: #000;
    &:active,
    &:hover {
        background-color: #f8f9fa !important;
        color: #000 !important;
    }
`;

export const ClearButton = styled(Button)`
    position: absolute;
    padding: 0.375rem 0px !important;
    top: 0;
    right: 0;
    border: 0px !important;
    background-color: #fff !important;
    color: #dc3545 !important;
`;

export const SearchBar = styled(Form.Control)`
    width: 98% !important;
    margin: 5px auto !important;
    border-radius: 5px !important;
`;
