import styled from 'styled-components';

export const SearchFilterBar = styled.div`
    padding: 5px;
    background: rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    height: 63px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    form,
    .input-group,
    input#pick-search {
        height: 100%;
    }

    input#pick-search {
        margin-left: 5px;
    }
`;
