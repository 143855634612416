// Library imports
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faFootballBall, faBasketballBall, faBaseballBall, faHockeyPuck, faBoxOpen, faLaptop } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
// Component imports
import UpgradeTooltip from '../page_components/UpgradeTooltip';
// Util imports
import { isSportsPage } from '../../utils';
import { ACTIVE_SPORTS } from '../../utils/constants';
import { WHITE } from '../../utils/styleConstants';
// Styled Components
import { SocialIconContainer } from './styles';
// CSS imports
import '../../css/Header.css';
// Image imports
import logo from '../../img/horiz-logos/white.png';
import lightLogo from '../../img/horiz-logos/color.png';
import appstoreicon from '../../img/appstoreicon.png';

const staticLinks = [
    {
        id: 'home',
        icon: <FontAwesomeIcon icon={faHome} />,
        label: 'Home',
        disabled: false,
    },
    {
        id: 'about',
        icon: <FontAwesomeIcon icon={faLaptop} />,
        label: 'Details',
        disabled: false,
    },
    {
        id: 'packages',
        icon: <FontAwesomeIcon icon={faBoxOpen} />,
        label: 'Packages',
        disabled: false,
    },
    {
        id: 'login',
        icon: <FontAwesomeIcon icon={faUserCircle} />,
        label: 'Log in',
        disabled: false,
    },
];

const appLinks = [
    {
        id: 'mlb',
        icon: <FontAwesomeIcon icon={faBaseballBall} />,
        label: 'MLB',
        disabled: !ACTIVE_SPORTS.includes('mlb'),
    },
    {
        id: 'nfl',
        icon: <FontAwesomeIcon icon={faFootballBall} />,
        label: 'NFL',
        disabled: !ACTIVE_SPORTS.includes('nfl'),
    },
    {
        id: 'cfb',
        icon: <FontAwesomeIcon icon={faFootballBall} />,
        label: 'CFB',
        disabled: !ACTIVE_SPORTS.includes('cfb'),
    },
    {
        id: 'nhl',
        icon: <FontAwesomeIcon icon={faHockeyPuck} />,
        label: 'NHL',
        disabled: !ACTIVE_SPORTS.includes('nhl'),
    },
    {
        id: 'nba',
        icon: <FontAwesomeIcon icon={faBasketballBall} />,
        label: 'NBA',
        disabled: !ACTIVE_SPORTS.includes('nba'),
    },
    {
        id: 'cbb',
        icon: <FontAwesomeIcon icon={faBaseballBall} />,
        label: 'CBB',
        disabled: !ACTIVE_SPORTS.includes('cbb'),
    },
    {
        id: 'profile',
        icon: <FontAwesomeIcon size="2x" icon={faUserCircle} />,
        label: '',
        disabled: false,
    },
];

export const Header = ({ lightMode, showBanner = false, shadow, currentPage }) => {
    // Redux
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    // Local state
    const [activeKey, setActiveKey] = useState(currentPage);
    const bannerEnabled = showBanner && user?.reciept?.type === 'free-account' ? true : false;
    const history = useHistory();

    const getLinkArray = () => {
        if (currentPage === 'home' || currentPage === 'about' || currentPage === 'packages' || currentPage === 'login' || currentPage === 'free-play')
            return staticLinks;
        if (isSportsPage(currentPage) || currentPage === 'profile') return appLinks;
        return [];
    };

    const changeMainPage = async e => {
        const pageName = e.currentTarget.id;
        setActiveKey(pageName);
        if (pageName === 'home') {
            history.push(`/`);
        }
        if (pageName === 'login') {
            history.push(`/sports/${ACTIVE_SPORTS[0]}`);
        }
        if (pageName === 'profile') {
            history.push(`/profile`);
        }
        if (pageName === 'about') {
            history.push(`/about`);
        }
        if (pageName === 'free-play') {
            history.push('/free-play');
        }
        if (pageName === 'packages') {
            return history.push('/packages');
        }
        if (pageName === 'blog') {
            window.open('https://blog.lineprophet.com/', '_blank');
        }
        if (isSportsPage(pageName)) {
            history.push(`/sports/${pageName}`);
        }
    };

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" id="header-navbar" className={`${!lightMode ? 'navbar-dark-bg' : ''} ${shadow ? 'shadow' : ''}`}>
                <Navbar.Brand href="/">
                    <img src={lightMode ? lightLogo : logo} alt="home" className="d-inline-block align-top header-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                {!user?.reciept ? (
                    <></>
                ) : user.reciept?.type === 'free-account' ? (
                    <SocialIconContainer>
                        <UpgradeTooltip
                            children={
                                <SocialIcon
                                    style={{
                                        height: '35px',
                                        width: '35px',
                                        pointerEvents: 'none',
                                    }}
                                    network="twitter"
                                />
                            }
                            innerChildStyle={{
                                display: 'inline-block',
                                marginRight: '5px',
                            }}
                            label="You must be a VIP member access private Twitter."
                        />
                        <UpgradeTooltip
                            children={
                                <SocialIcon
                                    style={{
                                        height: '35px',
                                        width: '35px',
                                        pointerEvents: 'none',
                                    }}
                                    network="discord"
                                />
                            }
                            innerChildStyle={{ display: 'inline-block' }}
                            label="You must be a VIP member access private Discord."
                        />
                        <UpgradeTooltip
                            children={
                                <a href="https://apps.apple.com/us/app/line-prophet/id1641926747" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={appstoreicon}
                                        alt="appstore"
                                        style={{
                                            height: '35px',
                                            width: '35px',
                                            marginRight: '5px',
                                        }}
                                    />
                                </a>
                            }
                            innerChildStyle={{ display: 'inline-block' }}
                            label="You must be a VIP member access mobile app."
                        />
                        <UpgradeTooltip
                            children={
                                <SocialIcon
                                    style={{ height: '35px', width: '35px' }}
                                    network="google_play"
                                    url="https://play.google.com/store/apps/details?id=com.kwaggoner00.lineProphet&hl=en&gl=US&pli=1"
                                />
                            }
                            innerChildStyle={{ display: 'inline-block' }}
                            label="You must be a VIP member access mobile app."
                        />
                    </SocialIconContainer>
                ) : (
                    <SocialIconContainer>
                        <SocialIcon
                            style={{
                                height: '35px',
                                width: '35px',
                                marginRight: '5px',
                            }}
                            url="https://twitter.com/LineProphetVIP"
                        />
                        <SocialIcon
                            style={{
                                height: '35px',
                                width: '35px',
                                marginRight: '5px',
                            }}
                            url="https://discord.gg/VSwkA2tDYE"
                        />
                        <a href="https://apps.apple.com/us/app/line-prophet/id1641926747" target="_blank" rel="noopener noreferrer">
                            <img
                                src={appstoreicon}
                                alt="appstore"
                                style={{
                                    height: '35px',
                                    width: '35px',
                                    marginRight: '5px',
                                }}
                            />
                        </a>
                        <SocialIcon
                            style={{ height: '35px', width: '35px' }}
                            network="google_play"
                            url="https://play.google.com/store/apps/details?id=com.kwaggoner00.lineProphet&hl=en&gl=US&pli=1"
                        />
                    </SocialIconContainer>
                )}
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav variant="pills" activeKey={activeKey} className="ml-auto align-items-center">
                        {getLinkArray().map((link, i) => {
                            if (link.id === 'profile') {
                                link.label = user ? user.displayName : '';
                            }
                            return (
                                <Nav.Link key={i} disabled={link.disabled} id={link.id} eventKey={link.id} onClick={changeMainPage}>
                                    {link.icon} {`${link.label}`}
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {bannerEnabled ? (
                <div className="banner-wrapper">
                    <div className="text-wrapper">
                        <span
                            style={{
                                color: WHITE,
                                fontWeight: '600',
                                fontSize: '1.25rem',
                            }}
                        >
                            Upgrade to VIP:{' '}
                        </span>
                        <span
                            style={{
                                color: WHITE,
                                fontWeight: '400',
                                fontSize: '.75rem',
                            }}
                        >
                            (Get access to expert picks and algo predictions){' '}
                        </span>
                    </div>
                    <Button variant="light" onClick={() => history.push('/profile?subpage=settings')}>
                        Become a VIP
                    </Button>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
