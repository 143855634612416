import { motion } from 'framer-motion';
import styled from 'styled-components';

export const DrawerContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 75%;
    max-width: 1400px;
    background: white;
    position: fixed;
    top: 0px;
    right: 0;
    border-left: 1px solid #ddd;
    box-shadow: -4px 5px 7px rgba(0, 0, 0, 0.4);
    padding: 20px 15px 100px 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media (max-width: 768px) {
        width: 96% !important;
    }
`;

export const DrawerHeader = styled.div`
    background: #f9f9f9;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin: -20px -15px 15px -15px;
    padding: 15px 15px 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`;

export const DrawerTitle = styled.span`
    font-size: 1.5em;
    font-weight: bold;
    color: #444;
    @media (max-width: 768px) {
        font-size: 1.5em;
    }
`;
