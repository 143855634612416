// Library imports
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
// Component imports
import SportsNav from '../../../components/page_components/SportsNav';
import EditGame from '../../../components/page_components/EditGame';
import Table from '../../../components/page_components/TableV2';
// Controller imports
import { getAllGameData, getAllMongoGameData } from '../../../controllers/GetDataController';
import { saveGame } from '../../../controllers/UpdateDataController';
// Firebase imports
import firebase from 'firebase';
// Styled componentss
import { ProfileContentContainer } from '../styles';

const DataAdmin = () => {
    const [sport, setSport] = useState('mlb');
    const [database, setDatabase] = useState('firebase');
    const [data, setData] = useState([]);
    const [editGame, setEditGame] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                var allGameInfo = [];
                if (database === 'firebase') {
                    allGameInfo = await getAllGameData(sport);
                } else {
                    allGameInfo = await getAllMongoGameData(sport);
                }
                setData(allGameInfo);
            } catch (error) {
                console.log(`Error getting game data\n${error}`);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [sport, database]);

    const handleSave = async game => {
        try {
            setLoading(true);
            await saveGame(game, sport);
            if (database === 'firebase') {
                const newData = data.filter(g => g.gameId !== game.gameId);
                await firebase.database().ref('line-prophet').child(`${sport.toUpperCase()}/game-info/`).set(newData);
                setData(newData);
            }
            if (database === 'mongo') {
                const newData = await getAllMongoGameData(sport);
                setData(newData);
            }
            setEditGame(null);
        } catch (error) {
            console.log(`Error saving game\n${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteGame = async id => {
        try {
            setDeleteLoading(true);
            if (database === 'firebase') {
                const newData = data.filter(game => game.gameId !== id);
                await firebase.database().ref('line-prophet').child(`${sport.toUpperCase()}/game-info/`).set(newData);
                setData(newData);
            }
        } catch (error) {
            console.log(`Error deleting game\n${error}`);
        } finally {
            setDeleteLoading(false);
        }
    };

    const handleEditGame = id => {
        const game = data.find(game => game.gameId === id);
        setEditGame(game);
    };

    const configureColumns = () => {
        const dataList = data.map((game, i) => {
            const awayOdds = game.homeTeam.odds?.fullGame ? game.awayTeam.odds.fullGame.moneyline : 'NA';
            const homeOdds = game.homeTeam.odds?.fullGame ? game.homeTeam.odds.fullGame.moneyline : 'NA';
            return {
                gameId: game.gameId,
                date: moment(game.dateTime).format('MM/DD/YYYY'),
                teams: `${game.awayTeam.abbr} (${awayOdds}) vs. ${game.homeTeam.abbr} (${homeOdds})`,
            };
        });
        return dataList;
    };

    if (editGame) {
        return (
            <ProfileContentContainer>
                <EditGame game={editGame} handleSave={handleSave} handleBack={() => setEditGame(null)} />
            </ProfileContentContainer>
        );
    }

    return (
        <ProfileContentContainer>
            <SportsNav click={setSport} all={false} activeItem={sport} />
            <Table
                loading={loading || deleteLoading}
                tabs={{
                    keys: ['firebase', 'mongo'],
                    activeKey: database,
                    handleOnNavClick: setDatabase,
                }}
                dataList={configureColumns()}
                handleDeleteRow={handleDeleteGame}
                handleEditRow={handleEditGame}
                autoHeight={true}
                bordered
                cellBordered
                columns={[
                    { header: 'Date', dataKey: 'date', flexGrow: 1 },
                    { header: 'Teams', dataKey: 'teams', flexGrow: 3 },
                    { header: 'Edit', dataKey: 'edit', flexGrow: 1 },
                    { header: 'Delete', dataKey: 'delete', flexGrow: 1 },
                ]}
                pagination={{
                    enabled: true,
                    pageSize: 10,
                }}
            />
        </ProfileContentContainer>
    );
};

export default DataAdmin;
