// Library imports
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button, Card, FormSelect } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faSave } from '@fortawesome/free-solid-svg-icons';
import { Calendar } from 'react-calendar';
// Compnent imports
import BarChart from '../../graphs/BarChart';
import GameCard from '../../../app_pages/GameCards/GameCard';
import Faq from 'react-faq-component';
import SportsNav from '../SportsNav';
// Utils imports
import gameData from '../../../utils/mock_data/ExampleGameData';
import trendData from '../../../utils/mock_data/ExampleTrendData';
import { dailyUnitData, calendarData } from '../../../utils/mock_data/ExampleVIPData';
// CSS imports
import '../../../css/HorizSection.css';
// Styled components
import { ButtonContainer, ContentWrapper, Description, Heading, SectionContainer, TopLine, SectionImage } from './styles';
import { MonthlyProfitText } from '../../../app_pages/Sidebar/Drawer/drawers/VIPDrawer/styles';

function Section({
    backgroundColor,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttons,
    img,
    alt,
    imgStart,
    slideDirection,
    contentType = '',
    defaultCardWindow = 'power-rank',
}) {
    // Refs
    const imgRef = useRef();
    // Local state
    const [isVisible, setVisible] = useState(true);
    const [sport, setSport] = useState(defaultCardWindow === 'prediction' ? 'nhl' : 'mlb');
    // Local variables
    const winnerTypes = { 'key-factors': 'moneyline' };

    useEffect(() => {
        if (!contentType && imgRef) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => setVisible(entry.isIntersecting));
            });
            observer.observe(imgRef.current);
            return () => {
                if (imgRef.current) {
                    observer.unobserve(imgRef.current);
                }
            };
        }
    }, []);

    const generateContent = () => {
        if (contentType === 'card') {
            return (
                <>
                    <SportsNav all={false} click={setSport} activeItem={sport} demo={true} />
                    <GameCard game={gameData[sport]} sport={sport} demo={true} vipPlay={true} defaultWindow={defaultCardWindow} />
                </>
            );
        }
        if (contentType === 'faq') {
            return (
                <Faq
                    data={{
                        rows: [
                            {
                                title: 'What kind of data does the platform provide?',
                                content: `Our comprehensive insights provide a 360 degree view of each game on the board.  Our game cards provide key factor analysis, line movement data, situational data, top headlines, AI predictions, and so much more.  On top of that you will also have access to our unique trend calculator that can be applied to the daily board.  All the information you need to quickly and easily analyze each game is provided on both our web and mobile apps.`,
                            },
                            {
                                title: 'Is this just a capping service?',
                                content: `While every package includes hand selected picks from our experts, access to the platform provides so much more.  Dive into each game with our matchup insights, unique trends, and community discussions.  You will also be invited to join our private Discord where our experts and community are there to answer all of your questions.`,
                            },
                            {
                                title: 'How do you track VIP results?',
                                content: `Once you log in you will have access to our one of a kind VIP insights panel.  This includes a look into our daily, weekly, monthly, and all time play results.  Our custom filters allow you to take an in-depth look at how our system performs across many different parameters.  Filter by sport, unit size, time of year, and so much more.`,
                            },
                            {
                                title: 'What comes with an access package?',
                                content:
                                    'With an Access Package you will get all of the benefits the Line Prophet platform has to offer.  With your account you will have access to both our web and mobile apps, private Discord, and VIP Twitter community.  Never miss a play ',
                            },
                            {
                                title: 'How are picks delivered?',
                                content:
                                    'Expert picks can be found via our web and mobile apps with an access package.  All plays are automatically tweeted out to our VIP Twitter page via our bots right as they are entered by one of our experts.  With a pick package plays will be delivered to your email each day.',
                            },
                        ],
                    }}
                    styles={{
                        bgColor: 'transparent',
                        titleTextColor: '#000',
                        rowTitleColor: '#000',
                        padding: '60px 0px',
                        rowContentColor: '#666',
                    }}
                />
            );
        }
        if (contentType === 'trend') {
            return (
                <Card style={{ marginTop: '15px', height: '100%' }}>
                    <Card.Body>
                        <Card.Title>{trendData.title}</Card.Title>
                        <Button
                            size={'sm'}
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '10px',
                                fontSize: '18px',
                                color: '#a62b23',
                            }}
                            color={'green'}
                            variant={'link'}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                        <Card.Subtitle style={{ fontWeight: 400, lineHeight: '22px' }} className="mb-2 text-muted">
                            {trendData.description}
                        </Card.Subtitle>
                        <FormSelect required style={{ maxWidth: '200px' }} value={'Moneyline'} disabled>
                            <option value={'Moneyline'}>Moneyline</option>
                            <option value={'Spread'}>Spread</option>
                        </FormSelect>
                        <BarChart
                            xAxisLabel=""
                            yAxisLabel="Win %"
                            barWidth={sport === 'mlb' ? 10 : 20}
                            data={trendData.data
                                .filter(factor => {
                                    if (factor.type === 'pitcher') return false;
                                    return true;
                                })
                                .map(factor => {
                                    if (factor[winnerTypes[trendData.id]].entries !== 0) {
                                        return {
                                            x: factor.label,
                                            y: (factor[winnerTypes[trendData.id]].wins / factor[winnerTypes[trendData.id]].entries) * 100,
                                        };
                                    }
                                    return { x: factor.label, y: 0 };
                                })}
                            symbol="%"
                        />
                    </Card.Body>
                </Card>
            );
        }
        if (contentType === 'vip-stats') {
            return (
                <div
                    style={{
                        padding: '1.25rem',
                        background: '#fff',
                        marginTop: '15px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    }}
                >
                    <MonthlyProfitText>August, 2024</MonthlyProfitText>
                    <Calendar
                        view="month"
                        showNavigation={false}
                        onClickDay={() => {}}
                        onChange={() => {}}
                        formatDay={(locale, date) => {
                            const dateObj = new Date(date);
                            const units = dailyUnitData[dateObj.toDateString()] ? dailyUnitData[dateObj.toDateString()] : 0;
                            return (
                                <div
                                    className={units === 0 ? 'alert-light' : units > 0 ? 'alert-success' : 'alert-danger'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '10px 0px',
                                    }}
                                >
                                    <span className="day-number">{dateObj.getDate()}</span>
                                    <span className="unit-total">{`${units > 0 ? `+${units.toFixed(1)}` : units.toFixed(1)}u`}</span>
                                </div>
                            );
                        }}
                    />
                </div>
            );
        }
        if (contentType === 'vip-stats-yearly') {
            return (
                <div
                    style={{
                        padding: '1.25rem',
                        background: '#fff',
                        marginTop: '15px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    }}
                >
                    <MonthlyProfitText>
                        <span>245.23u (2023)</span>
                        <FontAwesomeIcon
                            style={{
                                marginLeft: '5px',
                                marginTop: '-4px',
                                color: 'green',
                            }}
                            icon={faArrowUp}
                        />
                    </MonthlyProfitText>
                    <BarChart xAxisLabel="Month" yAxisLabel="Units" data={calendarData} symbol="u" fixedAmount={1} />
                </div>
            );
        }
        return (
            <SectionImage
                ref={imgRef}
                src={img}
                alt={alt}
                isVisible={isVisible}
                className={`${slideDirection} ${isVisible ? 'is-visible' : ''}`}
                slideDirection={slideDirection}
            />
        );
    };

    return (
        <SectionContainer variant={backgroundColor}>
            <Container>
                <Row
                    style={{
                        display: 'flex',
                        flexDirection: imgStart === 'start' ? 'row-reverse' : 'row',
                        alignItems: 'center',
                    }}
                >
                    <Col style={{ padding: '0px 25px' }} sm={12} md={12} lg={contentType ? 6 : 7}>
                        <ContentWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading
                                main={typeof headline === 'string' && headline.includes('Data, algorithms, & experts focused on winning') ? 'true' : 'false'}
                                variant={lightText ? 'light' : 'dark'}
                            >
                                {headline}
                            </Heading>
                            <Description variant={lightTextDesc ? 'light' : 'dark'}>{description}</Description>
                            <ButtonContainer>{buttons}</ButtonContainer>
                        </ContentWrapper>
                    </Col>
                    <Col
                        style={{
                            padding: '0px 25px',
                            marginTop: contentType === 'free-play' ? '1rem' : '0px',
                        }}
                        sm={12}
                        md={12}
                        lg={contentType ? 6 : 5}
                    >
                        {generateContent()}
                    </Col>
                </Row>
            </Container>
        </SectionContainer>
    );
}

export default Section;
