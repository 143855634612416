// Library imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, FormSelect } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
// Component imports
import BarChart from '../../components/graphs/BarChart';
import PieChart from '../../components/graphs/PieChart';
import TrendSearchFilter from '../../components/page_components/SearchFilters/TrendSearchFilter';
import { LoadingSpinner } from '../../components/page_components/LoadingSpinner';
import DoubleBarChart from '../../components/graphs/DoubleBarChart';
import PrimaryButton from '../../components/page_components/Buttons/PrimaryButton';
// Redux Actions
import { setFilters } from '../../store/slices/appSlice';
// Controller imports
import { getTrends } from '../../controllers/TrendsController';
// Styled components
import { ButtonContainer, TrendDescription, TrendTitle } from './styles';

const dayMap = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
};

const AllTrends = ({ setSubpage }) => {
    // Redux
    const dispatch = useDispatch();
    const { sport, teams } = useSelector(state => state.app);
    const teamsById = teams.map(team => team.teamId);
    // Local state
    const [data, setData] = useState([]);
    const [winnerTypes, setWinnerTypes] = useState({});
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [timeframe, setTimeframe] = useState('current_season');
    const [activeTeam, setActiveTeam] = useState(teamsById[0]);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const trendsRes = await getTrends(sport, timeframe);
                setData(trendsRes.filter(t => t.sport === sport || t.sport === 'all'));
                let types = {};
                trendsRes.forEach(trend => (types[trend.id] = trend.winnerTypes[0]));
                setWinnerTypes(types);
            } catch (error) {
                console.log(`Error getting trend data\n${error}`);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [timeframe, sport]);

    const renderTrendGraph = trend => {
        if (trend.type === 'pie') {
            return (
                <div style={{ height: '350px' }}>
                    <PieChart
                        showTitle={false}
                        title={trend.title}
                        data={[
                            {
                                x: `Wins: ${Math.trunc((trend.data.wins / trend.data.entries) * 100)}%`,
                                y: (trend.data.wins / trend.data.entries) * 100,
                            },
                            {
                                x: `Losses: ${Math.trunc((trend.data.losses / trend.data.entries) * 100)}%`,
                                y: (trend.data.losses / trend.data.entries) * 100,
                            },
                        ]}
                    />
                </div>
            );
        }
        if (trend.id === 'key-factors') {
            return (
                <BarChart
                    xAxisLabel="factor"
                    yAxisLabel="Win %"
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={trend.data
                        .filter(factor => {
                            if (factor.type === 'pitcher') return false;
                            return true;
                        })
                        .map(factor => {
                            if (factor[winnerTypes[trend.id]].entries !== 0) {
                                return {
                                    x: factor.label,
                                    y: (factor[winnerTypes[trend.id]].wins / factor[winnerTypes[trend.id]].entries) * 100,
                                };
                            }
                            return {
                                x: factor.label,
                                y: 0,
                            };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'key-factors-starting-pitcher') {
            return (
                <BarChart
                    xAxisLabel="factor"
                    yAxisLabel="Win %"
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={trend.data
                        .filter(factor => {
                            if (factor.type === 'pitcher') return true;
                            return false;
                        })
                        .map(factor => {
                            if (factor['moneyline'].entries !== 0) {
                                return {
                                    x: factor.label,
                                    y: (factor['moneyline'].wins / factor['moneyline'].entries) * 100,
                                };
                            }
                            return {
                                x: factor.label,
                                y: 0,
                            };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'days-of-week') {
            return (
                <DoubleBarChart
                    xAxisLabel="day"
                    yAxisLabel="Win %"
                    keys={[
                        { label: 'Favorite Win %', dataValue: 'favWinPercent' },
                        { label: 'Dog Win %', dataValue: 'dogWinPercent' },
                    ]}
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={Object.keys(trend.data[winnerTypes[trend.id]])
                        .sort((a, b) => {
                            return dayMap[a] - dayMap[b];
                        })
                        .map(day => {
                            return {
                                x: day,
                                ...trend.data[winnerTypes[trend.id]][day],
                            };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'team-days-of-week') {
            const teamData = trend.data[activeTeam];
            if (!teamData?.[winnerTypes[trend.id]]) return <div>No data available for this team</div>;
            return (
                <DoubleBarChart
                    xAxisLabel="day"
                    yAxisLabel="Win %"
                    keys={[
                        { label: 'Wins', dataValue: 'wins' },
                        { label: 'Losses', dataValue: 'losses' },
                    ]}
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={Object.keys(teamData[winnerTypes[trend.id]])
                        .sort((a, b) => {
                            return dayMap[a] - dayMap[b];
                        })
                        .map(day => {
                            return {
                                x: day,
                                wins: teamData[winnerTypes[trend.id]][day].wins / teamData[winnerTypes[trend.id]][day].entries,
                                losses: teamData[winnerTypes[trend.id]][day].losses / teamData[winnerTypes[trend.id]][day].entries,
                            };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'ratings') {
            return (
                <BarChart
                    xAxisLabel="Rating"
                    yAxisLabel="Win %"
                    data={Object.keys(trend.data[winnerTypes[trend.id]]).map(index => {
                        const ratingData = trend.data[winnerTypes[trend.id]][index];
                        return {
                            x: `${ratingData.range[0]}-${ratingData.range[1]}`,
                            y: ratingData.entries === 0 ? 0 : (ratingData.wins / ratingData.entries) * 100,
                        };
                    })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'power-diff') {
            return (
                <BarChart
                    xAxisLabel={'Difference'}
                    yAxisLabel="Win %"
                    data={Object.keys(trend.data[winnerTypes[trend.id]]).map((data, i) => {
                        return {
                            x: `${trend.data[winnerTypes[trend.id]][data].range[0]} - ${
                                i === trend.data[winnerTypes[trend.id]].length - 1 ? '+' : trend.data[winnerTypes[trend.id]][data].range[1].toFixed(0)
                            }`,
                            y:
                                trend.data[winnerTypes[trend.id]][data].entries === 0
                                    ? 0
                                    : (trend.data[winnerTypes[trend.id]][data].wins / trend.data[winnerTypes[trend.id]][data].entries) * 100,
                        };
                    })}
                    symbol="%"
                />
            );
        }
    };

    return (
        <div>
            <TrendSearchFilter value={value} setValue={setValue} timeframe={timeframe} setTimeframe={setTimeframe} />
            <div className="grid-content-wrapper">
                {loading ? (
                    <LoadingSpinner loading={loading} />
                ) : (
                    data
                        .filter(trend => {
                            const lowercaseValue = String(value).toLowerCase();
                            if (!value) {
                                return true;
                            }
                            if (String(trend.title).toLowerCase().includes(lowercaseValue)) {
                                return true;
                            }
                            return false;
                        })
                        .map((trend, i) => {
                            // For key factors trend.data is an array but arrays still have keys, all other trends have data as an object
                            if (Object.keys(trend.data).length === 0) return <React.Fragment key={i}></React.Fragment>;
                            // Only do this if trend entires exists (it does not for Days of the week trends)
                            if (trend.data.entries && trend.data.entries === 0) return <React.Fragment key={i}></React.Fragment>;
                            // This is a check only for key factors (all other trends have data as an object)
                            if (typeof trend.data === Array && trend.data.length === 0) return <React.Fragment key={i}></React.Fragment>;
                            return (
                                <Card key={i} className="grid-item-wrapper">
                                    <Card.Body>
                                        <TrendTitle>{trend.title}</TrendTitle>
                                        <TrendDescription>{trend.description}</TrendDescription>
                                        <ButtonContainer>
                                            <FormSelect
                                                style={{ maxWidth: '200px', marginRight: 15 }}
                                                value={winnerTypes[trend.id]}
                                                onChange={e => setWinnerTypes({ [trend.id]: e.target.value })}
                                            >
                                                {trend.winnerTypes.map((option, i) => {
                                                    return (
                                                        <option key={i} value={option}>
                                                            {option}
                                                        </option>
                                                    );
                                                })}
                                            </FormSelect>
                                            {trend.id === 'team-days-of-week' ? (
                                                <FormSelect
                                                    style={{ maxWidth: '200px', marginRight: 15 }}
                                                    value={activeTeam}
                                                    onChange={e => setActiveTeam(Number(e.target.value))}
                                                >
                                                    {teams.map((team, i) => {
                                                        return (
                                                            <option key={i} value={team.teamId}>
                                                                {team.teamName}
                                                            </option>
                                                        );
                                                    })}
                                                </FormSelect>
                                            ) : (
                                                <></>
                                            )}
                                            {trend.filterId ? (
                                                <PrimaryButton
                                                    variant="primary"
                                                    onClick={() => {
                                                        dispatch(setFilters(trend.filterId));
                                                        setSubpage('games');
                                                    }}
                                                >
                                                    <FontAwesomeIcon style={{ fontSize: '13px', marginRight: '5px' }} icon={faFilter} /> Filter
                                                </PrimaryButton>
                                            ) : (
                                                <></>
                                            )}
                                        </ButtonContainer>
                                        {renderTrendGraph(trend)}
                                    </Card.Body>
                                </Card>
                            );
                        })
                )}
            </div>
        </div>
    );
};

export default AllTrends;
