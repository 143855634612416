// Library and Component imports
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from 'react-router-dom';
import App from './app_pages/App';
import Login from './static_pages/Login';
import Home from './static_pages/Home';
import About from './static_pages/About';
import Packages from './static_pages/register_pages/Packages';
import Register from './static_pages/register_pages/Register';
import Profile from './app_pages/Profile';
import * as serviceWorker from './serviceWorker';
// Redux imports
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './store/rootReducer';
// CSS imports
import './css/index.css';
import './css/bootstrap-scope-limit.scss';
// Firebase init
import './Firebase';

export const store = configureStore({
    reducer: rootReducer,
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Route exact path="/" render={props => <Home {...props} />} />
            <Route exact path="/login" component={Login} />
            <Route path="/about" render={props => <About {...props} />} />
            <Route path="/packages" render={props => <Packages {...props} />} />
            <Route path="/register" render={props => <Register {...props} />} />
            <Route path="/sports/:sport" render={props => <App {...props} />} />
            <Route path="/profile" render={props => <Profile {...props} />} />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
