import styled from 'styled-components';

export const ProfileHeader = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 15px;
`;

export const ProfileUserName = styled.span`
    font-weight: bold;
    font-size: 1.65rem;
    margin-bottom: 5px;
    text-transform: capitalize;
`;

export const ProfileEmail = styled.span`
    font-size: 1rem;
    color: #999;
`;

export const ProfileImage = styled.img`
    height: 85px;
    width: 85px;
`;

export const ProfileContentContainer = styled.div`
    flex-grow: 1;
    min-height: 70vh;
`;
