import { getUserToken } from './UserController';

export const getAlgorithmStats = async (sport, timeframe) => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/algorithm/stats?sport=${sport}&timeframe=${timeframe}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};
