// Library imports
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import queryString from 'query-string';
// Component imports
import ManageUserData from './ManageUserData';
import ManagePackage from './ManagePackage';
// Styled components
import { ProfileContentContainer } from '../../styles';

const index = () => {
    let params = queryString.parse(window.location.href.split('?')[1]);
    const [subpage, setSubpage] = useState(params.tab ? params.tab : 'package-data');

    return (
        <ProfileContentContainer>
            <Nav variant="tabs" activeKey={subpage} className="mb-3">
                <Nav.Item>
                    <Nav.Link eventKey={'package-data'} onClick={() => setSubpage('package-data')}>
                        Subscription
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={'user-data'} onClick={() => setSubpage('user-data')}>
                        Profile Settings
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            {subpage === 'package-data' && <ManagePackage />}
            {subpage === 'user-data' && <ManageUserData />}
        </ProfileContentContainer>
    );
};

export default index;
