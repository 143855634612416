// Component imports</div>
import React from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
// Styled components
import { SectionContainer, Heading, Description } from './Section/styles';

const Testimonials = ({ bgColor = 'silver' }) => {
    return (
        <SectionContainer variant={bgColor === 'silver' ? 'silver' : 'white'}>
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <div className="section-title">
                            <Heading variant="dark" className="text-lg-center">
                                Testimonials
                            </Heading>
                            <Description variant="dark" className="text-lg-center">
                                Line Prophet LLC strives to bring every customer a postive experience. But don't take it from us, see what our clients have to say for themselves!
                            </Description>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                    <Col className="mt-4" sm={12} md={6}>
                        <Card className="p-3">
                            <blockquote className="blockquote mb-0 card-body">
                                <p className="test-quote">
                                    ...Just wanted to give you some praise on the work you put in. I found your account on here in April and in my two Vegas trips since, have come
                                    out plus money!
                                </p>
                                <footer className="blockquote-footer">
                                    <small className="text-muted">
                                        LawlessGambler <cite title="Source Title">@LawlessGambler</cite>
                                    </small>
                                </footer>
                            </blockquote>
                        </Card>
                    </Col>
                    <Col className="mt-4" sm={12} md={6}>
                        <Card className="p-3">
                            <blockquote className="blockquote mb-0 card-body">
                                <p className="test-quote">Join the site, hes killing it and he’s got the data everyone wants. It’s a great investment for sports bettors</p>
                                <footer className="blockquote-footer">
                                    <small className="text-muted">
                                        Adam <cite title="Source Title">@Adam_Aguilar95r</cite>
                                    </small>
                                </footer>
                            </blockquote>
                        </Card>
                    </Col>
                    <Col className="mt-4" sm={12} md={6}>
                        <Card className="p-3">
                            <blockquote className="blockquote mb-0 card-body">
                                <p className="test-quote">
                                    Glad I have a lifetime membership. Already referred a handful of friends to the site. Too much info available for you not to buy a subscription
                                </p>
                                <footer className="blockquote-footer">
                                    <small className="text-muted">
                                        Ryan Kennedy <cite title="Source Title">@rkennedy2622</cite>
                                    </small>
                                </footer>
                            </blockquote>
                        </Card>
                    </Col>
                    <Col className="mt-4" sm={12} md={6}>
                        <Card className="p-3">
                            <blockquote className="blockquote mb-0 card-body">
                                <p className="test-quote">VIPs got this [play] last night. If you don’t subscribe, you shouldn’t bet on sports</p>
                                <footer className="blockquote-footer">
                                    <small className="text-muted">
                                        TunaCanCrew <cite title="Source Title">@tunacancrew</cite>
                                    </small>
                                </footer>
                            </blockquote>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </SectionContainer>
    );
};

export default Testimonials;
