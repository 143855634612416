// Library imports
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
// Controller imports
import { keyFactorData } from './KeyFactorController';
// Component imports
import CompareList from '../../../components/card_components/CompareList';
import { LoadingSpinner } from '../../../components/page_components/LoadingSpinner';
// Util imports
import { shortName } from '../../../utils';
// Styled components
import { InnerContent } from './styles';

const KeyFactors = ({ sport, game }) => {
    const [type, setType] = useState('off');
    const [dataFields, setDataFields] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const res = await keyFactorData(sport, game.awayTeam, game.homeTeam, type);
                setDataFields(res);
            } catch (error) {
                console.log(`Error getting key factor objects for: ${sport}\n${error.message}`);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [type]);

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <Row>
                    <Col>
                        <Nav variant="tabs" className="mb-2" activeKey={type}>
                            <Nav.Item>
                                <Nav.Link onClick={e => setType('off')} eventKey="off">
                                    Offense
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={e => setType('def')} eventKey="def">
                                    Defense
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                {loading ? (
                    <LoadingSpinner loading={loading} />
                ) : (
                    <CompareList
                        labels={dataFields[2]}
                        dataSetOneTitle={shortName(game.awayTeam)}
                        dataSetOneTitleColor={game.awayTeam.color}
                        dataSetOne={dataFields[0]}
                        dataSetTwoTitle={shortName(game.homeTeam)}
                        dataSetTwoTitleColor={game.homeTeam.color}
                        dataSetTwo={dataFields[1]}
                    />
                )}
            </Container>
        </InnerContent>
    );
};

export default KeyFactors;
