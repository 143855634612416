// Library imports
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Card, Container, Button, Alert, Form, Col, Row } from 'react-bootstrap';
// Component imports
import { Header } from '../components/header';
import Footer from '../components/footer';
// Controller imports
import { login } from '../controllers/UserController';
// Util imports
import { scrollToTop } from '../utils';
// Firebase imports
import firebase from 'firebase';
// CSS imports
import '../css/Login.css';

const Login = ({ location }) => {
    const [form, setForm] = useState('login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        scrollToTop();
        setMessage('');
    }, [form]);

    return (
        <div style={{ backgroundColor: '#ededed' }} className="page-wrapper">
            <Helmet>
                <title>Login</title>
                <meta name="description" content="Sign up for your own Line Prophet LLC account and access our entire platform." />
            </Helmet>
            <Header currentPage="login" lightMode={true} shadow />
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '75px 10px',
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#fdfdfd',
                        padding: '25px 25px',
                        maxWidth: '600px',
                        width: '100%',
                    }}
                >
                    <Card.Title style={{ fontWeight: 'bold' }}>{form === 'login' ? 'Log In' : 'Password Reset'}</Card.Title>
                    <Card.Body>
                        <Alert
                            variant={message.includes('success') ? 'success' : 'danger'}
                            style={{ textAlign: 'center', fontSize: '14px' }}
                            className={`${!message ? 'hide' : ''}`}
                        >
                            {message}
                        </Alert>
                        <Form
                            onSubmit={async e => {
                                e.preventDefault();
                                try {
                                    const values = queryString.parse(location.search);
                                    if (form === 'login') {
                                        await login(email, password, values.direct);
                                    }
                                    if (form === 'forgot-password') {
                                        await firebase.auth().sendPasswordResetEmail(email);
                                        setMessage('Password reset email set successfully.');
                                    }
                                } catch (error) {
                                    setMessage(error.message ? error.message : error);
                                }
                            }}
                        >
                            <Row>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="email" sm={12}>
                                    <Form.Label>Email Address*</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        value={email}
                                        style={{ textTransform: 'lowercase' }}
                                        autoCapitalize="none"
                                        onKeyDown={e => {
                                            if (e.key === ' ') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                            {form !== 'forgot-password' ? (
                                <Row>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Col} controlId="password" sm={12}>
                                        <Form.Label>Password*</Form.Label>
                                        <Form.Control required value={password} type="password" onChange={e => setPassword(e.target.value)} />
                                    </Form.Group>
                                </Row>
                            ) : (
                                <></>
                            )}
                            <Button variant="primary" type="submit" style={{ width: '100%', marginTop: '15px' }}>
                                {form === 'forgot-password' ? 'Recover Password' : 'Enter'}
                            </Button>
                            {form === 'login' ? (
                                <div className="forgot-wrapper">
                                    <button className="forgot-line" id="forgot-password" onClick={() => setForm('forgot-password')}>
                                        Forgot Password?
                                    </button>
                                </div>
                            ) : (
                                <></>
                            )}
                            {form !== 'login' ? (
                                <div className="forgot-wrapper">
                                    <button onClick={() => setForm('login')}>Return to Login</button>
                                </div>
                            ) : (
                                <></>
                            )}
                        </Form>
                        <div className="forgot-wrapper">
                            <p>©2021 All Rights Reserved. Line Prophet® is a registered trademark of Line Prophet, LLC.</p>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
            <Footer />
        </div>
    );
};

export default Login;
