// Library imports
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

export default function BarChart({ data, xAxisLabel, yAxisLabel, symbol = '', fixedAmount = 0 }) {
    return (
        <div style={{ height: '350px' }}>
            <ResponsiveBar
                data={data.map(barData => {
                    return {
                        [xAxisLabel]: barData.x,
                        winPercent: Number(barData.y).toFixed(fixedAmount),
                    };
                })}
                padding={0.25}
                colors={{ scheme: 'paired' }}
                keys={['winPercent']}
                indexBy={xAxisLabel}
                margin={{ top: 50, right: 25, bottom: 50, left: 45 }}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: ``,
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yAxisLabel,
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                label={d => <tspan y={-10}>{`${d.value}${symbol}`}</tspan>}
                role="application"
            />
        </div>
    );
}
