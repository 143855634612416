// Library imports
import React, { useState, useEffect } from 'react';
import { Button, Alert, Form, Card, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import Table from '../../../../components/page_components/Table';
// Controller imports
import { deleteProfile, updateProfile } from '../../../../controllers/UserController';
// Util imports
import { scrollToTop } from '../../../../utils';

const ManageUserData = () => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [message, setMessage] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [editing, setEditing] = useState({ field: '', value: '' });
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => scrollToTop(), [message]);

    const editField = e =>
        setEditing({
            field: e.currentTarget.id,
            value: user[e.currentTarget.id],
        });

    const saveField = async () => {
        try {
            setSaveLoading(true);
            var user = await updateProfile(editing.field, editing.value, user.email, user.cust?.id);
            if (editing.field === 'email') {
                // appDispatch({ type: 'CHANGE_PAGE', payload: 'login' })
            } else {
                dispatch(setUser(user));
                setMessage('Profile update success');
            }
        } catch (error) {
            setMessage(`Error updating profile data.\n${error}`);
        } finally {
            setEditing({ field: '', value: '' });
            setSaveLoading(false);
        }
    };

    const handleDeleteProfile = async () => {
        try {
            await deleteProfile(user.uid, user.email);
            window.location = '/login';
        } catch (error) {
            console.log(`Error deleting profile\n${error.message}`);
            setMessage('Error deleting profile - please try again.');
        }
    };

    return (
        <div>
            <Alert
                style={{
                    display: message ? 'block' : 'none',
                    marginTop: '15px',
                }}
                variant={message.includes('Error') ? 'danger' : 'success'}
            >
                {message}
            </Alert>
            <Table
                hover={false}
                headers={['Setting', 'Value', 'Actions']}
                rows={[
                    <tr key={2}>
                        <td data-title="Setting">Display Name</td>
                        <td data-title="Value">
                            {editing.field === 'displayName' ? (
                                <Form.Control
                                    onChange={e =>
                                        setEditing({
                                            ...editing,
                                            value: e.target.value,
                                        })
                                    }
                                    type="text"
                                    id={editing.field}
                                    defaultValue={user.displayName}
                                />
                            ) : (
                                user.displayName
                            )}
                        </td>
                        <td data-title="Actions">
                            <Button
                                style={{ margin: '0px' }}
                                variant={editing.field === 'displayName' ? 'outline-success' : 'outline-primary'}
                                id="displayName"
                                onClick={editing.field === 'displayName' ? saveField : editField}
                                disabled={saveLoading}
                            >
                                {editing.field === 'displayName' ? 'Save' : 'Edit'}
                            </Button>
                        </td>
                    </tr>,
                    <tr key={3}>
                        <td data-title="Setting">Twitter</td>
                        <td data-title="Value">
                            {editing.field === 'twitter' ? (
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>@</InputGroup.Text>
                                    <Form.Control
                                        onChange={e =>
                                            setEditing({
                                                ...editing,
                                                value: e.target.value,
                                            })
                                        }
                                        type="text"
                                        id={editing.field}
                                        defaultValue={user.reciept?.twitter}
                                    />
                                </InputGroup>
                            ) : (
                                user.reciept?.twitter
                            )}
                        </td>
                        <td data-title="Actions">
                            <Button
                                style={{ margin: '0px' }}
                                variant={editing.field === 'twitter' ? 'outline-success' : 'outline-primary'}
                                id="twitter"
                                onClick={editing.field === 'twitter' ? saveField : editField}
                                disabled={saveLoading}
                            >
                                {editing.field === 'twitter' ? 'Save' : 'Edit'}
                            </Button>
                        </td>
                    </tr>,
                    <tr key={4}>
                        <td data-title="Setting">Email</td>
                        <td data-title="Value">
                            {editing.field === 'email' ? (
                                <Form.Control
                                    onChange={e =>
                                        setEditing({
                                            ...editing,
                                            value: e.target.value,
                                        })
                                    }
                                    type="text"
                                    id={editing.field}
                                    defaultValue={user.email}
                                />
                            ) : (
                                user.email
                            )}
                        </td>
                        <td data-title="Actions">
                            <Button
                                style={{ margin: '0px' }}
                                variant={editing.field === 'email' ? 'outline-success' : 'outline-primary'}
                                id="email"
                                onClick={editing.field === 'email' ? saveField : editField}
                                disabled={saveLoading}
                            >
                                {editing.field === 'email' ? 'Save' : 'Edit'}
                            </Button>
                        </td>
                    </tr>,
                    <tr key={5}>
                        <td data-title="Setting">Default Units</td>
                        <td data-title="Value">
                            {editing.field === 'units' ? (
                                <Form.Control
                                    id={editing.field}
                                    value={editing.value}
                                    type="number"
                                    style={{ maxWidth: 350 }}
                                    onChange={e =>
                                        setEditing({
                                            ...editing,
                                            value: parseFloat(e.target.value),
                                        })
                                    }
                                    step={0.5}
                                />
                            ) : user.units ? (
                                user.units
                            ) : (
                                1
                            )}
                        </td>
                        <td data-title="Actions">
                            <Button
                                style={{ margin: '0px' }}
                                variant={editing.field === 'units' ? 'outline-success' : 'outline-primary'}
                                id="units"
                                onClick={editing.field === 'units' ? saveField : editField}
                                disabled={saveLoading}
                            >
                                {editing.field === 'units' ? 'Save' : 'Edit'}
                            </Button>
                        </td>
                    </tr>,
                    <tr key={6}>
                        <td data-title="Setting">Delete Profile</td>
                        <td data-title="Value">Are you sure you want to go?</td>
                        <td data-title="Actions">
                            {confirmDelete ? (
                                <>
                                    <Button
                                        variant="outline-primary"
                                        style={{ marginRight: '5px' }}
                                        id="cancel"
                                        onClick={() => setConfirmDelete(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="danger"
                                        id="confirm-delete"
                                        disabled={saveLoading}
                                        onClick={handleDeleteProfile}
                                    >
                                        Confirm Delete
                                    </Button>
                                </>
                            ) : (
                                <Button variant="outline-danger" id="delete" onClick={() => setConfirmDelete(true)}>
                                    Delete Profile
                                </Button>
                            )}
                        </td>
                    </tr>,
                ]}
            />
        </div>
    );
};

export default ManageUserData;
