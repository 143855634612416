// Library imports
import React from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

const Pagination = ({ items, currentPage, setCurrentPage, itemsPerPage, style }) => {
    const totalPages = Math.ceil(items ? items.length / itemsPerPage : 0);

    let startPage, endPage;
    if (totalPages <= 8) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 4) {
            startPage = 1;
            endPage = 8;
        } else if (currentPage + 3 >= totalPages) {
            startPage = totalPages - 7;
            endPage = totalPages;
        } else {
            startPage = currentPage - 3;
            endPage = currentPage + 4;
        }
    }

    return (
        <BootstrapPagination style={{ ...style }}>
            <BootstrapPagination.First onClick={() => setCurrentPage(1)} />
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                const pageNumber = startPage + index;
                return (
                    <BootstrapPagination.Item key={index} onClick={() => setCurrentPage(pageNumber)} active={pageNumber === currentPage}>
                        {pageNumber}
                    </BootstrapPagination.Item>
                );
            })}
            <BootstrapPagination.Last onClick={() => setCurrentPage(totalPages)} />
        </BootstrapPagination>
    );
};

export default Pagination;
