// Library imports
import React from 'react';
// Styled components
import { TabButton, TabContainer } from './styles';
// Util imports
import { capitalize } from '../../../utils';

const TabNav = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <TabContainer>
            {tabs.map((tab, i) => {
                return (
                    <TabButton
                        key={i}
                        size="sm"
                        onClick={() => setActiveTab(tab)}
                        active={String(tab).toLowerCase() === String(activeTab).toLowerCase()}
                        firstItem={i === 0}
                        lastItem={i === tabs.length - 1}
                    >
                        {capitalize(tab)}
                    </TabButton>
                );
            })}
        </TabContainer>
    );
};

export default TabNav;
