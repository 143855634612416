// Library imports
import React, { useState, useEffect } from 'react';
import { Card, Modal, Button, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// Component imports
import PropSearchFilter from '../../components/page_components/SearchFilters/PropSearchFilter';
import Table from '../../components/page_components/TableV2';
import { LabelValueListGroup } from '../App/styles';
// Controller imports
import { getPropData } from '../../controllers/GetDataController';
import { LoadingSpinner } from '../../components/page_components/LoadingSpinner';
import { addPick } from '../../controllers/UserController';
// Styled components
import { PropDataWrapper, GameDetailsHeader, PropTableCard, PickName } from './styles';
// Image imports
import defaultImage from '../../img/logo-icon-gray.png';
// Util imports
import { addPlusSign, createPickId } from '../../utils';
import { PICK_OBJ } from '../../utils/constants';
// Action imports
import { setUser } from '../../store/slices/userSlice';
import { addLoader, removeLoader } from '../../store/slices/appSlice';
import NoData from '../../components/page_components/NoData';

const Props = () => {
    // Redux
    const { sport, teams, loaders, gameData } = useSelector(state => state.app);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [value, setValue] = useState('');
    const [propCategory, setPropCategory] = useState('playerProps');
    const [propType, setPropType] = useState('');
    const [data, setData] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [pickData, setPickData] = useState({
        ...PICK_OBJ,
        units: user.units ? user.units : 1,
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getPropData(sport);
                setPropType(Object.keys(res[propCategory])[0]);
                setData(res);
            } catch (error) {
                console.log(`Error getting prop stats.\n${error}`);
                setData(null); // set back to null on error if switching between sports on this page
            }
        }
        fetchData();
    }, [sport]);

    const imageLoadError = e => {
        e.target.src = defaultImage;
    };

    const handleCloseModal = () => {
        setModalShow(false);
        setPickData({ ...PICK_OBJ, units: user.units ? user.units : 1 });
    };

    const getPlayersInProp = (awayTeamId, homeTeamId) => {
        var propPlayers = [];
        if (!data[propCategory]?.[propType]?.players?.length) {
            return propPlayers;
        }
        propPlayers = data[propCategory][propType].players.filter(player => {
            if (player.teamId !== awayTeamId && player.teamId !== homeTeamId) {
                return false;
            }
            if (!value) {
                return true;
            }
            const teamData = teams.find(team => team.teamId === player.teamId);
            if (player?.name && player.name.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else if (teamData?.displayName && teamData.displayName.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else if (teamData?.abbr && teamData.abbr.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else if (teamData?.fullName && teamData.fullName.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });
        return propPlayers;
    };

    const playerPropGridData = (propPlayers, gameTime, gameId) => {
        const gridData = propPlayers
            .sort((a, b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map(player => {
                const teamData = teams.find(team => team.teamId === player.teamId);
                if (!teamData) return <></>;

                const propData = data[propCategory][propType];
                if (propData.type === 'single-value') {
                    return {
                        player: player.name,
                        'prop-value': `${player.value}\n${-110}`,
                        playerData: player,
                        propData: propData,
                        gameId: gameId,
                        gameTime: gameTime,
                    };
                } else {
                    return {
                        player: player.name,
                        'prop-over': `${player.value}\n${player.overOdds}`,
                        'prop-under': `${player.value}\n${player.underOdds}`,
                        playerData: player,
                        propData: propData,
                        gameId: gameId,
                        gameTime: gameTime,
                    };
                }
            });
        return gridData;
    };

    if (loaders.find(loader => loader === 'team-data')) {
        return (
            <div className="grid-content-wrapper">
                <LoadingSpinner loading={true} />
            </div>
        );
    }

    if (!data) {
        return (
            <PropDataWrapper>
                <NoData />
            </PropDataWrapper>
        );
    }

    return (
        <div>
            <PropSearchFilter
                value={value}
                setValue={setValue}
                propCategory={propCategory}
                setPropCategory={setPropCategory}
                propType={propType}
                setPropType={setPropType}
                data={data}
            />
            <PropDataWrapper>
                {gameData
                    .filter(game => {
                        // We can a no key assigned issue in the map function if we don't filter this out
                        const propPlayers = getPlayersInProp(game.awayTeam.teamId, game.homeTeam.teamId);
                        if (!propPlayers?.length) {
                            return false;
                        }
                        return true;
                    })
                    .map((game, i) => {
                        const propPlayers = getPlayersInProp(game.awayTeam.teamId, game.homeTeam.teamId);
                        const gridData = playerPropGridData(propPlayers, game.dateTime, game.gameId);
                        const propData = data[propCategory][propType];
                        const columns =
                            propData.type === 'single-value'
                                ? [
                                      { header: 'Player', dataKey: 'player', flexGrow: 1 },
                                      { header: 'Value', dataKey: 'prop-value' },
                                  ]
                                : [
                                      { header: 'Player', dataKey: 'player', flexGrow: 1 },
                                      { header: 'Over', dataKey: 'prop-over' },
                                      { header: 'Under', dataKey: 'prop-under' },
                                  ];
                        return (
                            <PropTableCard key={i}>
                                <Card.Body>
                                    <GameDetailsHeader>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                style={{ height: '25px', width: 'auto', marginRight: '5px' }}
                                                src={game.awayTeam.logo}
                                                id={game.awayTeam.teamId}
                                                alt="team logo"
                                                onError={imageLoadError}
                                            />
                                            <span style={{ fontSize: '18px', fontWeight: '500' }}>{game.awayTeam.teamName}</span>
                                        </div>
                                        <span style={{ fontSize: '16px', margin: '0px 8px', fontWeight: '400' }}>@</span>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                style={{ height: '25px', width: 'auto', marginRight: '5px' }}
                                                src={game.homeTeam.logo}
                                                id={game.homeTeam.teamId}
                                                alt="team logo"
                                                onError={imageLoadError}
                                            />
                                            <span style={{ fontSize: '18px', fontWeight: '500' }}>{game.homeTeam.teamName}</span>
                                        </div>
                                    </GameDetailsHeader>
                                    <Table
                                        autoHeight={true}
                                        dataList={gridData}
                                        bordered={false}
                                        columns={columns}
                                        handleSavePick={(propData, playerData, gameId, gameTime, oddType, juice) => {
                                            setModalShow(true);
                                            if (propData.type === 'single-value') {
                                                setPickData({
                                                    ...pickData,
                                                    teamName: `${playerData.name} ${playerData.value} ${propData.name}`,
                                                    pickTeamId: playerData.teamId,
                                                    pickType: 'prop',
                                                    pickJuice: playerData.value,
                                                    pickValue: playerData.value,
                                                    gameId: gameId,
                                                    gameTime: gameTime,
                                                });
                                            } else {
                                                setPickData({
                                                    ...pickData,
                                                    teamName: `${playerData.name} ${oddType === 'over' ? 'o' : 'u'}${playerData.value} ${propData.name}`,
                                                    pickTeamId: playerData.teamId,
                                                    pickType: 'prop',
                                                    pickJuice: juice,
                                                    pickValue: playerData.value,
                                                    gameId: gameId,
                                                    gameTime: gameTime,
                                                });
                                            }
                                        }}
                                    />
                                </Card.Body>
                            </PropTableCard>
                        );
                    })}
            </PropDataWrapper>
            <Modal show={modalShow} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Pick Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PickName>{pickData.teamName}</PickName>
                    <LabelValueListGroup direction="row" variant="flush">
                        <ListGroup.Item>
                            <label>Game Id:</label>
                            <span>{pickData.gameId}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <label>Game time:</label>
                            <span>{moment(pickData.gameTime).format('DD-MM-YYYY')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <label>Value:</label>
                            <span>{pickData.pickValue}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <label>Juice:</label>
                            <span>{addPlusSign(pickData.pickJuice)}</span>
                        </ListGroup.Item>
                    </LabelValueListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loaders.find(loader => loader === 'add-pick')}
                        variant="success"
                        onClick={async () => {
                            try {
                                dispatch(addLoader('add-pick'));
                                const newPicks = await addPick({
                                    ...pickData,
                                    userId: user.uid,
                                    sport: sport,
                                    pickId: createPickId(),
                                });
                                dispatch(setUser({ ...user, picks: newPicks }));
                            } catch (error) {
                                console.log(`Error saving pick\n${error}`);
                            } finally {
                                dispatch(removeLoader('add-pick'));
                            }
                        }}
                    >
                        Save Pick
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Props;
