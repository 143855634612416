import queryBuilderSlice from './slices/queryBuilderSlice';
import appSlice from './slices/appSlice';
import userSlice from './slices/userSlice';
import registerSlice from './slices/registerSlice';

const rootReducer = {
    queryBuilder: queryBuilderSlice,
    app: appSlice,
    user: userSlice,
    register: registerSlice,
};

export default rootReducer;
