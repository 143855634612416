import { getUserToken } from './UserController';

export const createPaymentIntent = async ({ product, customer }) => {
    // Must delete these so customer is a stringified properly for payment intent
    if (customer.reciept) delete customer.reciept;
    if (customer.cust) delete customer.cust;
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/stripe/payment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ product, customer }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const subscription = async dataForSubscription => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/stripe/subscription`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForSubscription),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const updateSubscription = async (product, id, itemId, endDate, cancel = false, renew = false) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/stripe/subscription`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            product,
            id,
            itemId,
            endDate,
            cancel,
            renew,
        }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getCustomer = async email => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/customer?email=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const saveReciept = async reciept => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/reciept`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reciept),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getReciept = async (email, type) => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/reciept?email=${email}&type=${type}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getReciepts = async () => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/reciepts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const updateReciept = async (id, field, value) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/reciept`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, field, value }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};
