import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const PropDataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 50vh;
    padding: 15px 25px;
    flex-wrap: wrap;
`;

export const PropTableCard = styled(Card)`
    width: 48%;
    min-width: 380px !important;
    margin-bottom: 15px;
    margin-right: 15px;
    box-shadow: 5px 5px 5px #ddd;
`;

export const GameDetailsHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
`;

/* Modal styles */
export const PickName = styled.span`
    font-size: 1em;
    font-weight: bold;
    margin-right: 10px;
`;
