// Library imports
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Nav, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// Component imports
import NoData from '../../../../components/page_components/NoData';
// Controller imports
import { getPropData } from '../../../../controllers/GetDataController';
// Util imports
import { addPlusSign, shortName, shortString, formatOddButtonJuice } from '../../../../utils';
// Styled components
import { CardTitle } from '../styles';
import { OddButton, OverUnderButtonGroup, OddLabel } from './styles';

const OddsBoard = ({ game, setView, pickData, setPickData }) => {
    // Redux
    const { sport } = useSelector(state => state.app);
    // Local state
    const [oddFilter, setOddFilter] = useState('fullGame');
    const [loading, setLoading] = useState(String(oddFilter).toLowerCase().includes('prop') ? true : false);
    const [activePropType, setActivePropType] = useState('');
    const [propData, setPropData] = useState({});

    useEffect(() => {
        async function getPlayerProps() {
            try {
                setLoading(true);
                const res = await getPropData(sport);
                if (!res[oddFilter]) {
                    throw new Error(`No prop data found for ${oddFilter}`);
                }
                setActivePropType(Object.keys(res[oddFilter])[0]);
                setPropData(res);
            } catch (error) {
                console.log(`Error getting player props. ${error}`);
            } finally {
                setLoading(false);
            }
        }
        if (String(oddFilter).toLowerCase().includes('prop')) {
            getPlayerProps();
        }
    }, [oddFilter]);

    const renderPlayerPropsList = teamId => {
        if (!propData[oddFilter]?.[activePropType]?.players) {
            return <NoData title="No data" message="There is currently no data for this prop." headerFontSize={14} messageFontSize={12} />;
        }
        const players = propData[oddFilter][activePropType].players.filter(player => player.teamId === teamId);
        if (!players.length) {
            return (
                <NoData
                    title="No player data"
                    message="There is currently no player data for this prop."
                    headerFontSize={14}
                    messageFontSize={12}
                    variant="light"
                />
            );
        }
        const propHtml = players.map((player, j) => {
            return (
                <ListGroup.Item key={j} className="odds-list-item">
                    <div className="odds-desc-wrapper">
                        <span style={{ fontSize: '14px' }}>{shortString(player.name)}</span>
                        <span style={{ fontSize: '12px' }}>{propData[oddFilter][activePropType].name}</span>
                    </div>
                    {propData[oddFilter][activePropType].type === 'over-under' ? (
                        <OverUnderButtonGroup>
                            <OddButton
                                onClick={() => {
                                    setPickData({
                                        ...pickData,
                                        teamName: `${player.name} u${player.value} ${propData[oddFilter][activePropType].name}`,
                                        pickTeamId: player.teamId,
                                        pickType: 'prop',
                                        pickJuice: player.underOdds,
                                        pickValue: player.value,
                                    });
                                    setView('confirm');
                                }}
                            >
                                <span className="value">u{player.value}</span>
                                <span className="juice">{formatOddButtonJuice(player.underOdds, 'prop')}</span>
                            </OddButton>
                            <OddButton
                                onClick={() => {
                                    setPickData({
                                        ...pickData,
                                        teamName: `${player.name} o${player.value} ${propData[oddFilter][activePropType].name}`,
                                        pickTeamId: player.teamId,
                                        pickType: 'prop',
                                        pickJuice: player.overOdds,
                                        pickValue: player.value,
                                    });
                                    setView('confirm');
                                }}
                            >
                                <span className="value">o{player.value}</span>
                                <span className="juice">{formatOddButtonJuice(player.overOdds, 'prop')}</span>
                            </OddButton>
                        </OverUnderButtonGroup>
                    ) : (
                        <OddButton
                            onClick={() => {
                                setPickData({
                                    ...pickData,
                                    teamName: `${player.name} ${addPlusSign(player.value)} ${propData[oddFilter][activePropType].name}`,
                                    pickTeamId: player.teamId,
                                    pickValue: player.value,
                                    pickJuice: player.value,
                                    pickType: 'prop',
                                });
                                setView('confirm');
                            }}
                        >
                            <span className="value">{addPlusSign(player.value)}</span>
                            <span className="juice">{formatOddButtonJuice(-110, 'prop-value')}</span>
                        </OddButton>
                    )}
                </ListGroup.Item>
            );
        });
        return propHtml;
    };

    const renderOdds = () => {
        if (oddFilter === 'fullGame' || oddFilter === 'half' || oddFilter === 'quarter') {
            return [game.awayTeam, game.homeTeam].map((team, i) => {
                const oddTypes = oddFilter === 'fullGame' ? ['moneyline', 'spread'] : ['moneyline', 'spread', 'total'];
                return (
                    <Col xs={6} sm={6} className={`p-0 ${i === 0 ? 'pr-1' : ''}`} key={i}>
                        <Card style={{ height: '100%' }}>
                            <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                            <ListGroup variant="flush">
                                {oddTypes.map((type, j) => {
                                    const oddDesc = oddFilter !== 'fullGame' ? ` (${oddFilter})` : '';
                                    const odds = team.odds?.[oddFilter]?.[type] ? team.odds[oddFilter][type] : 0;
                                    return (
                                        <ListGroup.Item key={j} className="odds-list-item">
                                            <div className="odds-desc-wrapper">
                                                <OddLabel>{type === 'total' ? 'Total' : shortName(team)}</OddLabel>
                                            </div>
                                            <OddButton
                                                onClick={() => {
                                                    setPickData({
                                                        ...pickData,
                                                        // Fullgame totals are not done here - they are done in the totals section
                                                        teamName:
                                                            type === 'total'
                                                                ? `${game.awayTeam.abbr}-${game.homeTeam.abbr}${oddDesc}`
                                                                : `${team.teamName}${oddDesc}`,
                                                        pickTeamId: team.teamId,
                                                        pickValue: odds,
                                                        // Fullgame totals are not done here - they are done in the totals section
                                                        pickType: type === 'total' ? (i === 0 ? `${oddFilter}-under` : `${oddFilter}-over`) : type,
                                                    });
                                                    setView('confirm');
                                                }}
                                            >
                                                <span className="value">{type === 'total' ? (i === 0 ? `u${odds}` : `o${odds}`) : addPlusSign(odds)}</span>
                                                <span className="juice">{formatOddButtonJuice(-110, type)}</span>
                                            </OddButton>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </Card>
                    </Col>
                );
            });
        }
        if (oddFilter === 'total') {
            return (
                <Col className={`p-0 pr-1`}>
                    <Card style={{ width: '50%' }}>
                        <CardTitle>Totals</CardTitle>
                        <ListGroup>
                            <ListGroup.Item className="odds-list-item">
                                <div className="odds-desc-wrapper">
                                    <OddLabel>Total</OddLabel>
                                </div>
                                <OddButton
                                    id="under"
                                    onClick={e => {
                                        setPickData({
                                            ...pickData,
                                            teamName: `${game.awayTeam.abbr}-${game.homeTeam.abbr}`,
                                            pickTeamId: `${game.awayTeam.teamId}-${game.homeTeam.teamId}`,
                                            pickValue: game.total,
                                            pickType: e.currentTarget.id,
                                        });
                                        setView('confirm');
                                    }}
                                >
                                    <span className="value">u{game.total}</span>
                                    <span className="juice">{formatOddButtonJuice(-110, 'total')}</span>
                                </OddButton>
                            </ListGroup.Item>
                            <ListGroup.Item className="odds-list-item">
                                <div className="odds-desc-wrapper">
                                    <OddLabel>Total</OddLabel>
                                </div>
                                <OddButton
                                    id="over"
                                    onClick={e => {
                                        setPickData({
                                            ...pickData,
                                            teamName: `${game.awayTeam.abbr}-${game.homeTeam.abbr}`,
                                            pickTeamId: `${game.awayTeam.teamId}-${game.homeTeam.teamId}`,
                                            pickValue: `${game.total}`,
                                            pickType: e.currentTarget.id,
                                        });
                                        setView('confirm');
                                    }}
                                >
                                    <span className="value">o{game.total}</span>
                                    <span className="juice">{formatOddButtonJuice(-110, 'total')}</span>
                                </OddButton>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            );
        }
        if (oddFilter === 'playerProps') {
            return [game.awayTeam, game.homeTeam].map((team, i) => {
                return (
                    <Col xs={6} sm={6} className={`p-0 ${i === 0 ? 'pr-1' : ''}`} key={i}>
                        <Card style={{ height: '100%' }}>
                            <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                            <ListGroup variant="flush">{renderPlayerPropsList(team.teamId)}</ListGroup>
                        </Card>
                    </Col>
                );
            });
        }

        return <></>;
    };

    return (
        <div>
            <Nav variant="tabs" className="mb-2" activeKey={oddFilter}>
                <Nav.Item>
                    <Nav.Link eventKey={'fullGame'} onClick={() => setOddFilter('fullGame')}>
                        Game
                    </Nav.Link>
                </Nav.Item>
                {game.awayTeam.odds?.quarter && game.homeTeam.odds?.quarter ? (
                    <Nav.Item>
                        <Nav.Link eventKey={'quarter'} onClick={() => setOddFilter('quarter')}>
                            1Q
                        </Nav.Link>
                    </Nav.Item>
                ) : (
                    <></>
                )}
                {game.awayTeam.odds?.half && game.homeTeam.odds?.half ? (
                    <Nav.Item>
                        <Nav.Link eventKey={'half'} onClick={() => setOddFilter('half')}>
                            Half
                        </Nav.Link>
                    </Nav.Item>
                ) : (
                    <></>
                )}
                <Nav.Item>
                    <Nav.Link eventKey={'total'} onClick={() => setOddFilter('total')}>
                        Totals
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={'playerProps'} onClick={() => setOddFilter('playerProps')}>
                        Player Props
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            {String(oddFilter).toLowerCase().includes('prop') && propData[oddFilter] ? (
                <Nav className="d-flex mb-2" activeKey={activePropType} variant="pills">
                    {Object.keys(propData[oddFilter]).map((key, i) => {
                        const prop = propData[oddFilter][key];
                        return (
                            <Nav.Item key={i}>
                                <Nav.Link onClick={() => setActivePropType(prop.id)} eventKey={prop.id}>
                                    {prop.name}
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Nav>
            ) : (
                <></>
            )}
            <Row className="m-0" style={{ alignItems: 'flex-start' }}>
                {!loading ? renderOdds() : <></>}
            </Row>
        </div>
    );
};

export default OddsBoard;
