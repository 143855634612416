// Library imports
import React, { useEffect, useState } from 'react';
import { Form, FormSelect, Nav, Button, Alert, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faList } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
// Component imports
import PieChart from '../../components/graphs/PieChart';
import { LoadingSpinner } from '../../components/page_components/LoadingSpinner';
import TrendList from './TrendList';
import Pagination from '../../components/pagination';
import DoubleBarChart from '../../components/graphs/DoubleBarChart';
// Controller imports
import { getTrendById } from '../../controllers/TrendsController';
// Styled components
import {
    FilterWrapper,
    GraphWrapper,
    GraphHeaderWrapper,
    TrendDescription,
    TrendDetailsWrapper,
    TrendFilter,
    TrendFilterDescription,
    TrendFilterTitle,
    TrendGraphSubtitle,
    TrendGraphTitle,
    TrendGraphTitleWrapper,
    TrendGraphWrapper,
    TrendTitle,
    TrendsWrapper,
} from './styles';
import { SearchButtonContainer } from '../SearchBuilder/styles';
import { LabelValueListGroup } from '../App/styles';
// Util imports
import { formatOdd, sortGames } from '../../utils';
import { GREEN, RED } from '../../utils/styleConstants';
import { EDITABLE_TRENDS } from '../../utils/constants';

const dayMap = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
};

const SingleTrend = () => {
    // Redux
    const { sport, teams } = useSelector(state => state.app);
    const teamsById = teams.map(team => team.teamId);
    // Default values
    let params = queryString.parse(window.location.href.split('?')[1]);
    const defaultTrend = params.trend ? EDITABLE_TRENDS.find(trend => trend.id === params.trend) : EDITABLE_TRENDS[0];
    const defaultTeam = params.team ? teamsById.find(team => team === Number(params.team)) : teamsById[0];
    // Local state
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [trend, setTrend] = useState(defaultTrend);
    const [winnerType, setWinnerType] = useState('');
    const [tab, setTab] = useState('trend');
    const [amount, setAmount] = useState(0);
    const [contentView, setContentView] = useState('graph');
    const [activeTeam, setActiveTeam] = useState(defaultTeam);
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * 4;
    const endIndex = startIndex + 4;

    useEffect(() => {
        async function fetchData() {
            await queryTrend('default', true);
        }
        fetchData();
    }, [trend?.id, sport]);

    const getTeamData = (value, property) => {
        const teamData = teams.find(team => team[property] === value || team.city === value);
        if (!teamData) {
            console.log(`No team data for for ${value} while looking for ${property}`);
            return {};
        } else {
            return teamData;
        }
    };

    const queryTrend = async (amount = 'default', setDefaultAmount = true) => {
        try {
            if (amount !== 'default') {
                amount = parseFloat(amount);
                if (trend.filter.validation === 'positive' && amount < 100) {
                    throw new Error('Amount must be a positive number greater than 100');
                }
                if (trend.filter.validation === 'negative' && amount > -100) {
                    throw new Error('Amount must be a positive number less than -100');
                }
            }
            setLoading(true);
            const res = await getTrendById(sport, trend.id, amount);
            const defaultAmount = res.filter ? res.filter.value : 0;
            setWinnerType(res.winnerTypes[0]);
            // For the first time, set the default amount from the query response
            if (setDefaultAmount) {
                setAmount(defaultAmount);
            }
            setTrend(res);
            setError('');
        } catch (error) {
            console.log(`Error getting single trend data.\n${error.message}`);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const trendGraphTitle = () => {
        if (trend.id === 'team-days-of-week') {
            const activeTeamData = teams.find(team => team.teamId === activeTeam);
            return activeTeamData?.teamName ? `${activeTeamData.teamName} (${winnerType ? winnerType : 'loading...'})` : '';
        }
        return trend?.title ? trend?.title : '';
    };

    const renderTrendGraph = () => {
        if (!trend?.data) return;
        if (trend.type === 'pie') {
            return (
                <div style={{ height: '350px' }}>
                    <PieChart
                        data={[
                            {
                                x: `Wins: ${Math.trunc((trend.data.wins / trend.data.entries) * 100)}%`,
                                y: (trend.data.wins / trend.data.entries) * 100,
                            },
                            {
                                x: `Losses: ${Math.trunc((trend.data.losses / trend.data.entries) * 100)}%`,
                                y: (trend.data.losses / trend.data.entries) * 100,
                            },
                        ]}
                    />
                </div>
            );
        }
        if (trend.type === 'bar-group') {
            const teamData = trend.data[activeTeam];
            if (!teamData) return; // This happens when you switch sports (while viewing this trend)
            return (
                <DoubleBarChart
                    xAxisLabel="day"
                    yAxisLabel="Win %"
                    keys={[
                        { label: 'Wins', dataValue: 'wins' },
                        { label: 'Losses', dataValue: 'losses' },
                    ]}
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={Object.keys(teamData[winnerType])
                        .sort((a, b) => {
                            return dayMap[a] - dayMap[b];
                        })
                        .map(day => {
                            return {
                                x: day,
                                wins: teamData[winnerType][day].wins / teamData[winnerType][day].entries,
                                losses: teamData[winnerType][day].losses / teamData[winnerType][day].entries,
                            };
                        })}
                    symbol="%"
                />
            );
        }
        return;
    };

    const renderGameList = () => {
        const listHtml = sortGames(trend.games)
            .slice(startIndex, endIndex)
            .map(game => {
                const awayML = game.awayTeam.odds?.fullGame?.moneyline ? game.awayTeam.odds.fullGame.moneyline : 0;
                const homeML = game.homeTeam.odds?.fullGame?.moneyline ? game.homeTeam.odds.fullGame.moneyline : 0;
                return (
                    <ListGroup.Item
                        style={{
                            borderLeft: `5px solid ${game.trendResult === 'win' ? GREEN : RED}`,
                        }}
                    >
                        <label>
                            <span style={{ display: 'block', fontWeight: '400', fontSize: 12 }}>{game.gameId}</span>
                            <span>
                                {game.awayTeam.teamName} ({formatOdd(awayML, 'moneyline')})
                            </span>
                            <span style={{ margin: '0px 5px' }}>@</span>
                            <span>
                                {game.homeTeam.teamName} ({formatOdd(homeML, 'moneyline')})
                            </span>
                            <span style={{ display: 'block', fontWeight: '400' }}>{moment(new Date(game.dateTime)).format('MM/DD hh:mm A')}</span>
                        </label>
                        <span>
                            Score: {game.awayTeam.score}-{game.homeTeam.score}
                        </span>
                        <span style={{ margin: '0px 15px' }}>Winner: {getTeamData(game.winner, 'teamId').abbr}</span>
                        <span>Spread Winner: {getTeamData(game.spreadWinner, 'teamId').abbr}</span>
                    </ListGroup.Item>
                );
            });
        return (
            <div>
                <Pagination
                    items={trend?.games ? trend.games : []}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    style={{ marginTop: '15px' }}
                    itemsPerPage={4}
                />
                <LabelValueListGroup style={{ marginTop: '15px' }} direction="row" variant="flush">
                    {listHtml}
                </LabelValueListGroup>
            </div>
        );
    };

    return (
        <TrendsWrapper>
            <FilterWrapper>
                <Nav
                    variant="tabs"
                    className="mb-2"
                    activeKey={tab}
                    style={{
                        marginLeft: '-25px',
                        marginRight: '-25px',
                        paddingLeft: '20px',
                        paddingRight: '25px',
                        marginTop: '5px',
                    }}
                    id="change-trend-tabs"
                >
                    <Nav.Item>
                        <Nav.Link onClick={() => setTab('trend')} eventKey="trend">
                            Trend
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => setTab('change-trend')} eventKey="change-trend">
                            Change Trend
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {tab === 'change-trend' ? (
                    <TrendList setActiveTrend={setTrend} activeTrend={trend} />
                ) : (
                    <div>
                        <TrendDetailsWrapper>
                            <TrendTitle>{trend?.title ? trend?.title : ''}</TrendTitle>
                            <TrendDescription>{trend?.description ? trend?.description : ''}</TrendDescription>
                        </TrendDetailsWrapper>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {trend?.winnerTypes ? (
                            <TrendFilter>
                                <TrendFilterTitle>Winner Type</TrendFilterTitle>
                                <FormSelect required style={{ maxWidth: '200px' }} value={winnerType} onChange={e => setWinnerType(e.target.value)}>
                                    {trend.winnerTypes.map((option, i) => {
                                        return (
                                            <option key={i} value={option}>
                                                {option}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </TrendFilter>
                        ) : (
                            <></>
                        )}
                        {trend?.filter ? (
                            <TrendFilter>
                                <TrendFilterTitle>{trend.filter.label}</TrendFilterTitle>
                                {trend.filter.input === 'number' ? (
                                    <Form.Control
                                        value={amount}
                                        type="number"
                                        style={{ maxWidth: '200px' }}
                                        onChange={e => setAmount(e.target.value)}
                                        step={1}
                                        {...(trend.filter.validation === 'positive' ? { min: 100 } : {})}
                                        {...(trend.filter.validation === 'negative' ? { max: -100 } : {})}
                                    />
                                ) : (
                                    <></>
                                )}
                                <TrendFilterDescription>{trend.filter.description}</TrendFilterDescription>
                            </TrendFilter>
                        ) : (
                            <></>
                        )}
                        {trend.id === 'team-days-of-week' ? (
                            <TrendFilter>
                                <TrendFilterTitle>Select Team</TrendFilterTitle>
                                <FormSelect
                                    style={{ maxWidth: '200px', marginRight: 15 }}
                                    value={activeTeam}
                                    onChange={e => setActiveTeam(Number(e.target.value))}
                                >
                                    {teams.map((team, i) => {
                                        return (
                                            <option key={i} value={team.teamId}>
                                                {team.teamName}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </TrendFilter>
                        ) : (
                            <></>
                        )}
                        <SearchButtonContainer>
                            {trend.id !== 'team-days-of-week' ? (
                                <Button
                                    onClick={async () => {
                                        if (isNaN(amount) || !amount || amount === '-' || amount === '.') {
                                            setError('Amount must be a number');
                                            return;
                                        }
                                        await queryTrend(amount, false);
                                    }}
                                    disabled={loading}
                                >
                                    Analyze Trend
                                </Button>
                            ) : (
                                <></>
                            )}
                        </SearchButtonContainer>
                    </div>
                )}
            </FilterWrapper>
            <TrendGraphWrapper>
                <GraphWrapper id="trend-graph-container">
                    <GraphHeaderWrapper>
                        <TrendGraphTitleWrapper>
                            <TrendGraphTitle>{trendGraphTitle()}</TrendGraphTitle>
                            {trend?.data && trend.filter && amount !== 0 ? (
                                <TrendGraphSubtitle>
                                    {trend.filter.label}: {amount}
                                </TrendGraphSubtitle>
                            ) : (
                                <></>
                            )}
                        </TrendGraphTitleWrapper>
                        {trend?.games?.length ? (
                            <Button
                                variant="light"
                                onClick={() => setContentView(contentView === 'graph' ? 'game-list' : 'graph')}
                                id="toggle-content-view-button"
                            >
                                {contentView === 'graph' ? <FontAwesomeIcon icon={faList} /> : <FontAwesomeIcon icon={faChartArea} />}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </GraphHeaderWrapper>
                    {loading ? <LoadingSpinner loading={loading} /> : contentView === 'graph' ? renderTrendGraph() : renderGameList()}
                </GraphWrapper>
            </TrendGraphWrapper>
        </TrendsWrapper>
    );
};

export default SingleTrend;
