// Library imports
import React, { useState } from 'react';
import { Alert, Button, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const Access = () => {
    // Redux
    const { data } = useSelector(state => state.register);

    return (
        <div className="access-wrapper">
            <Alert variant="success">
                <Alert.Heading className="d-flex align-items-center" style={{ fontSize: 24, fonWeight: 'bold' }}>
                    <FontAwesomeIcon className="mr-2" icon={faCheckCircle} /> Congratulations!
                </Alert.Heading>
                <hr />
                <p>
                    Your purchase of the <b>{data.plan.name}</b> was successful!
                </p>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Button variant="light" onClick={() => (window.location.href = '/login')}>
                        Log In
                    </Button>
                </div>
            </Alert>
            <Card>
                <Card.Header>What's Next?</Card.Header>
                <Card.Body>
                    <Row className="mb-4">
                        <Col sm={3} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Email:
                        </Col>
                        <Col sm={9} style={{ fontSize: 18 }}>
                            {data.email}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={3} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Package:
                        </Col>
                        <Col sm={9} style={{ fontSize: 18 }}>
                            {data.plan.name}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={3} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Next Steps:
                        </Col>
                        <Col sm={9} style={{ fontSize: 18 }}>
                            {data.plan.id.indexOf('access') > -1
                                ? 'Check your email for more information.'
                                : 'Check your email in the next few hours for your play(s).  Play(s) will be delivered daily.'}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={3} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Assistance:
                        </Col>
                        <Col sm={9} style={{ fontSize: 18 }}>
                            Please reach out to us via email, Twitter, or Instagram for support.
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Access;
