// Library imports
import React from 'react';
import { Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
// Component imports
import Dropdown from '../DropDown';
// Styled components
import { SearchFilterContainer, SearchFilterRow } from './styles';

const TrendSearchFilter = ({ value, setValue, timeframe, setTimeframe }) => {
    return (
        <SearchFilterContainer>
            <SearchFilterRow>
                <Col md={12} lg={2} className="order-2-col pl-0 pr-0 d-flex align-items-stretch">
                    <Dropdown
                        icon={<FontAwesomeIcon icon={faCalendar} />}
                        toggleStyle={{
                            borderRadius: 0,
                            flex: 0,
                            minWidth: 50,
                            background: '#fff',
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                            borderRight: '1px solid #ccc',
                        }}
                        value={timeframe}
                        setValue={selectedKey => setTimeframe(selectedKey)}
                        menuItems={['current_season', 'all data']}
                        caption="timeframe"
                    />
                </Col>
                <Col md={12} lg={10} className="search-col pl-0 pr-0 d-flex align-items-stretch">
                    <Form
                        style={{
                            width: '100%',
                            display: 'flex',
                            background: '#fff',
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                        }}
                        onSubmit={e => {
                            e.preventDefault();
                            document.querySelector('#card-search').blur();
                            return false;
                        }}
                    >
                        <InputGroup>
                            <Form.Control
                                type="search"
                                placeholder="Search by trend title..."
                                aria-label="Search"
                                id="card-search"
                                onChange={e => setValue(e.target.value)}
                                value={value}
                                style={{
                                    borderRadius: 0,
                                    borderWidth: 0,
                                    height: 'auto',
                                    borderTopRightRadius: 999,
                                    borderBottomRightRadius: 999,
                                }}
                            />
                            <Button
                                variant="link"
                                size="sm"
                                style={{
                                    alignSelf: 'center',
                                    marginRight: '25px',
                                    color: 'red',
                                }}
                                onClick={() => setValue('')}
                            >
                                Clear
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

export default TrendSearchFilter;
