import { getUserToken } from './UserController';

export const getInvestor = async email => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/investor?email=${email}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getPortfolio = async () => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/portfolio`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const makeDeposit = async amount => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/investor/deposit`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};
