// Library imports
import React from 'react';
import { FormSelect } from 'react-bootstrap';
import { Cell } from 'rsuite-table';

export const PlayCell = ({ rowData, dataKey, ...props }) => {
    const colText = rowData[dataKey];
    const colArr = colText.split('\n');
    return (
        <Cell {...props}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: '12px', fontWeight: '400', color: '#666' }}>{colArr[0]}</span>
                <span style={{ fontWeight: 'bold', color: '#444' }}>{colArr[1]}</span>
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        color: '#666',
                    }}
                >
                    {colArr[2]}
                </span>
            </div>
        </Cell>
    );
};

export const ResultCell = ({ rowData, dataKey, handleSavePick, ...props }) => {
    const pickId = rowData['pickId'];
    const result = rowData[dataKey];
    const { backgroundColor, textColor } =
        String(result).toLowerCase() === 'win'
            ? { backgroundColor: '#dff0d8', textColor: '#3c763d' }
            : String(result).toLowerCase() === 'loss'
            ? { backgroundColor: '#f2dede', textColor: '#a94442' }
            : { backgroundColor: '#fff', textColor: '#000' };
    return (
        <Cell {...props} style={{ backgroundColor, textColor }}>
            <FormSelect
                onChange={e => {
                    if (pickId) {
                        handleSavePick(pickId, e.target.value);
                    }
                }}
                defaultValue={String(result).toLowerCase()}
            >
                {[
                    <option key={1} value="pending">
                        Pending
                    </option>,
                    <option key={2} value="win">
                        Win
                    </option>,
                    <option key={3} value="loss">
                        Loss
                    </option>,
                ]}
            </FormSelect>
        </Cell>
    );
};

export const OddValueCell = ({ rowData, dataKey, ...props }) => {
    const colText = rowData[dataKey];
    const colArr = colText ? colText.split('\n') : ['NA', 'NA'];
    return (
        <Cell {...props}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <span style={{ fontWeight: 'bold', color: '#444' }}>{colArr[0]}</span>
                <span style={{ fontWeight: '400', color: '#888', marginLeft: '8px', fontSize: '14px' }}>({colArr[1]})</span>
            </div>
        </Cell>
    );
};
