// Component imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ListGroup, Alert, Form } from 'react-bootstrap';
import StripePaymentForm from '../../components/payment/StripePaymentForm';
import moment from 'moment';
// Controller imports
import { saveReciept, createPaymentIntent, subscription } from '../../controllers/PaymentController';
import { register, deleteProfile } from '../../controllers/UserController';
// Styled Components imports
import { PaymentListItem } from './styles';
// Action imports
import { setStage } from '../../store/slices/registerSlice';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Pay = ({ setError }) => {
    // Redux
    const { data } = useSelector(state => state.register);
    const dispatch = useDispatch();
    // Local state
    const [intentLoading, setIntentLoading] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [autoPay, setAutoPay] = useState(true);

    const calculatePromo = () => {
        var price = data.plan.price;
        if (String(data.promo).toLowerCase() === 'playoffs') {
            return Math.floor(price / 2);
        }
        if (String(data.promo).toLowerCase() === 'yearly75') {
            return 75;
        }
        if (String(data.promo).toLowerCase() === 'yearly99') {
            return 99;
        }
        if (String(data.promo).toLowerCase() === 'restofyear') {
            return 50;
        }
        return price;
    };

    const price = calculatePromo();

    const createPayment = async () => {
        console.log(`Creating payment intent`);
        try {
            setIntentLoading(true);
            const { client_secret } = await createPaymentIntent({
                product: { ...data.plan, price: price },
                customer: data,
            });
            setClientSecret(client_secret);
        } catch (error) {
            console.log(`Error creating intent.\n${error}`);
        } finally {
            setIntentLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await createPayment();
        }
        fetchData();
    }, []);

    return (
        <ListGroup variant="flush">
            <PaymentListItem>
                <span className="item-desc">Display Name:</span>
                <span className="item-details">{data.displayName}</span>
            </PaymentListItem>
            <PaymentListItem>
                <span className="item-desc">Email:</span>
                <span className="item-details">{data.email}</span>
            </PaymentListItem>
            <PaymentListItem>
                <span className="item-desc">Package:</span>
                <span className="item-details">{data.plan.name}</span>
            </PaymentListItem>
            <PaymentListItem>
                <span className="item-desc">Price:</span>
                <span className="item-details">{`$${price}.00`}</span>
            </PaymentListItem>
            <PaymentListItem>
                {data.plan.id.indexOf('access') > -1 ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '15px',
                        }}
                    >
                        <span className="item-desc">AutoPay:</span>
                        <Form.Check
                            onClick={() => setAutoPay(!autoPay)}
                            style={{ marginLeft: '30px', marginTop: '-1px' }}
                            checked={autoPay}
                            disabled={data.plan.id === 'daily-access'}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {intentLoading || !clientSecret ? (
                    <div>Loading</div>
                ) : (
                    <Elements stripe={stripeTestPromise} options={{ clientSecret: clientSecret }}>
                        <StripePaymentForm
                            autoPay={autoPay}
                            handleSubmit={async id => {
                                try {
                                    var newUser = null;
                                    if (data.plan.id.indexOf('access') > -1) {
                                        newUser = await register(data.email, data.password, data.displayName, data.plan.type); // lowercase verification on the BE
                                    }
                                    if (autoPay && id && data.plan.id.indexOf('access') > -1) {
                                        await subscription({
                                            paymentId: id,
                                            product: {
                                                ...data.plan,
                                                price: price,
                                            }, // end date is created on the BE
                                            email: data.email,
                                        });
                                    }
                                    await saveReciept({
                                        items: [
                                            {
                                                ...data.plan,
                                                startDate: moment().toISOString(),
                                            },
                                        ], // end date is created on the BE
                                        email: data.email, // lowercase verification on the BE
                                        type: data.plan.type,
                                        firstName: data.firstName,
                                        lastName: data.lastName,
                                        twitter: data.twitter,
                                    });
                                    setError('');
                                    dispatch(setStage('access'));
                                } catch (error) {
                                    setError(error.message);
                                    deleteProfile(newUser.uid, String(newUser.email).toLowerCase());
                                    await createPayment();
                                }
                            }}
                            setError={setError}
                        />
                    </Elements>
                )}
                <Alert style={{ marginTop: '10px', textAlign: 'center' }} variant="light">
                    By clicking purchase you agree to Line Prophet LLC's privacy policy and terms and conditions.
                </Alert>
            </PaymentListItem>
        </ListGroup>
    );
};

export default Pay;
