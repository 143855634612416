// Library imports
import React from 'react';
import { ResponsivePie } from '@nivo/pie';

export default function PieChart({ data, colors = ['#d4edda', '#f8d7da'] }) {
    return (
        <ResponsivePie
            data={data.map(pieData => {
                return {
                    id: pieData.x,
                    label: pieData.x,
                    value: pieData.y,
                };
            })}
            colors={colors}
            margin={{ top: 5, right: 2, bottom: 5, left: 2 }}
            padAngle={3}
            innerRadius={0.2}
            borderWidth={2}
            enableArcLinkLabels={false}
            arcLabel={e => e.id}
            arcLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
        />
    );
}
