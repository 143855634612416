// Library imports
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
// Util imports
import { PRIMARY } from '../../../utils/styleConstants';
// Constant imports
import { POWER_BREAK_POINT } from '../../../app_pages/GameCards/game_card_windows/PowerRank/constants';

export const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
`;

export const TabButton = styled(Button)`
    flex: 1;
    border-radius: 0;
    font-size: 14px;
    background-color: ${props => (props.active ? PRIMARY : 'white')} !important;
    color: ${props => (props.active ? 'white' : PRIMARY)};
    border-width: 1px;
    border-color: ${PRIMARY};
    border-top-left-radius: ${props => (props.firstItem ? '999px' : '0')};
    border-bottom-left-radius: ${props => (props.firstItem ? '999px' : '0')};
    border-top-right-radius: ${props => (props.lastItem ? '999px' : '0')};
    border-bottom-right-radius: ${props => (props.lastItem ? '999px' : '0')};

    &:hover {
        background-color: ${PRIMARY} !important;
        color: white;
    }

    @media (max-width: ${POWER_BREAK_POINT}) {
        font-size: 12px;
    }
`;
