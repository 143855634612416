// Library imports
import React from 'react';
import { Card, ListGroup, Container } from 'react-bootstrap';
// Component imports
import TwoColumnView from '../../../components/card_components/TwoColumnView';
// Controller imports
import { shortName } from '../../../utils';
// Styled components
import { CardTitle, InnerContent } from './styles';
import { LabelValueListGroup } from '../../App/styles';

const Prediction = ({ game }) => {
    const addZeroes = num1 => {
        var num = Number(num1);
        if (isNaN(num)) return 0;
        if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2) {
            num = num.toFixed(2);
        }
        return num;
    };

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <TwoColumnView
                    columnOneJSX={
                        <Card>
                            <CardTitle className="text-center" color={game.awayTeam.color} style={{ display: 'block' }}>
                                {shortName(game.awayTeam)}
                            </CardTitle>
                            <Card.Text
                                style={{
                                    fontSize: '1.75rem',
                                    textAlign: 'center',
                                    fontWeight: '800',
                                    color: game.awayTeam.color,
                                    marginTop: 0,
                                    marginBottom: '10px',
                                }}
                            >
                                {addZeroes(game.prediction.awayScore)}
                            </Card.Text>
                            <LabelValueListGroup variant="flush">
                                {game.advantages.map((adv, i) => {
                                    if (adv.teamId === game.awayTeam.teamId) {
                                        return (
                                            <ListGroup.Item key={i}>
                                                <label>{adv.adv}</label>
                                                <span>{adv.description}</span>
                                            </ListGroup.Item>
                                        );
                                    }
                                    return false;
                                })}
                            </LabelValueListGroup>
                        </Card>
                    }
                    columnTwoJSX={
                        <Card>
                            <CardTitle className="text-center" color={game.homeTeam.color} style={{ display: 'block' }}>
                                {shortName(game.homeTeam)}
                            </CardTitle>
                            <Card.Text
                                style={{
                                    fontSize: '1.75rem',
                                    textAlign: 'center',
                                    fontWeight: '800',
                                    color: game.homeTeam.color,
                                    marginTop: 0,
                                    marginBottom: '10px',
                                }}
                            >
                                {addZeroes(game.prediction.homeScore)}
                            </Card.Text>
                            <LabelValueListGroup variant="flush">
                                {game.advantages.map((adv, i) => {
                                    if (adv.teamId === game.homeTeam.teamId) {
                                        return (
                                            <ListGroup.Item key={i}>
                                                <label>{adv.adv}</label>
                                                <span>{adv.description}</span>
                                            </ListGroup.Item>
                                        );
                                    }
                                    return false;
                                })}
                            </LabelValueListGroup>
                        </Card>
                    }
                />
            </Container>
        </InnerContent>
    );
};

export default Prediction;
