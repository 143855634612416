import { getUserToken } from './UserController';

export const getAllGameData = async (sport, accountType) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/games/upcoming?sport=${sport}&accountType=${accountType}`, {
        method: 'GET',
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getAllMongoGameData = async sport => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/games/completed?sport=${sport}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getAllTeamData = async sport => {
    sport = String(sport).toLowerCase();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/teams?sport=${sport}`, {
        method: 'GET',
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getGameTeamData = async (sport, game) => {
    sport = String(sport).toLowerCase();
    const allTeamInfo = await getAllTeamData(sport);
    const awayTeamData = allTeamInfo.find(team => team.teamId === game.awayTeam.teamId);
    const homeTeamData = allTeamInfo.find(team => team.teamId === game.homeTeam.teamId);
    if (awayTeamData === undefined) {
        console.log('--- ' + game.awayTeam.teamName + ' team data not found ---');
    }
    if (homeTeamData === undefined) {
        console.log('--- ' + game.homeTeam.teamName + ' team data not found ---');
    }
    return { awayTeamData, homeTeamData };
};

export const getTeamInfo = async (teamId, teamName, sport) => {
    const token = await getUserToken();
    var url = `${process.env.REACT_APP_HOST}/api/team?teamId=${teamId}&teamName=${teamName}&sport=${sport}`;
    if (teamId === '') url = `/api/team?teamName=${teamName}&sport=${sport}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const keyFactorList = async sport => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/keyfactors?sport=${sport}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const searchBuilderQuery = async (data, sport) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/games/searchBuilder?data=${encodeURIComponent(JSON.stringify(data))}&sport=${sport}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getLeagueData = async sport => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/league?sport=${sport}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getPropData = async sport => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/props?sport=${sport}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};
