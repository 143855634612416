// Library imports
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
// Drawer imports
import VIPDrawer from './drawers/VIPDrawer';
import StandingsDrawer from './drawers/StandingsDrawer';
// Styled compoent
import { DrawerHeader, DrawerTitle, DrawerContainer } from './styles';

export const Drawer = ({ closeDrawers, id, title }) => {
    const { loaders } = useSelector(state => state.app);

    return (
        <DrawerContainer initial={{ translateX: '100%' }} animate={{ translateX: 0 }} transition={{ ease: 'easeOut', duration: 0.75 }} className={`${id}`}>
            <DrawerHeader>
                <DrawerTitle>{title}</DrawerTitle>
                {loaders.find(loader => loader === 'drawerLoading') ? (
                    <FontAwesomeIcon className="ml-4" icon={faSpinner} spin color={'#007bff'} />
                ) : (
                    <Button className="ml-4" variant="link" onClick={closeDrawers}>
                        <FontAwesomeIcon style={{ marginTop: '-3px' }} icon={faArrowRight} />
                    </Button>
                )}
            </DrawerHeader>
            {sessionStorage.getItem('lp_expired') === 'true' ? (
                <></>
            ) : (
                <div className="drawer-content">
                    {id === 'vip' ? <VIPDrawer key={16} /> : <></>}
                    {id === 'standings' ? <StandingsDrawer key={14} /> : <></>}
                </div>
            )}
        </DrawerContainer>
    );
};
