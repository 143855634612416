import fire from 'firebase';

const firebaseConfig = {
    apiKey: 'AIzaSyBgOTkrcZSHaryM5t8wrOmWsC9xevLvwx0',
    authDomain: 'line-prophet.firebaseapp.com',
    databaseURL: 'https://line-prophet.firebaseio.com',
    projectId: 'line-prophet',
    storageBucket: 'line-prophet.appspot.com',
    messagingSenderId: '1068006183477',
    appId: '1:1068006183477:web:521ce631310b2a740cd77c',
};

if (!fire.apps.length) {
    fire.initializeApp(firebaseConfig);
}
