// Library imports
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
// Util imports
import { BLACK, HEADING_FONT_WEIGHT, PRIMARY_DARK, WHITE } from '../../../../utils/styleConstants';
// Constant imports
import { POWER_BREAK_POINT } from './constants';

export const PowerCol = styled(Col)`
    @media (max-width: ${POWER_BREAK_POINT}) {
        ${props => props.leftCol && 'padding-right: 0;'}
        ${props => props.rightCol && 'padding-left: 0;'}
        ${props => props.centerCol && 'padding: 0;'}
    }
`;

export const TeamSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    text-align: center;
`;

export const TeamNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${POWER_BREAK_POINT}) {
        align-items: ${p => (p.side === 'left' ? 'flex-start' : 'flex-end')};
    }
    justify-content: flex-start;
`;

export const TeamLogo = styled.img`
    height: 40px;
    width: 40px;
    margin-bottom: -8px;
`;

export const PowerTeamId = styled.span`
    display: block;
    font-size: 10px;
    color: #888;
    margin-top: -10px;
    margin-bottom: 10px;
`;

export const Score = styled.span`
    display: block;
    font-size: 32px;
    color: ${BLACK};
    margin: 5px 0px;
    font-weight: ${HEADING_FONT_WEIGHT};
`;

export const PowerDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PowerItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
`;

export const PowerItemLabel = styled.span`
    font-weight: ${HEADING_FONT_WEIGHT};
    color: ${BLACK};
    margin-bottom: 10px;
`;

export const PowerRankArrow = styled(FontAwesomeIcon)`
    color: ${PRIMARY_DARK};
    font-size: 24px;
    background: ${WHITE};
    border-radius: 50%;
    @media (max-width: ${POWER_BREAK_POINT}) {
        font-size: 16px;
        margin: 0px 5px;
    }
`;
