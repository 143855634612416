// Library imports
import React, { useState } from 'react';
import { LoadingSpinner } from '../../../../components/page_components/LoadingSpinner';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// Component imports
import OddsBoard from './OddsBoard';
import Confirm from './Confirm';
// Util imports
import { PICK_OBJ } from '../../../../utils/constants';
// Styled components
import { InnerContent } from '../styles';

const PickOdds = ({ game }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    // Local state
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState('odds');
    const [pickData, setPickData] = useState({
        ...PICK_OBJ,
        units: user.units ? user.units : 1,
    });

    if (loading) {
        return (
            <div className="inner-content">
                <Container className="mt-2" style={{ height: '100%' }} fluid>
                    <LoadingSpinner loading={loading} />
                </Container>
            </div>
        );
    }
    return (
        <InnerContent>
            <Container className="mt-2 mb-3" style={{ height: '100%' }} fluid>
                {view === 'confirm' ? (
                    <Confirm game={game} setView={setView} pickData={pickData} setPickData={setPickData} setLoading={setLoading} />
                ) : (
                    <OddsBoard game={game} setView={setView} pickData={pickData} setPickData={setPickData} setLoading={setLoading} />
                )}
            </Container>
        </InnerContent>
    );
};

export default PickOdds;
