// Library imports
import React, { useState, useEffect } from 'react';
import { Alert, Row, Col, Form, Card, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// Component imports
import Subscription from './Subscription';
import Dropdown from '../../../../components/page_components/DropDown';
// Controller imports
import { updateReciept, createPaymentIntent, subscription } from '../../../../controllers/PaymentController';
// Util imports
import { calcClientEndDate, scrollToTop } from '../../../../utils';
import { PACKAGES } from '../../../../utils/constants';
// Stripe imports
import StripePaymentForm from '../../../../components/payment/StripePaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// Action imports
import { fetchCustomer, fetchReciept } from '../../../../store/slices/userSlice';
// Styled components
import { PackageDetailsCard, PaymentListItemWithPadding } from './styles';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ManagePackage = () => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [message, setMessage] = useState('');
    const [newPlan, setNewPlan] = useState(PACKAGES['weekly-access']);
    const [intentLoading, setIntentLoading] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [autoPay, setAutoPay] = useState(true);

    const lifeTimeUser =
        (!user.reciept?.endDate || user.reciept?.endDate === 'never') && user.reciept?.type !== 'free-account';

    const getStartDate = () => {
        if (user.reciept.items.length && user.reciept.items[user.reciept.items.length - 1].startDate) {
            return moment(user.reciept.items[user.reciept.items.length - 1].startDate).format('MM/DD/YYYY');
        } else {
            return moment(user.reciept.startDate).format('MM/DD/YYYY');
        }
    };

    const getEndDate = () => {
        if (lifeTimeUser) {
            return 'Lifetime access';
        }
        if (user.reciept?.type === 'free-account') {
            return 'Free account';
        }
        return moment(user.reciept.endDate).format('MM/DD/YYYY');
    };

    useEffect(() => scrollToTop(), [message]);

    useEffect(() => {
        async function fetchData() {
            console.log(`--- Creating payment intent ---`);
            try {
                setIntentLoading(true);
                const { client_secret } = await createPaymentIntent({
                    product: { ...newPlan },
                    customer: { ...user },
                });
                setClientSecret(client_secret);
            } catch (error) {
                console.log(`Error creating intent.\n${error}`);
            } finally {
                setIntentLoading(false);
            }
        }
        if (!lifeTimeUser) {
            fetchData();
        }
    }, [newPlan]);

    return (
        <div>
            <Alert
                style={{
                    display: message ? 'block' : 'none',
                    marginTop: '15px',
                }}
                variant={message.includes('Error') ? 'danger' : 'success'}
            >
                {message}
            </Alert>
            {!user.reciept.items ? (
                <Alert style={{ textAlign: 'left' }} variant={'info'}>
                    No subscription information found.
                </Alert>
            ) : (
                <Row>
                    <Col sm={12} md={3}>
                        <PackageDetailsCard>
                            <Card.Header>All Access Package</Card.Header>
                            <ListGroup variant="flush">
                                <PaymentListItemWithPadding>
                                    <span className="item-desc">Start Date:</span>
                                    <span className="item-details">{getStartDate()}</span>
                                </PaymentListItemWithPadding>
                                <PaymentListItemWithPadding>
                                    <span className="item-desc">End Date:</span>
                                    <span className="item-details">{getEndDate()}</span>
                                </PaymentListItemWithPadding>
                            </ListGroup>
                        </PackageDetailsCard>
                    </Col>
                    <Col sm={12} md={9}>
                        <Card>
                            <Card.Header>Manage Package</Card.Header>
                            {lifeTimeUser ? (
                                <Row>
                                    <Col xs={10} sm={10} style={{ margin: '15px auto 0px auto' }}>
                                        <Alert style={{ textAlign: 'center' }} variant="success">
                                            You have lifetime access to the Line Prophet app.
                                        </Alert>
                                    </Col>
                                </Row>
                            ) : user.cust?.subscriptions?.data?.length ? (
                                <Row xs={1} md={2}>
                                    {user.cust?.subscriptions.data.map((subscription, i) => {
                                        return (
                                            <Col key={i}>
                                                <Subscription
                                                    subscription={subscription}
                                                    setCustomer={() => ''}
                                                    reciept={user.reciept}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            ) : (
                                <ListGroup variant="flush">
                                    <PaymentListItemWithPadding>
                                        <span className="item-desc">
                                            {user.reciept?.type === 'free-account'
                                                ? 'Get All Access:'
                                                : 'Extend Package:'}
                                        </span>
                                        <Form.Group as={Row} className="mb-0" controlId="package">
                                            <Col sm="10">
                                                <Dropdown
                                                    variant="light"
                                                    dropdownStyle={{
                                                        border: '1px solid #ddd',
                                                        borderRadius: 5,
                                                    }}
                                                    value={newPlan?.id}
                                                    setValue={selectedKey => setNewPlan(PACKAGES[selectedKey])}
                                                    menuItems={[
                                                        'daily-access',
                                                        'weekly-access',
                                                        'monthly-access',
                                                        'yearly-access',
                                                    ].map(product => product)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </PaymentListItemWithPadding>
                                    {user.reciept?.type === 'free-account' ? (
                                        <></>
                                    ) : (
                                        <PaymentListItemWithPadding>
                                            <span className="item-desc">Current End Date:</span>
                                            <span className="item-details">
                                                {moment(user.reciept.endDate).format('MM/DD/YYYY')}
                                            </span>
                                        </PaymentListItemWithPadding>
                                    )}
                                    <PaymentListItemWithPadding>
                                        <span className="item-desc">
                                            {user.reciept?.type === 'free-account' ? 'End Date' : 'New End Date:'}
                                        </span>
                                        <span className="item-details">
                                            {moment(calcClientEndDate(newPlan.id, user.reciept)).format('MM/DD/YYYY')}
                                        </span>
                                    </PaymentListItemWithPadding>
                                    <PaymentListItemWithPadding>
                                        <span className="item-desc">Price:</span>
                                        <span className="item-details">{`$${newPlan?.price}.00`}</span>
                                    </PaymentListItemWithPadding>
                                    <PaymentListItemWithPadding>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            <span className="item-desc">AutoPay:</span>
                                            <Form.Check
                                                onClick={() => setAutoPay(!autoPay)}
                                                style={{
                                                    marginLeft: '30px',
                                                    marginTop: '-1px',
                                                }}
                                                checked={autoPay}
                                            />
                                        </div>
                                    </PaymentListItemWithPadding>
                                    <PaymentListItemWithPadding>
                                        <span className="item-desc">Payment:</span>
                                        {intentLoading || !clientSecret ? (
                                            <div>Loading</div>
                                        ) : (
                                            <Elements
                                                stripe={stripeTestPromise}
                                                options={{
                                                    clientSecret: clientSecret,
                                                }}
                                            >
                                                <StripePaymentForm
                                                    autoPay={autoPay}
                                                    handleSubmit={async id => {
                                                        // This creates the isoFormatted date - There is no BE validations for isoFormatted date on the PATCH endpoint
                                                        const endDate = calcClientEndDate(newPlan.id, user.reciept);
                                                        if (autoPay && id) {
                                                            await subscription({
                                                                paymentId: id,
                                                                product: {
                                                                    ...newPlan,
                                                                    price: newPlan.price,
                                                                }, // end date is created on the BE
                                                                email: user.email,
                                                                endDate: endDate,
                                                            });
                                                        }
                                                        await updateReciept(user.reciept._id, 'endDate', endDate);
                                                        await updateReciept(user.reciept._id, 'type', newPlan.type);
                                                        await updateReciept(user.reciept._id, 'items', {
                                                            ...newPlan,
                                                            startDate: moment().toISOString(),
                                                        });
                                                        dispatch(fetchReciept());
                                                        dispatch(fetchCustomer());
                                                        setMessage('');
                                                        setNewPlan({
                                                            ...PACKAGES['weekly-access'],
                                                        });
                                                        scrollToTop();
                                                    }}
                                                    setError={async message => {
                                                        setMessage(message);
                                                    }}
                                                />
                                            </Elements>
                                        )}
                                        <Alert
                                            style={{
                                                marginTop: '10px',
                                                textAlign: 'center',
                                            }}
                                            variant="light"
                                        >
                                            By clicking purchase you agree to Line Prophet LLC's privacy policy and
                                            terms and conditions.
                                        </Alert>
                                    </PaymentListItemWithPadding>
                                </ListGroup>
                            )}
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default ManagePackage;
