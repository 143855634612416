// Library imports
import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const SearchFilterContainer = styled.div`
    display: flex;
    justify-content: center;
    background-image: linear-gradient(180deg, #f1f1f1 0%, #f4f4f4 100%);
    padding: 15px 0px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    @media (max-width: 992px) {
        padding: 15px 15px;
        justify-content: flex-start;
        padding-left: 35px;

        .search-col {
            order: 1;
            margin-bottom: 10px;
            form {
                border: 1px solid #ddd;
                border-radius: 0 !important;
            }
        }

        .input-group {
            padding: 5px 0px;
        }

        .order-2-col {
            order: 2;
        }

        .order-3-col {
            order: 3;
        }
    }
`;

export const SearchFilterRow = styled(Row)`
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 999px;
    @media (max-width: 992px) {
        width: 83%;
        border: 0px !important;
        border-radius: 0px !important;

        button {
            border: 1px solid #ddd !important;
            border-radius: 0px !important;
        }
    }
`;
