// Library imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Controller imports
import { authListener, getUserData } from '../../controllers/UserController';
import { getCustomer, getReciept } from '../../controllers/PaymentController';

const initialState = {
    user: {},
    userLoading: true,
    receiptLoading: false,
    customerLoading: false,
};

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { rejectWithValue }) => {
    try {
        const user = await authListener();
        const userData = await getUserData(user.uid);
        var reciept = await getReciept(userData.email, 'account');
        if (Object.keys(reciept).length === 0) {
            reciept = await getReciept(userData.email, 'free-account');
        }
        const cust = await getCustomer(user.email);
        return {
            token: user.token,
            ...userData,
            reciept: { ...reciept },
            cust: { ...cust },
        };
    } catch (error) {
        return rejectWithValue(`Error setting up auth listener\n${error.message}`);
    }
});

export const fetchReciept = createAsyncThunk('user/fetchReciept', async (_, { getState, rejectWithValue }) => {
    try {
        const { user: userSlice } = getState();
        const user = userSlice.user;
        var reciept = await getReciept(user.email, 'account');
        if (Object.keys(reciept).length === 0) {
            reciept = await getReciept(user.email, 'free-account');
        }
        return { ...reciept };
    } catch (error) {
        return rejectWithValue(`Error getting reciept in fetchReciept\n${error.message}`);
    }
});

export const fetchCustomer = createAsyncThunk('user/fetchCustomer', async (_, { getState, rejectWithValue }) => {
    try {
        const { user: userSlice } = getState();
        const user = userSlice.user;
        const cust = await getCustomer(user.email);
        return { ...cust };
    } catch (error) {
        return rejectWithValue(`Error getting customer in fetchCustomer\n${error.message}`);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser(state, { payload }) {
            state.user = { ...state.user, ...payload };
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUser.pending, state => {
            state.userLoading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            state.user = { ...payload };
            state.userLoading = false;
        });
        builder.addCase(fetchUser.rejected, state => {
            state.userLoading = false;
        });
        builder.addCase(fetchReciept.pending, state => {
            state.receiptLoading = true;
        });
        builder.addCase(fetchReciept.fulfilled, (state, { payload }) => {
            state.user = { ...state.user, reciept: { ...payload } };
            state.receiptLoading = false;
        });
        builder.addCase(fetchReciept.rejected, state => {
            state.receiptLoading = false;
        });
        builder.addCase(fetchCustomer.pending, state => {
            state.customerLoading = true;
        });
        builder.addCase(fetchCustomer.fulfilled, (state, { payload }) => {
            state.user = { ...state.user, cust: { ...payload } };
            state.customerLoading = false;
        });
        builder.addCase(fetchCustomer.rejected, state => {
            state.customerLoading = false;
        });
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
