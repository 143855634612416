// Library imports
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWind } from '@fortawesome/free-solid-svg-icons';
// Component imports
import NoData from '../../../components/page_components/NoData';
// Image imports
import arrow from '../../../img/arrow.png';
import field from '../../../img/baseballfield.png';

const Weather = ({ sport, game }) => {
    const rotateImg = coord => {
        switch (coord) {
            case 'N':
                return 'rotate(0deg)';
            case 'NE':
                return 'rotate(45deg)';
            case 'NNE':
                return 'rotate(45deg)';
            case 'E':
                return 'rotate(90deg)';
            case 'SE':
                return 'rotate(135deg)';
            case 'SSE':
                return 'rotate(135deg)';
            case 'S':
                return 'rotate(180deg)';
            case 'SW':
                return 'rotate(225deg)';
            case 'WSW':
                return 'rotate(225deg)';
            case 'W':
                return 'rotate(270deg)';
            case 'NW':
                return 'rotate(315deg)';
            default:
                return 'rotate(0deg)';
        }
    };

    if (!game.weather?.forecast?.desc) {
        return <NoData title="No data" message="There is currently no weather data for this game.  Check back soon." />;
    }

    return (
        <div className="weather-wrapper">
            <div
                className={`weather-icon ${
                    game.weather.forecast.desc[0].toLowerCase().includes('overcast clouds') ||
                    game.weather.forecast.desc[0].toLowerCase().includes('rain')
                        ? 'cloud'
                        : 'sun'
                }`}
            ></div>
            <div className="additional-weather-info">
                <p>
                    <FontAwesomeIcon icon={faWind} />
                    {game.weather.forecast.wind_speed} {game.weather.forecast.wind_dir}
                </p>
            </div>
            <div>
                {sport === 'mlb' ? (
                    <>
                        <div
                            id="ballpark-container"
                            style={{
                                backgroundImage: `url(${field})`,
                                transform: rotateImg(game.fieldOrientation),
                            }}
                        ></div>
                        <img
                            alt="wind direction"
                            id="wind-direction"
                            style={{
                                height: '50px',
                                width: '50px',
                                marginTop: '90px',
                                marginLeft: '290px',
                                transform: rotateImg(game.weather.forecast.wind_dir),
                            }}
                            src={arrow}
                        />
                    </>
                ) : (
                    <></>
                )}
                <div id="temp-container">
                    <h1>{game.weather.forecast.temperature}</h1>
                    <p className="main-data">{game.weather.forecast.desc}</p>
                </div>
            </div>
        </div>
    );
};

export default Weather;
