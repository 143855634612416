// Library imports
import React from 'react';
import { BeatLoader } from 'react-spinners';
import { Button } from 'react-bootstrap';
// CSS imports
import '../../css/LoadingSpinner.css';

export const LoadingSpinner = ({ loading, text = '', onClick = undefined }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className={loading ? 'loading-spinner' : 'hide'}
        >
            <BeatLoader color={'#123abc'} loading={loading} />
            {text !== '' ? <h5 style={{ textAlign: 'center', margin: '15px 0px' }}>{text}</h5> : <></>}
            {onClick ? (
                <Button variant="light" onClick={onClick}>
                    Cancel
                </Button>
            ) : (
                <></>
            )}
        </div>
    );
};
