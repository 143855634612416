// Library imports
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faListOl } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import { Drawer } from './Drawer';
import UpgradeTooltip from '../../components/page_components/UpgradeTooltip';
// Actions imports
import { setDrawer } from '../../store/slices/appSlice';
// Styled components
import { SidebarContainer, TabContainer, Tab, BackDrop } from './styles';

const tabData = {
    vip: {
        title: 'VIP Picks',
        id: 'vip',
        icon: <FontAwesomeIcon icon={faUserCheck} />,
        free: false,
    },
    standings: {
        title: 'Standings',
        id: 'standings',
        icon: <FontAwesomeIcon icon={faListOl} />,
        free: true,
    },
};

const Sidebar = ({ tabs }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const { page, openDrawer } = useSelector(state => state.app);
    const dispatch = useDispatch();

    return (
        <SidebarContainer id="sidebar" page={page} openDrawer={openDrawer}>
            {!openDrawer ? (
                <TabContainer>
                    {tabs.map(tab => {
                        if (tabData[tab].free || user.reciept.type !== 'free-account') {
                            return (
                                <Tab
                                    key={tabData[tab].id}
                                    onClick={() => dispatch(setDrawer(tabData[tab].id))}
                                    className={tabData[tab].id}
                                >
                                    {tabData[tab].icon}
                                    {tabData[tab].title}
                                </Tab>
                            );
                        } else {
                            return (
                                <UpgradeTooltip
                                    children={
                                        <Tab onClick={() => ''} id={tabData[tab].id}>
                                            {tabData[tab].icon}
                                            {tabData[tab].title}
                                        </Tab>
                                    }
                                />
                            );
                        }
                    })}
                </TabContainer>
            ) : (
                <>
                    <Drawer
                        title={tabData[openDrawer].title}
                        id={openDrawer}
                        closeDrawers={() => dispatch(setDrawer(''))}
                    />
                    <BackDrop onClick={() => dispatch(setDrawer(''))} />
                </>
            )}
        </SidebarContainer>
    );
};

export default Sidebar;
