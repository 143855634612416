// Library imports
import React from 'react';
import { Alert } from 'react-bootstrap';

const NoData = ({
    message,
    title,
    variant = 'primary',
    containerStyle = {},
    headerFontSize = 18,
    messageFontSize = 14,
}) => {
    return (
        <Alert
            variant={variant}
            style={{
                alignSelf: 'flex-start',
                maxWidth: '900px',
                margin: 15,
                width: '-webkit-fill-available',
                ...containerStyle,
            }}
        >
            <Alert.Heading style={{ fontSize: headerFontSize }}>
                {title ? title : 'There are no results.'}
            </Alert.Heading>
            <span style={{ fontSize: messageFontSize }}>
                {message
                    ? message
                    : 'Check back later for more data.  You can also try another filter or search query to find what you are looking for.'}
            </span>
        </Alert>
    );
};

export default NoData;
