// Library imports
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const OverUnderButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    button:first-child {
        margin-right: 5px;
    }
    @media (max-width: 1225px) {
        flex-direction: column;
        button:first-child {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
`;

export const OddLabel = styled.span`
    font-weight: 500;
`;

export const OddButton = styled(Button)`
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #f8f9fa !important;
    border-color: ${props => (props.active ? '#28a745' : '#ddd')} !important;
    color: ${props => (props.active ? '#155724' : '#212529')} !important;
    border-radius: 10px;
    .juice {
        font-size: 10px;
        color: ${props => (props.active ? '#155724' : '#212529')} !important;
        margin-left: 3px;
    }
    .value {
        font-size: 14px;
        font-weight: bold;
    }
`;
