// Library imports
import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
// Component imports
import Dropdown from '../DropDown';
// Styled components
import { SearchFilterContainer, SearchFilterRow } from './styles';

const labelMap = {
    playerProps: 'Player Props',
    'Player Props': 'playerProps',
};

const PropSearchFilter = ({ data, propCategory, setPropCategory, propType, setPropType, value, setValue }) => {
    return (
        <SearchFilterContainer>
            <SearchFilterRow>
                <Col md={12} lg={2} className="order-2-col pl-0 pr-0 d-flex align-items-stretch">
                    <Dropdown
                        icon={<FontAwesomeIcon icon={faCalendar} />}
                        toggleStyle={{
                            borderRadius: 0,
                            flex: 0,
                            minWidth: 50,
                            background: '#fff',
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                            borderRight: '1px solid #ccc',
                            paddingLeft: 20,
                        }}
                        value={labelMap[propCategory]}
                        setValue={selectedKey => setPropCategory(labelMap[selectedKey])}
                        menuItems={Object.keys(data).map(key => labelMap[key])}
                        caption="prop category"
                    />
                </Col>
                <Col md={12} lg={6} className="search-col pl-0 pr-0 d-flex align-items-stretch">
                    <Form
                        style={{
                            width: '100%',
                            display: 'flex',
                            background: '#fff',
                        }}
                        onSubmit={e => {
                            e.preventDefault();
                            return false;
                        }}
                    >
                        <InputGroup>
                            <Form.Control
                                type="search"
                                placeholder="Start typing player or name..."
                                aria-label="Search"
                                id="card-search"
                                onChange={e => setValue(e.target.value)}
                                value={value}
                                style={{
                                    borderRadius: 0,
                                    borderWidth: 0,
                                    height: 'auto',
                                }}
                            />
                        </InputGroup>
                    </Form>
                </Col>
                <Col md={12} lg={4} className="order-3-col pl-0 pr-0 d-flex align-items-stretch">
                    <Dropdown
                        dropdownStyle={{ borderRadius: 0 }}
                        toggleStyle={{
                            borderRadius: 0,
                            borderLeft: '1px solid #ccc',
                            flex: 0,
                            minWidth: 50,
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                            background: '#fff',
                            paddingLeft: 20,
                        }}
                        value={
                            Object.values(data[propCategory]).find(prop => prop.id === propType)
                                ? Object.values(data[propCategory]).find(prop => prop.id === propType).name
                                : ''
                        }
                        setValue={selectedKey =>
                            setPropType(Object.values(data[propCategory]).find(prop => prop.name === selectedKey).id)
                        }
                        menuItems={Object.keys(data[propCategory]).map(key => {
                            const prop = data[propCategory][key];
                            return prop.name;
                        })}
                        caption="prop type"
                    />
                </Col>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

export default PropSearchFilter;
