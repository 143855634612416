import styled from 'styled-components';
import { Badge, Button } from 'react-bootstrap';

export const GameCardHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #dedede;
    background-color: #fff;
    position: relative;
`;

export const GameCardTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const GameCardTitle = styled.span`
    font-size: 18px;
    font-weight: bold;
`;

export const GameCardSubtitle = styled.span`
    font-size: 14px;
`;

export const LockButton = styled(Button)``;

export const BadgeContainer = styled.div`
    position: absolute;
    top: 38px;
    left: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const VIPBadge = styled(Badge)``;

export const LiveBadge = styled(Badge)`
    color: #fff;
    margin-right: 5px;
`;
