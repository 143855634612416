// Library imports
import React from 'react';
import { Card, Container, Alert, ListGroup } from 'react-bootstrap';
// Component imports
import NoData from '../../../components/page_components/NoData';
import TwoColumnView from '../../../components/card_components/TwoColumnView';
// Util imports
import { shortName } from '../../../utils';
// Styled components
import { CardTitle, InnerContent } from './styles';
import { LabelValueListGroup } from '../../App/styles';

const InjuryList = ({ game }) => {
    const createInjuryJSX = team => {
        return (
            <Card>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                {!team.injuries || team.injuries.length === 0 ? (
                    <LabelValueListGroup variant="flush">
                        <ListGroup.Item key={102}>
                            <Alert className="m-auto">No injuries reported.</Alert>
                        </ListGroup.Item>
                    </LabelValueListGroup>
                ) : (
                    <LabelValueListGroup variant="flush">
                        {team.injuries.map((injury, j) => {
                            return (
                                <ListGroup.Item key={j}>
                                    <label>
                                        {injury.player} ({injury.position})
                                    </label>
                                    <span>
                                        <span
                                            style={{
                                                display: 'block',
                                                color: '#999',
                                            }}
                                        >
                                            {injury.status} | {injury.date}
                                        </span>
                                        {injury.desc}
                                    </span>
                                </ListGroup.Item>
                            );
                        })}
                    </LabelValueListGroup>
                )}
            </Card>
        );
    };

    if (!game.awayTeam.injuries && !game.homeTeam.injuries) {
        return <NoData />;
    }

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <TwoColumnView
                    columnOneJSX={createInjuryJSX(game.awayTeam)}
                    columnTwoJSX={createInjuryJSX(game.homeTeam)}
                />
            </Container>
        </InnerContent>
    );
};

export default InjuryList;
