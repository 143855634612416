// Library imports
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ResponsivePie } from '@nivo/pie';
// Component imports
import NoData from '../../../components/page_components/NoData';
import CompareList from '../../../components/card_components/CompareList';
// Util imports
import { shortName } from '../../../utils';
// Styled components
import { InnerContent } from './styles';

const Totals = ({ sport, game, history }) => {
    const generateFieldList = team => {
        if (!team?.stats) {
            return [];
        }
        const teamStats = [];
        teamStats.push(team.stats.off?.oppg ? team.stats.off?.oppg : 'N/A');
        teamStats.push(team.recordData?.ou ? team.recordData?.ou : 'N/A');
        if (sport === 'mlb') {
            return teamStats;
        }
        if (sport === 'nba' || sport === 'cbb') {
            teamStats.push(team.stats.off?.paceofplay ? team.stats.off?.paceofplay : 'N/A');
        } else {
            teamStats.push(team.stats.off?.top ? team.stats.off?.top : 'N/A');
        }
        return teamStats;
    };

    if (!history && !game.totalStats) {
        return <NoData title="No data" message="There is currently no total data for this game.  Check back soon." />;
    }

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <Row style={{ minHeight: '150px' }} className="mb-3 justify-content-center">
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '5px',
                        }}
                    >
                        <span style={{ marginRight: '10px', fontSize: '1.5rem' }}>Total</span>
                        <h1
                            style={{
                                marginBottom: '0px',
                                fontWeight: '800',
                                fontSize: '2.5rem',
                            }}
                        >
                            {game.total}
                        </h1>
                    </Col>
                    {!history ? (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ResponsivePie
                                animate={false}
                                data={[
                                    {
                                        id: 'Overs',
                                        label: 'Overs',
                                        value: game.totalStats
                                            ? game.totalStats.over / (game.totalStats.under + game.totalStats.over)
                                            : 0,
                                    },
                                    {
                                        id: 'Unders',
                                        label: 'Unders',
                                        value: game.totalStats
                                            ? game.totalStats.under / (game.totalStats.under + game.totalStats.over)
                                            : 0,
                                    },
                                ]}
                                colors={
                                    game.totalStats.under < game.totalStats.over
                                        ? ['#d4edda', '#f8d7da']
                                        : ['#f8d7da', '#d4edda']
                                }
                                margin={{
                                    top: 1,
                                    right: 55,
                                    bottom: 1,
                                    left: 55,
                                }}
                                startAngle={-90}
                                endAngle={90}
                                padAngle={3}
                                valueFormat=" >-.2%"
                                innerRadius={0.25}
                                borderWidth={2}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={3}
                                arcLinkLabelsColor={{ from: 'color' }}
                                arcLabelsSkipAngle={10}
                                arcLinkLabelsDiagonalLength={1}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [['darker', 2]],
                                }}
                            />
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
                <CompareList
                    labels={
                        sport === 'mlb'
                            ? ['PPG', 'O/U']
                            : ['PPG', 'O/U', sport === 'nba' || sport === 'cbb' ? 'Pace of Play' : 'Time of Poss.']
                    }
                    dataSetOne={generateFieldList(game.awayTeam)}
                    dataSetOneTitle={shortName(game.awayTeam)}
                    dataSetOneTitleColor={game.awayTeam.color}
                    dataSetTwo={generateFieldList(game.homeTeam)}
                    dataSetTwoTitle={shortName(game.homeTeam)}
                    dataSetTwoTitleColor={game.homeTeam.color}
                />
            </Container>
        </InnerContent>
    );
};

export default Totals;
