// Library imports
import React from 'react';
import { Table as BootstrapTable, Nav } from 'react-bootstrap';
// Component imports
import { LoadingSpinner } from './LoadingSpinner';
// Util imports
import { capitalize } from '../../utils';

const Table = ({
    style = {},
    tableStyle = {},
    headers = [],
    rows = [],
    tabs = {},
    responsive = true,
    loading = false,
    hover = true,
}) => {
    return (
        <div style={style}>
            {Object.keys(tabs).length ? (
                <Nav variant="tabs" activeKey={tabs.activeKey}>
                    {tabs.keys.map((key, i) => {
                        return (
                            <Nav.Item key={i}>
                                <Nav.Link eventKey={key} onClick={() => tabs.handleOnNavClick(key)}>
                                    {capitalize(key)}
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Nav>
            ) : (
                <></>
            )}
            <BootstrapTable striped bordered hover={hover} responsive={responsive} style={tableStyle}>
                <thead>
                    <tr>
                        {headers.map((header, i) => {
                            return <th key={i}>{header}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <td colSpan={headers.length}>
                            <LoadingSpinner loading />
                        </td>
                    ) : (
                        rows
                    )}
                </tbody>
            </BootstrapTable>
        </div>
    );
};

export default Table;
