// Library imports
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
// Component imports
import { Header } from '../components/header';
import Footer from '../components/footer';
import Section from '../components/page_components/Section';
import Testimonials from '../components/page_components/Testimonials';
// Util imports
import { scrollToTop } from '../utils';
// Image imports
import communityIcon from '../img/logo-icon.png';
import PrimaryButton from '../components/page_components/Buttons/PrimaryButton';

const About = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Learn More</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
            </Helmet>
            <Header currentPage="about" lightMode={true} shadow />
            <Section
                backgroundColor="white"
                lightText={false}
                lightTextDesc={false}
                topLine="Focused Algorithms and Data"
                headline={'Line Prophet provides an in-depth look at every game.'}
                description="Access comprehensive game data effortlessly with our unique matchup cards, providing a distinctive insight into each game. Each window is a gateway to a wealth of matchup data. Immerse yourself in the experience by trying out our demo card - your key to unlocking unparalleled insights."
                buttons={[
                    <Link to="/packages">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 5 }} />
                            Get Started
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart=""
                img={''}
                contentType="card"
                slideDirection="img-slide-right"
            />
            <Section
                backgroundColor="silver"
                lightText={false}
                lightTextDesc={false}
                topLine="Trends and Situational Data"
                headline="Take your capping to the next level."
                description="Beyond head-to-head statistics, Line Prophet's system incorporates trends and situational data, adding depth to your analysis. Unlock the full spectrum of information, empowering you to make informed decisions. Access this wealth of data firsthand to enhance your understanding of each scenario and maximize your sports betting strategy."
                buttons={[
                    <Link to="/packages">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 5 }} />
                            Get Started
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart="start"
                img={''}
                contentType="trend"
                slideDirection="img-slide-left"
            />
            <Section
                backgroundColor="white"
                lightText={false}
                lightTextDesc={false}
                topLine="How does it all work?"
                headline="FAQs"
                description="Learn more about what an all access package provides.  We are so much more than just expert pick selections.  Our data and insights truly provide a one-of-a-kind look at every matchup"
                buttons={[
                    <Link to="/packages">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 5 }} />
                            Get Started
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart=""
                slideDirection="img-slide-right"
                contentType="faq"
            />
            <Section
                backgroundColor="silver"
                lightText={false}
                lightTextDesc={false}
                topLine="Honesty, transparency, results"
                headline="VIP Picks & Transparency."
                description="Explore our VIP Picks and Transparency Drawer, offering a transparent window into historical prediction data. Track the algorithm's success over time to uncover valuable model trends. With Line Prophet, informed decisions are not just a possibility but a result of data-driven insights and a commitment to transparency."
                buttons={[
                    <Link to="/packages">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 5 }} />
                            Get Started
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart=""
                contentType="vip-stats"
                slideDirection="img-slide-right"
            />
            <Section
                backgroundColor="white"
                lightText={false}
                lightTextDesc={false}
                topLine="Trends, Leaderboards, Unique Filters"
                headline="Membership has its benefits."
                description="Join the Line Prophet community and equip yourself with the essential tools to identify winners effortlessly. As a valued member, benefit from daily expert picks, eliminating the need for manual research. Your all-access account not only grants entry to the Line Prophet system but also secures an invitation to our exclusive private Twitter feed and Discord server. Elevate your sports betting experience with a community dedicated to success."
                buttons={[
                    <Link to="/packages">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 5 }} />
                            Get Started
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart="start"
                img={communityIcon}
                slideDirection="img-slide-left"
            />
            <Testimonials />
            <Footer />
        </div>
    );
};

export default About;
