import styled from 'styled-components';
import { HEADING_FONT, HEADING_FONT_WEIGHT, PRIMARY_DARK, SILVER, WHITE } from '../../../utils/styleConstants';

export const SectionContainer = styled.div`
    padding: 150px 0px;
    background-color: ${props => (props.variant === 'white' ? WHITE : props.variant === 'silver' ? SILVER : PRIMARY_DARK)};
`;

export const ContentWrapper = styled.div`
    max-width: 540px;
    padding-top: 0px;
    padding: 60px;
    a:hover {
        text-decoration: none;
    }
    @media (max-width: 768px) {
        padding: 0px !important;
    }
`;

export const TopLine = styled.div`
    color: #f00946;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.4px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    font-family: ${HEADING_FONT};
    font-weight: ${HEADING_FONT_WEIGHT} !important;
    margin-bottom: 24px !important;
    font-size: ${props => (props.main === 'true' ? '3.75rem' : '2.5rem')} !important;
    line-height: ${props => (props.main === 'true' ? '3.40rem' : '2.5rem')} !important;
    color: ${props => (props.variant === 'dark' ? '#1c2237' : '#f7f8fa')};
    ${props => props.color && `letter-spacing: -.025em;`}
    @media (max-width: 991px) {
        font-size: ${props => (props.main === 'true' ? '3rem' : '2.5rem')} !important;
        line-height: ${props => (props.main === 'true' ? '3rem' : '2.5rem')} !important;
        margin-bottom: 18px !important;
    }
`;

export const Description = styled.p`
    color: ${props => (props.variant === 'dark' ? '#1c2237' : '#f7f8fa')};
    font-size: 1.25rem;
    letter-spacing: -0.025em;
    line-height: 1.75rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SectionImage = styled.img`
    opacity: ${props => (props.isVisible ? '1' : '0')};
    transform: ${props => (props.isVisible ? 'none' : props.slideDirection === 'img-slide-right' ? 'translateX(20vh);' : 'translateX(-20vh);')};
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    border: 0px;
    max-width: 100%;
    display: block;
    @media (max-width: 990px) {
        margin-top: 25px !important;
    }
`;
